<div
  class="card mixed-round-border indicator-div"
  (click)="openDetail()"
  [class.hasDetail]="indicator.noDetail !== true"
  [class.largeWidth]="indicator.isLarge === true"
  [class.isNotCard]="isNotCard"
>
  <div class="indicator-title">
    <span>
      <button *ngIf="indicator.noDetail !== true" class="button-title" [attr.aria-label]="indicator.label">
        <h2>{{indicator.label}} </h2>
      </button>
      <h2 *ngIf="indicator.noDetail === true">{{indicator.label}}</h2>
    </span>
    <span class="iconTooltip">
      <button
        *ngIf="indicator.labelTooltip"
        [matTooltip]="indicator.labelTooltip"
        aria-label="Informations"
        matTooltipPosition="after"
        class="clickable btn-info"
      >
        <mat-icon>info</mat-icon>
      </button>
    </span>
  </div>

  <div [ngSwitch]="indicator.dataType" class="indicator-value" tabindex="0" aria-labelledby="graphique">
    <div *ngSwitchCase="'numeric'" class="number">
      <p class="p-value">{{indicator.value}}</p>
    </div>

    <div *ngSwitchCase="'graph'" style="width: 100%" class="graph" aria-hidden="true">
      <div  [chart]="chart"></div>
    </div>
    <div *ngSwitchCase="'graph'" class="visually-hidden" visually-hidden="true">
      <ul>
        <li *ngFor="let stat of listRgaaStat" >
          {{stat.name}} {{stat.y}}
        </li>
      </ul>
    </div>

    <div *ngSwitchCase="'graphAndNumber'" style="width: 100%" class="graphAndNumber" aria-hidden="true">
      <div style="display:flex;flex-direction: row;align-items: stretch; justify-content:space-evenly ">
        <div style="display:flex;flex-direction: row;align-items: stretch ;justify-content:space-evenly">
          <div [chart]="chart" style="width: 380px"></div>
        </div>
        <div style="display:flex;flex-direction: column" style="width: 100px">
          <p class="p-value">{{indicator.value1}} <span class="visually-hidden">{{indicator.label}}</span></p>
          <p class="p-title">Aujourd'hui</p>
          <p class="p-value">{{indicator.value2}} <span class="visually-hidden">{{indicator.label}}</span></p>
          <p class="p-title">Cette semaine</p>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="'graphAndNumber'" class="visually-hidden" visually-hidden="true">
      <ul>
        <li *ngFor="let stat of listRgaaStat" >
          {{stat.name}} {{stat.y}}
        </li>
      </ul>
    </div>

    <div *ngSwitchCase="'list'" style="width: 100%" >

    </div>

    <div *ngSwitchCase="'review'" class="review" style="width: 100%" >
      <h4>{{reviewIndicator.average | decimalFormat }}/4</h4>
      <app-rating [rating]="reviewIndicator.average" [viewMode]="true" [forIndicator]="true"></app-rating>
      <h5>{{reviewIndicator.total}} demandes</h5>
    </div>
  </div>
</div>
