<mat-card class="assignments mixed-round-border ml-25 print-no-margin">
  <mat-card-content>
    <h4>Référent</h4>
    <ul class="assignments__list-container">
      <li *ngIf="responsable">
        <app-user-badge [user]="responsable"></app-user-badge>
      </li>
    </ul>
    <h4>{{ 'Intervenant' + (intervenants?.length > 1 ? 's' : '')}}</h4>
    <ul class="assignments__list-container">
      <li *ngFor="let intervenant of intervenants">
        <app-user-badge [user]="intervenant"></app-user-badge>
      </li>
    </ul>
  </mat-card-content>
</mat-card>
  