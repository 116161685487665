import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Demand } from '@app/shared/models/demand';

@Component({
  selector: 'app-demand-page-content-print',
  templateUrl: './demand-page-content-print.component.html',
  styleUrls: ['./demand-page-content-print.component.scss']
})
export class DemandPageContentPrintComponent implements OnInit {

  @Input() demand: Demand;
  @Output() mapIsReady : EventEmitter<boolean> = new EventEmitter<boolean>();
  private currentTabCacheKey = 'demandPageTab';

  constructor() {}

  ngOnInit() {}

  getSelectedTab() {
    return sessionStorage.getItem(this.currentTabCacheKey) || 0;
  }

  onTabChange(event: MatTabChangeEvent) {
    sessionStorage.setItem(this.currentTabCacheKey, String(event.index));
  }
}
