import { DemandStateCode } from 'src/app/shared/models/demand-state';

const statusColors: string[] = [];
statusColors[DemandStateCode.A_ENVOYER] = '#7fc9fa';
statusColors[DemandStateCode.ENVOYEE] = '#ecf0f1';
statusColors[DemandStateCode.A_COMPLETER] = '#e09f25';
statusColors[DemandStateCode.AFFECTEE] = '#c5e1A5';
statusColors[DemandStateCode.EN_COURS] = '#97bf0d';
statusColors[DemandStateCode.EN_ATTENTE_CLOTURE] = '#558b2f';
statusColors[DemandStateCode.EN_ATTENTE_ABANDON] = '#e67e22';
statusColors[DemandStateCode.CLOTUREE] = '#757575';
statusColors[DemandStateCode.SUSPENDUE] = '#e95f47';
statusColors[DemandStateCode.REJETEE] = '#c0392b';
statusColors[DemandStateCode.ABANDONNEE] = '#000000';

export const environment = {
  production: false,
  CLIENT_ID_KEYCLOAK: 'LINEO-HG',
  HUBGRADE_PASS_URL : 'https://keycloak.istefr.fr/realms/collectivites/account/',
  MOVEO_URL: 'https://moveo-int.hp.m-ve.com/#/wallet?action=newOI',
  //API_URL: 'http://localhost:8000/api/v1',
  API_URL: 'https://lineo-nat-rec.hp.m-ve.com/api/v1',
  PUBLIK_URL: 'https://www.unset.com',
  PUBLIK_ID: 'unset',
  PUBLIK_REDIRECT: 'http://172.24.221.86:8280/api/v1/unset',
  PUBLIK_STATE: 'unset',
  PUBLIK_LOGOUT: 'https://www.unset.com',
  KEYCLOAK_URL: 'https://keycloak-hom.dir.istefr.fr/realms/vef/protocol/openid-connect',
  KEYCLOAK_ID: 'LINEO-HG',
  KEYCLOAK_REDIRECT: window.location.origin + '/api/v1/keycloak/authenticate/',
  KEYCLOAK_STATE: 'unset',
  KEYCLOAK_LOGOUT: 'https://keycloak-hom.dir.istefr.fr/realms/vef/protocol/openid-connect/logout',
  google_api_key: 'AIzaSyDMCE4OKyGzBtdt3dZpIptWWYmIzytqyGU',
  VERSION: require('../../package.json').version,
  REVISION: require('../../package.json').revision,
  authentication_types: ['keycloak_internal'], // authentificationProvider_authorizationProvider
  client_id: '215987360096-utust5aedqhjtmvldlk136hd0o25v4uq.apps.googleusercontent.com',
  client_name: 'national', // national, sabom, setom
  client_to_display: 'Veolia', // Veolia, SABOM, SETOM
  app_name_short: 'lineo', // nom application pour export, impression, etc...
  app_name_long: 'Lineo', // nom application pour titre
  toggle_feature_internal_comment_history: false, // Fil de discussion des commentaires internes pour l'onglet historique d'une demande
  toggle_feature_full_export: false,
  toggle_feature_attachements_url: true,
  toggle_feature_navigation_demands: true,
  toggle_feature_internal_comment: true,
  toggle_feature_display_urgence_in_red: true,
  toggle_feature_notification: true,
  toggle_feature_notification_preference: true,
  toogle_feature_notification_group: true,
  toggle_feature_send_ilotier: false,
  toggle_feature_right_side_home: false,
  toggle_feature_indicators: true,
  toggle_feature_communication_channel: true,
  toggle_feature_map_on_demands: true,
  toggle_feature_internal_reference: true,
  toggle_feature_customer_complaint: true,
  toggle_feature_mandatory_observed_impact: true,
  toggle_feature_map_by_status: true,
  toggle_feature_map_contract_shape_display: false,
  toogle_feature_modal_news: true,
  toogle_feature_show_contract_upper_levels: false,
  toogle_feature_contract_from_zone: false,
  toogle_feature_search_full_text: false,
  toogle_feature_search_full_text_bd: true,
  toggle_feature_publik_connexion: false,
  toggle_feature_keycloak_connexion: true,
  toggle_feature_id_color: true,
  toggle_feature_pushed_notification_mail: true,
  toggle_feature_new_demand_exploit: true, // Ajout 'Nouvelle demande' pour les exploitants
  toggle_feature_duplicate_for_exploit: true, // Ajout duplcation demande dans tableau exploitant
  toggle_feature_add_comment_to_assignation: true, // Ajout d'un commentaire dans la modale d'affectation/désaffectation
  toggle_feature_all_demand_exploitant: true, // Ajout d'un filtre rapide 'toutes les demandes' sur accueil exploitant
  toggle_feature_to_send_filter: true, // Ajout d'un filtre rapide 'à envoyer' sur accueil exploitant
  toggle_feature_delete_demand_exploitant: true, // Ajout suppression demande dans tableau exploitant lorsque paramétreur
  toggle_feature_add_internal_comment_history: true, // Ajout commentaire interne historique
  toogle_feature_hubgrade: true,
  toggle_feature_moveo_oi: true, // Ajout bouton ouverture moveo OI
  toggle_feature_review: true, // Toggle les avis sur les demandes
  indicators_to_display_demandeur: [7, 20, 6, 19, 18, 5, 16, 2, 17, 10, 13, 14],
  indicators_to_display_exploitant: [7, 20, 6, 19, 18, 5, 16, 2, 17, 10, 13, 14],
  activity_label: 'Activité concernée', // Activité concernée, Type demande, Activité concernée
  contract_label: 'Contrat', // Contrat, Organisation, Contrat
  status_labels: {
    DEMANDE_A_COMPLETER: 'à compléter',
    DEMANDE_EN_ATTENTE_CLOTURE: 'à clôturer',
    DEMANDE_EN_ATTENTE_ABANDON: 'à abandonner',
    DEMANDE_A_ENVOYER: 'à envoyer',
    DEMANDE_ENVOYEE: 'envoyée',
    DEMANDE_EN_COURS: 'en cours',
    DEMANDE_AFFECTEE: 'affectée',
    DEMANDE_REJETEE: 'rejetée',
    DEMANDE_SUSPENDUE: 'suspendue',
    DEMANDE_ABANDONNEE: 'abandonnée',
    DEMANDE_CLOTUREE: 'clôturée',
    DEMANDE_A_AFFECTER: 'à affecter', // Utilisé uniquement pour le filtre rapide
    DEMANDE_ALL: 'toutes', // Utilisé uniquement pour le filtre rapide
  },
  status_colors: statusColors,
  status_for_adding_attachments: ['DEMANDE_ENVOYEE', 'DEMANDE_AFFECTEE', 'DEMANDE_EN_COURS', 'DEMANDE_SUSPENDUE'],
  map_latitude: 46.75553536795022, // 46.75553536795022, 44.8404400, 43.601077
  map_longitude: 2.836908406250018, // 2.836908406250018, -0.5805000, 1.443533
  map_zoom: 5, // 5, 11, 11
  map_POI: true, // Active/Desactive les points d'intérêts et points de transport
  max_file_size: 100000000, // octets
  phone_number: '', // ?, 09 77 40 10 14, ?
  phone_number_bis: '',
  object_code_for_branching: '', // 'Diagnostic', Vide pour désactiver
  fiche_memo_link: 'https://docs.google.com/presentation/d/1AWWpdOw29KmfTa47mGf-yh82M5nwLyprg99f0l7hOBU', // https://docs.google.com/presentation/d/1AWWpdOw29KmfTa47mGf-yh82M5nwLyprg99f0l7hOBU, assets/fiche_memo_sabom.pdf, assets/fiche_memo_setom.pdf
  reporting_link: 'https://lookerstudio.google.com/reporting/4138fd26-140c-42ef-9a10-ac4863cfbb4f', // Lien lookerstudio
  suggestion_url: '', // Vide pour ne pas afficher le menu
  bug_url: '', // Vide pour ne pas afficher le menu
  demandeur_columns: [
    'id', 'workflow_current_state', 'demand_object', 'location_full_address', 'location_locality', 'contract', 'concerned_activity',
    'created_by', 'send_date', 'in_charge', 'external_reference_number', 'receive_date', 'close_date', 'last_transition_created_at', 'action',
  ],
  exploitant_columns: [
    'id', 'workflow_current_state', 'infos', 'demand_object', 'realisation_datetime', 'observed_impact', 'location_full_address',
    'location_locality', 'contract', 'concerned_activity', 'created_by', 'reception_date', 'in_charge', 'intervenant', 'external_reference_number',
    'internal_reference_number', 'receive_date', 'close_date', 'last_transition_created_at', 'action',
  ],
  indicator_default_demandeur_columns: [
    'id', 'workflow_current_state', 'demand_object', 'location_full_address', 'location_locality', 'concerned_activity',
    'created_by', 'send_date', 'in_charge', 'external_reference_number', 'receive_date', 'close_date', 'last_transition_created_at', 'action',
  ],
  indicator_default_exploitant_columns: [
    'id', 'workflow_current_state', 'infos', 'demand_object', 'realisation_datetime', 'observed_impact', 'location_full_address',
    'location_locality', 'contract', 'concerned_activity', 'created_by', 'reception_date', 'in_charge', 'external_reference_number',
    'internal_reference_number', 'receive_date', 'close_date', 'last_transition_created_at', 'action',
  ],
  indicator_19_exploitant_columns: [
    'id', 'reviews', 'workflow_current_state', 'infos', 'demand_object', 'realisation_datetime', 'observed_impact', 'location_full_address',
    'location_locality', 'contract', 'concerned_activity', 'created_by', 'reception_date', 'in_charge', 'external_reference_number',
    'internal_reference_number', 'receive_date', 'close_date', 'last_transition_created_at', 'action',
  ],
  indicator_19_demandeur_columns: [
    'id', 'reviews', 'workflow_current_state', 'demand_object', 'location_full_address', 'location_locality', 'concerned_activity',
    'created_by', 'send_date', 'in_charge', 'external_reference_number', 'receive_date', 'close_date', 'last_transition_created_at', 'action',
  ],
  authorizedEmailsForReopenDemand: ['amadou.aly-sy.ext@veolia.com', 'franck.beugnet.ext@veolia.com', 'arthur.heude.ext@veolia.com', 'pierre-alexandre.fillaudeau.ext@veolia.com'],
};
