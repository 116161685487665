import { Component, Input, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { Demand } from '@app/shared/models/demand';
import { User } from '@app/shared/models/user';
import { map } from 'lodash';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-demand-assignment-print',
  templateUrl: './demand-assignment-print.component.html',
  styleUrls: ['./demand-assignment-print.component.scss'],
})
export class DemandAssignmentPrintComponent implements OnInit, OnDestroy {
  @Input() demand: Demand;

  public responsable: User;
  public intervenants: User[];
  private subscription: Subscription;

  constructor(
    private demandService: DemandService,
  ) {
    this.subscription = this.demandService.getDemandUpdated().subscribe((demand) => {
      this.demand = demand;
      this.responsable = this.demand.assignations.responsable && this.demand.assignations.responsable.user;
      this.intervenants = map(this.demand.assignations.intervenants, 'user');
    });
  }

  ngOnInit() {
    this.responsable = this.demand.assignations && this.demand.assignations.responsable && this.demand.assignations.responsable.user;
    this.intervenants = this.demand.assignations && map(this.demand.assignations.intervenants, 'user');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.demand) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
