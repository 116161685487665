import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit{
  @Input() rating: number = 0;
  @Input() viewMode: boolean = false;
  @Input() forIndicator: boolean = false;

  @Output() ratingChange: EventEmitter<number> = new EventEmitter<number>();

  selectedRate: number = 0;
  stars: number[] = [1, 2, 3, 4];

  ngOnInit(): void {
    this.selectedRate = this.rating;
  }

  rate(star: number) {
    this.rating = star;
  }

  saveRating(star: number) {
    if(star <= 0) star = 0;
    if(star >= this.stars.length) star = this.stars.length;

    this.selectedRate = star;
    this.rating = star;
    this.ratingChange.emit(this.selectedRate);
  }

  onKeydown(event) {
    if (event.key === 'ArrowRight') {
      this.saveRating(this.selectedRate + 1);
    } else if (event.key === 'ArrowLeft') {
      this.saveRating(this.selectedRate - 1);
    } else if (event.key === 'ArrowUp') {
      this.saveRating(this.stars.length);
    } else if (event.key === 'ArrowDown') {
      this.saveRating(1);
    }
  }

  getStarIcon(index: number): string {
    let baseDiff = this.rating - index;
    if (baseDiff >= 1) {           // Si diff > 1 alors étoile pleine
      return 'star';
    } else if (baseDiff >= 0.7) {  // Si diff >= 0.7 etoile pleine
      return 'star';
    } else if (baseDiff >= 0.3) {  // Si diff >= 0.3 etoile semi pleine 
      return 'star_half';
    } else {                       // sinon vide
      return 'star_border';
    }
  }
}
