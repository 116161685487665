<h2 mat-dialog-title>Saisie email du demandeur</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field appearance="fill">
    <mat-label>Entrez l'email </mat-label>
    <input matInput [(ngModel)]="userInput">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="userInput">Envoyer</button>

</mat-dialog-actions>
