<form style="width: 400px;" [formGroup]="suspensionForm">
  <h5 style="font-weight: bold">Suspendre les notifications</h5>
  <div class="d-flex flex-row align-items-center" class="message-div">
    <mat-icon style="margin-right: 8px;">info</mat-icon>
    La suspension concerne les notifications de l'ensemble de votre périmètre contractuel.
  </div>

  <mat-form-field style="width: 100%; margin-top: 32px;">
    <input matInput [matDatepicker]="pickerDateDebut" [max]="suspensionForm.get('dateFin').value"
           placeholder="Date de début" formControlName="dateDebut"
           aria-describedby="date_start_error" required readonly>
    <mat-datepicker-toggle matIconSuffix [for]="pickerDateDebut"></mat-datepicker-toggle>
    <mat-datepicker #pickerDateDebut></mat-datepicker>
    <mat-error id="date_start_error">Le champs est requis.</mat-error>
  </mat-form-field>

  <mat-form-field style="width: 100%; margin-top: 16px;">
    <input matInput [matDatepicker]="pickerDateFin" [min]="suspensionForm.get('dateDebut').value"
           placeholder="Date de fin" formControlName="dateFin"
           aria-describedby="date_end_error" required readonly>
    <mat-datepicker-toggle matIconSuffix [for]="pickerDateFin"></mat-datepicker-toggle>
    <mat-datepicker #pickerDateFin></mat-datepicker>
    <mat-error id="date_end_error">Le champs est requis.</mat-error>
  </mat-form-field>

  <!--<mat-form-field style="width: 100%; margin-top: 16px;">
    <input matInput placeholder="Adresse mail du backup" formControlName="emailBackup" pattern="[^@]+@[^\.]+\..+" aria-describedby="email_backup_error">
    <mat-error id="email_backup_error">Le mail renseigné n'a pas le bon format.</mat-error>
  </mat-form-field>-->

  <div class="d-flex justify-content-end" style="margin-top: 16px;">
    <button mat-button mat-dialog-close class="uppercased mat-primary" style="margin-right: 16px;">
      Annuler
    </button>
    <button mat-button class="uppercased mat-primary" (click)="apply()">
      Suspendre
    </button>
  </div>
</form>
