<div class="btn-wrapper" *ngIf="checkVisibility()"
  matTooltip="Merci d'être affecté comme intervenant à la&#13;résolution de cette demande pour poursuivre"
  [matTooltipDisabled]="!isDisabled()" [tabindex]="isDisabled() ? '0' : '-1'">
  <button *ngIf="!raisedBtn" (click)="onClick()" [hv360-loading]="loading" mat-stroked-button
    class="uppercased demand-action-btn mat-primary" [disabled]="isDisabled()">
    {{ buttonText }}
  </button>
  <button *ngIf="raisedBtn" (click)="onClick()" [hv360-loading]="loading" mat-raised-button
    class="uppercased demand-action-btn mat-primary" [disabled]="isDisabled()">
    {{ buttonText }}
  </button>
</div>
