import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from '../authentication/guards/authentication.guard';
import { ExploitantGuard } from './guards/exploitant.guard';
import { DemandeurGuard } from './guards/demandeur.guard';
import { IndicatorsDashboardComponent } from './indicators-dashboard/indicators-dashboard.component';
import { NotificationComponent } from './notification/notification.component';
import { PreferencesNotificationComponent } from './preferences-notification/preferences-notification.component';
import { HomeModule } from './home/home.module';
import { DemandsModule } from './demands/demands.module';
import { ExploitantModule } from './exploitant/exploitant.module';
import { AccessibilityComponent } from './accessibility/accessibility.component';
import { environment } from '@env/environment';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: '',
        loadChildren: () => HomeModule,
        canActivate: [AuthGuard, DemandeurGuard],
        title: 'Accueil - ' + environment.app_name_short.toUpperCase(),
      },
      {
        path: 'demands',
        loadChildren: () => DemandsModule,
        canActivate: [AuthGuard],
        title: 'Accueil - ' + environment.app_name_short.toUpperCase(),
      },
      {
        path: 'exploitant',
        loadChildren: () => ExploitantModule,
        canActivate: [AuthGuard, ExploitantGuard],
        title: 'Accueil - ' + environment.app_name_short.toUpperCase(),
      },
      {
        path: 'indicators',
        component: IndicatorsDashboardComponent,
        canActivate: [AuthGuard],
        title: 'Indicateurs - ' + environment.app_name_short.toUpperCase(),
      },
      {
        path: 'notification',
        component: NotificationComponent,
        canActivate: [AuthGuard],
        title: 'Notifications - ' + environment.app_name_short.toUpperCase(),
      },
      {
        path: 'preferences-notification',
        component: PreferencesNotificationComponent,
        canActivate: [AuthGuard],
        title: 'Préférences de notification - ' + environment.app_name_short.toUpperCase(),
      },
      {
        path: 'accessibilite',
        component: AccessibilityComponent,
        canActivate: [AuthGuard],
        title: 'Accessibilité numérique - ' + environment.app_name_short.toUpperCase(),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {
}
