import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IReview } from '@app/shared/models/review';
import { ReviewService } from '@app/shared/services/review/review.service';

@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss']
})
export class ReviewFormComponent implements OnInit {

  public rating: number = 0;
  public maxLength: number = 200;
  public isLoading: boolean = false;
  @ViewChild('commentInput') commentInput;

  constructor(
    private reviewService: ReviewService,
    public matDialogRef: MatDialogRef<ReviewFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    
  }

  confirmWithReview() {
    this.isLoading = true;
    const review: IReview = {
      rate: this.rating,
      comment: this.rating <= 3 ? this.commentInput.nativeElement.value == "" ? null : this.commentInput.nativeElement.value : null,
      demand: this.data.demand.id,
      user: this.data.user.id
    };
    this.reviewService.createReview(review).then(() => {
      this.isLoading = false;
      this.matDialogRef.close(true);
    })
  }

  confirmWithoutReview() {
    this.matDialogRef.close(true);
  }

  cancel() {
    this.matDialogRef.close(false);
  }
}