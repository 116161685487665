<h3 mat-dialog-title>Note de satisfaction</h3>
<div mat-dialog-content>
  <div class="modal-wrapper">

    <div class="row">
      <span class="label-form" tabindex="0">
        Quel est votre niveau de satisfaction sur le traitement de votre demande ?
      </span>
      <app-rating [(rating)]="rating"></app-rating>
    </div>
    <div class="row" *ngIf="rating <= 3 && rating != 0">
      <mat-form-field class="full-width">
        <textarea matInput #commentInput placeholder="Que faut-il améliorer pour augmenter votre niveau de satisfaction ? (facultatif)" [attr.maxlength]="maxLength"></textarea>
        <mat-hint align="end">
          <span class="visually-hidden">Compteur de caractères</span>
          {{commentInput?.value.length || 0}}
          <span aria-hidden="true">/</span><span class="visually-hidden">sur</span>
          {{maxLength}}
        </mat-hint>
      </mat-form-field> 
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="uppercased mat-primary" (click)="cancel()" [disabled]="isLoading" cdkFocusInitial>ANNULER</button>
  <button mat-button mat-dialog-close class="uppercased mat-primary" (click)="confirmWithoutReview()" [disabled]="isLoading">IGNORER</button>
  <button mat-button class="uppercased mat-primary" [disabled]="rating == 0 || isLoading" (click)="confirmWithReview()">JE CONFIRME</button>
</div>
