import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Demand } from '@app/shared/models/demand';
import { ActivatedRoute } from '@angular/router';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import { DemandService } from '@app/shared/services/demand/demand.service';
import Toast from '@app/shared/helpers/toast';
import { environment } from '@env/environment';

@Component({
  selector: 'app-demand-upload-btn',
  templateUrl: './demand-upload-btn.component.html',
  styleUrls: ['./demand-upload-btn.component.scss']
})
export class DemandUploadBtnComponent implements OnInit {

  @ViewChild('fileInput') fileInput;
  @Output() reloadHistory: EventEmitter<any> = new EventEmitter<any>();

  public demand: Demand;
  public userMode = getUserMode();
  max_file_size = environment.max_file_size;

  constructor(
    private route: ActivatedRoute,
    private demandService: DemandService
  ) { }

  ngOnInit() {
    this.demand = this.route.snapshot.data.demand;
  }

  onFileInput(fileList: FileList) {
    let files = [];
    for (let i = 0; i < fileList.length; i++) {
      for (let y = 0; y < this.demand.attachments.length; y++) {
        if (this.demand.attachments.find((e) => e.file_name === fileList[i].name)) {
          Toast.error('Un fichier du même nom figure déjà parmi les fichiers téléchargés', 'Fichier refusé');
          return;
      }}
      for (let y = 0; y < this.demand.point_avancement.length; y++) {
        if (this.demand.point_avancement[y].attachments.find((e) => e.file_name === fileList[i].name)) {
          Toast.error('Un fichier du même nom figure déjà parmi les fichiers téléchargés', 'Fichier refusé');
          return
      }}
      if (fileList[i].size >= this.max_file_size) {
        Toast.error("Ce fichier ne respecte pas les normes de téléchargement (taille supérieure à " + (this.max_file_size / 1000 / 1000) + " Mo).", "Fichier refusé");
      } else if (['exe', 'bat', 'vbs', 'js', 'sql', 'reg', 'class', 'java', 'py', 'pyc'].indexOf(fileList[i].name.substr(fileList[i].name.lastIndexOf('.') + 1)) >= 0) {
        Toast.error("Ce fichier ne respecte pas les normes de téléchargement (format interdit).", "Fichier refusé");
      } else {
        files.push({
          file_name: fileList[i].name,
          _file: fileList[i],
          id: null,
          mode_creation: this.userMode.toUpperCase(),
        });
      }
    }

    this.demandService.createAttachments(this.demand, files, 'demand', undefined, "true").subscribe((result) => {
      this.reloadHistory.emit(true);
      Toast.info('Le fichier joint a été ajouté à cette demande.', 'Fichier Ajouté');
    });
  }

}
