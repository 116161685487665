<div class="main-view no-child-padding">
  <app-lineo-breadcrumb [childPageName]="'Accessibilité numérique'"></app-lineo-breadcrumb>
  <button mat-fab (click)="scrollToBottom()" aria-label="Aller vers bas de page"
  class="fabPositionBottom fabColor" matTooltip="Bas écran" matTooltipPosition="left">
  <i class="material-icons">arrow_downward</i>
</button>
  <div class="a11y-wrapper" id="skiplink-accessibilite" aria-label="Accessibilité numérique">
    <div class="row-no-margin no-child-padding">
      <h1 class="header d-print-none">Accessibilité numérique</h1>
    </div>

    <div class="row-no-margin no-child-padding">
      <main>
        <h2>Déclaration d’accessibilité</h2>
        <ul>
          <li>Veolia s’engage à rendre ses sites web accessibles conformément à l’article 47 de la loi n°2005-102 du 11 février 2005.</li>

          <li>Cette déclaration d’accessibilité s’applique au site <a target="_blank" [href]="currentUrl">{{currentUrl}}</a>.</li>
          <li>L'audit RGAA a été réalisé sur un site de recette, les résultats sont similaires pour chaque site.</li>
          <li>Les actions de remédiation sont intégrées dans notre programme de développement.</li>
        </ul>

        <h2>Etat de conformité</h2>
        <p>Le site est en <b>conformité partielle</b> avec le référentiel général d’amélioration de l’accessibilité (RGAA).</p>

        <h2>Résultat des tests</h2>
        <ul>
          <li>
            Pourcentage de critères respectés (somme des critères conformes divisée par le nombre de critères applicables) : <b>55,2%</b>.
          </li>
          <li>
            Taux moyen de conformité du service en ligne (moyenne des taux de conformité de chaque page) : <b>72,1%</b>.
          </li>
        </ul>

        <div *ngIf="critereNonConforme.length > 0">
          <h2>Contenus non accessibles</h2>
          <h5><b>- Critères non conformes</b></h5>
          <ul>
            <li *ngFor="let crit of critereNonConforme" style="padding-top:3px;">
              <b>{{crit.crit}}</b> {{crit.detail}}
            </li>
          </ul>
        </div>

        <h5><b>- Dérogations pour charges disproportionnées</b></h5>
        <p>Pas de dérogation.</p>

        <h5><b>- Contenus non soumis à l’obligation d’accessibilité</b></h5>
        <p>Les cartes Google Maps sont exemptées. Elles n’entrent pas dans le champ d’application du RGAA et sont facultatives car elles sont une version visuelle d’un contenu déjà présent dans le site.</p>

        <h2>Établissement de cette déclaration d’accessibilité</h2>
        <p>Cette déclaration a été établie le 22/01/2024.</p>

        <h5><b>- Technologies utilisées pour la réalisation du site</b></h5>
        <ul>
          <li>Angular 14</li>
          <li>HTML</li>
          <li>CSS</li>
          <li>JavaScript</li>
        </ul>

        <h5><b>- Environnement de test pour évaluer l'accessibilité</b></h5>
        <p>L’environnement décrit ci-dessous a été utilisé pour effectuer l’audit :</p>
        <ul>
          <li>Microsoft Windows 10 Enterprise Version 22H2 (version du système d'exploitation 19045.3803), système x64</li>
          <li>Google Chrome version 120.0.6099.109</li>
          <li>Microsoft Edge version 120.0.2210.77</li>
          <li>Lecteur d’écran JAWS version 2023.2311.34.400</li>
          <li>Lecteur d’écran NVDA 2023.3</li>
          <li>Logiciel d’agrandissement ZoomText version 2023.2311.20.400</li>
        </ul>

        <h5><b>- Outils pour évaluer l'accessibilité</b></h5>
        <ul>
          <li>Les extensions de navigateurs Stylus, HeadingsMap, aXe, Accessibility Insights for Web, Web Developer Toolbar</li>
          <li>Le logiciel Color Contrast Analyzer (CCA)</li>
        </ul>

        <h2>Pages du site ayant fait l'objet de la vérification de conformité</h2>
        L'audit RGAA a été réalisé sur un site de recette, les résultats sont similaires pour chaque site.
        <table mat-table [dataSource]="page" class="mat-elevation-z8">
          <ng-container matColumnDef="page">
            <th mat-header-cell *matHeaderCellDef> Page </th>
            <td mat-cell *matCellDef="let element"> {{element.page}} </td>
          </ng-container>
        
          <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef> URL </th>
            <td mat-cell *matCellDef="let element"> <a target="_blank" [href]="element.url">{{element.url}}</a></td>
          </ng-container>
        
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <h2>Retour d’information et contact</h2>
        <p>
          Si vous n’arrivez pas à accéder à un contenu ou à un service, vous pouvez contacter votre référent Veolia Hubgrade Local, pour être orienté vers une alternative accessible ou obtenir le contenu sous une autre forme.
          Le cas échéant, il contactera les équipes de développement du site pour demander une remédiation (via le Portail interne Helia, en précisant "Accessibilité" au début du sujet de la demande, et en précisant le site, la page concernée ainsi que le souci rencontré).
        </p>

        <h2>Voies de recours</h2>
        <p>Cette procédure est à utiliser dans le cas suivant.<br>
        Vous avez signalé au responsable du site internet un défaut d’accessibilité qui vous empêche d’accéder à un contenu ou à un des services du site et vous n’avez pas obtenu de réponse satisfaisante.</p>

        <p>Plusieurs moyens sont à votre disposition :</p>

        <ul>
          <li><a target="_blank" href="https://www.defenseurdesdroits.fr/nous-contacter">Écrire un message</a> au Défenseur des droits</li>
          <li><a target="_blank" href="https://www.defenseurdesdroits.fr/fr/saisir/delegues">Contacter le délégué</a> du Défenseur des droits dans votre région</li>
          <li>Envoyer un courrier par la poste (gratuit, ne pas mettre de timbre) Défenseur des droits Libre réponse 71120 75342 Paris CEDEX 07</li>
        </ul>
      </main>
    </div>
  </div>
  <button mat-fab (click)="scrollToTop()" aria-label="Aller vers haut de page"
  class="fabPositionBottom2 fabColor" matTooltip="Haut écran" matTooltipPosition="left">
  <i class="material-icons">arrow_upward</i>
  </button>

</div>
