import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Demand } from '@app/shared/models/demand';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-demand-page-content',
  templateUrl: './demand-page-content.component.html',
  styleUrls: ['./demand-page-content.component.scss'],
})
export class DemandPageContentComponent implements OnInit {

  @Input() demand: Demand;
  @Output() reloadHistory: EventEmitter<any> = new EventEmitter<any>();
  @Input() endReload: EventEmitter<any> = new EventEmitter<any>();
  private currentTabCacheKey = 'demandPageTab';

  constructor(private route: ActivatedRoute,) {}

  ngOnInit() {
    sessionStorage.setItem(this.currentTabCacheKey, "0");
    this.route.fragment.subscribe((fragment: string) => {
      if(["historique", "history"].includes(fragment)) {
        sessionStorage.setItem(this.currentTabCacheKey, "1");
      }
    })
  }

  getSelectedTab() {
    return sessionStorage.getItem(this.currentTabCacheKey) || 0;
  }

  onTabChange(event: MatTabChangeEvent) {
    sessionStorage.setItem(this.currentTabCacheKey, String(event.index));
  }
}
