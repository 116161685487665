import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import { DemandService } from '@app/shared/services/demand/demand.service';
import Toast from '@app/shared/helpers/toast';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { ConfirmationInputModalComponent } from '@app/components/modals/confirmation-input-modal/confirmation-input-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-btn-demander-point-avancement',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnDemanderPointAvancementComponent extends BtnTransitionBaseComponent {
  private subscription: Subscription;
  constructor(
    private dialog: MatDialog,
    private demandService: DemandService,
    private authenticationService: AuthenticationService
  ) {
    super(authenticationService);
    this.buttonText = 'Demander un point d\'avancement';
  }

  ngOnInit(): void {
    this.subscription = this.demandService.getDemandUpdated().subscribe((demand) => {
      this.stopLoading();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  checkVisibility(): boolean {
    return !demandMatchState(this.demand, [
      DemandStateCode.CLOTUREE,
      DemandStateCode.ABANDONNEE,
      DemandStateCode.ENVOYEE,
      DemandStateCode.A_ENVOYER,
      DemandStateCode.EN_ATTENTE_CLOTURE,
      DemandStateCode.EN_ATTENTE_ABANDON,
      DemandStateCode.A_COMPLETER,
    ]);
  }

  onClick() {
    // add to messagePlaceHolder : Évitez les informations nominatives et/ou personnelles
    const dialogRef = this.dialog.open(ConfirmationInputModalComponent, {
      data: {
        title: 'Demande de point d\'avancement',
        isMandatory: false,
        messagePlaceholder: 'Votre commentaire (facultatif)',
        messageMaxLength: 3000,
        upload: true,
        demand: this.demand,
      },
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.status === true) {
        this.startLoading();

        let message;
        if (result.message) {
          message = "Point d'avancement demandé : " + result.message;
        }

        this.demandService.demanderPointAvancement(this.demand.id, message)
          .subscribe((response: any) => {
            if (result.attachments.length > 0) {
              this.demandService.createAttachments(this.demand, result.attachments, 'point', response.point.id)
                .subscribe((attachments: any) => {
                  response.demand.point_avancement.forEach((point: any, idx: number) => {
                    if (point.id === response.point.id) {
                      response.demand.point_avancement[idx].attachments = attachments;
                    }
                  });
                  this.onFinish(response);
              },
              (error) => {
                this.onError("Une erreur s'est produite pendant le téléversement du fichier.");
              }
            );
            } else {
              this.onFinish(response);
            }
        }, () => {
          Toast.info('Point d\'avancement déjà demandé.');
          this.stopLoading();
        });
      }
    });
  }

  onFinish(response) {
    Toast.info(response.message);
    this.demandService.updateDemand(this.demand);
    this.stopLoading();
  }

  onError(message = "Une erreur s'est produite.") {
    Toast.info(message);
    this.demandService.updateDemandByRequest(this.demand);
  }
}
