import { Component, AfterViewInit, Input,  OnChanges, SimpleChanges } from '@angular/core';
import { Demand } from '@app/shared/models/demand';
import { IReview } from '@app/shared/models/review';
import { ReviewService } from '@app/shared/services/review/review.service';
import { ToastHelper } from '@app/shared/helpers/toast-helper';

@Component({
  selector: 'app-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.scss']
})
export class ReviewListComponent implements AfterViewInit, OnChanges {

  @Input() demand: Demand;

  rating: number = 3;
  isLoading: boolean = false;
  reviews: IReview[] = [];

  constructor(
    private reviewService: ReviewService,
    private toastHelper: ToastHelper,
  ) {}

  ngAfterViewInit(): void {
    this._getReviews();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['demand']) {
      this.isLoading = true;
      this.reviews = [];
      this._getReviews();
    }
  }

  private _getReviews() {
    this.isLoading = true;
    this.reviewService.getReviewByDemand(this.demand?.id)
    .then((review: IReview[]) => {
      this.reviews = review
      this.isLoading = false;
    })
    .catch((error) => {
      this.toastHelper.error("Une erreur a eu lieu pendant le chargement des avis", "Ressayer").subscribe(() => this._getReviews());
      console.error(error);
      this.isLoading = false;
    })
  }
}