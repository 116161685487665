<div class="attachment-list" *ngIf="_attachments.length" [ngClass]="{
    'row-no-margin no-child-padding': !inBubble,
    'in-last-message': inLastMessage
  }">
    <div class="col-xs-12 attachment" *ngFor="let attachment of _attachments" [ngClass]="{
        'col-xl-6 col-lg-6 col-md-12 col-sm-12': !inBubble,
        'in-bubble': inBubble
      }">
      <div>
        <mat-icon class="attachment-file-icon" *ngIf="attachment.kind==='FILE'">attach_file</mat-icon>
        <mat-icon class="attachment-file-icon" *ngIf="attachment.kind==='URL'">link</mat-icon>
      </div>
      <a [matTooltip]="attachment.file_name" [matTooltipShowDelay]="1000" download
        class="attachment-file-download cut-text d-print-none" (click)="open(attachment)">
        <div id="cloud-block">
          <div>
  
            <span class="attachment-file-name">{{ attachment.file_name }}</span>
            <span class="attachment-created-infos" *ngIf="!inBubble"> Le {{ attachment.created_at | date: 'dd-MM-yyyy' }}
              à
              {{ attachment.created_at | date: 'HH:mm' }}</span>
          </div>
          <div>
            <button class="attachment-file-download-icon" *ngIf="attachment.kind==='URL'" tabindex="0">
              <span class="visually-hidden">Afficher le fichier</span>
            </button>
          </div>
          <div>
            <button class="attachment-file-download-icon" *ngIf="attachment.kind==='FILE'">
              <span class="visually-hidden">Télécharger le fichier</span>
              <mat-icon>cloud_download</mat-icon>
            </button>
          </div>
        </div>
      </a>
      <a *ngIf="attachment.kind==='FILE'" class="d-print-only" target="_blank"
        [href]="prefixurl + '/\#/download/demand/' + demandId + '/attachment/' + attachment.id + '/'">
        <span class="attachment-file-name">{{ attachment.file_name }}</span>
        <span class="attachment-created-infos" *ngIf="!inBubble"> Le {{ attachment.created_at | date: 'dd-MM-yyyy' }} à
          {{ attachment.created_at | date: 'HH:mm' }}</span>
      </a>
  
      <a *ngIf="attachment.kind==='URL'" class="d-print-only" [href]="encodeUri(attachment.storage_full_uri)">
        <span class="attachment-file-name">{{ attachment.file_name }}</span>
        <span class="attachment-created-infos" *ngIf="!inBubble"> Le {{ attachment.created_at | date: 'dd-MM-yyyy' }} à
          {{ attachment.created_at | date: 'HH:mm' }}</span>
      </a>
    </div>
  </div>
  