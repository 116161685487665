<div class="btn-wrapper-pdf">
  <button (click)="print()" mat-stroked-button class="uppercased  pdf-print-button mat-primary" [ngClass]="defaultStyle ? 'demand-action-btn-pdf-default' : 'demand-action-btn-pdf'">
    <mat-icon>vertical_align_bottom</mat-icon>enregistrer en pdf
  </button>
  <button mat-icon-button
  matTooltip="La diffusion des informations du fichier d'enregistrement présente un risque pour la vie privée des personnes concernées, et pour vos organismes. Par conséquent, vous vous engagez à protéger la confidentialité de ces informations, et en particulier à empêcher qu’elles ne soient communiquées à des personnes non expressément autorisées à les recevoir."
  class="clickable btn-info_add_file pdf-print-button"
  >
    <mat-icon>lock</mat-icon>
  </button>
</div>
<ng-template #viewContainerRef></ng-template>