import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { User } from '../shared/models/user';
import { Value } from '../shared/models/value';

@Injectable()
export class AuthenticationService {

  private currentUserSource = new BehaviorSubject<User>(null);
  public currentUser$ = this.currentUserSource.asObservable();

  private userContractsSource = new BehaviorSubject<Value[]>([]);
  public userContracts$ = this.userContractsSource.asObservable();

  private user;
  public authentication_types: string;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
  ) { }

  login(username: string, password: string) {
    return this.httpClient.post<any>(`${environment.API_URL}/authenticate/`, { username: username, password: password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          this.setToken(user.token);
        }
        return user;
      }));
  }

  loginPublik() {
    localStorage.setItem('isPublik', 'true');
    localStorage.setItem('isKeyCloak', 'false');
    window.location.href = `${environment.PUBLIK_URL}/authorize/?client_id=${environment.PUBLIK_ID}&redirect_uri=${environment.PUBLIK_REDIRECT}&state=${environment.PUBLIK_STATE}&response_type=code&scope=openid email profile`
  }

  logoutPublik() {
    window.location.href = `${environment.PUBLIK_LOGOUT}`
  }

  loginKeycloak() {
    localStorage.setItem('isKeyCloak', 'true');
    localStorage.setItem('isPublik', 'false');
    window.location.href = `${environment.KEYCLOAK_URL}/auth?client_id=${environment.KEYCLOAK_ID}&redirect_uri=${environment.KEYCLOAK_REDIRECT}&state=${environment.KEYCLOAK_STATE}&response_type=code&scope=openid email profile`
  }

  logoutKeycloak() {
    if (environment.client_name === 'national' || environment.client_name === 'ileo') {
       window.location.href = `${environment.KEYCLOAK_LOGOUT}` + '?post_logout_redirect_uri=' + window.location.origin + "&client_id=" + `${environment.KEYCLOAK_ID}`;
    } else {
       window.location.href = `${environment.KEYCLOAK_LOGOUT}` + '?redirect_uri=' + window.location.origin;
    }

  }

  changePassword(username: string, oldPassword: string, newPassword: string) {
    return this.httpClient.post<any>(`${environment.API_URL}/change-password/`, { username: username, old_password: oldPassword, new_password: newPassword })
      .pipe(map((user) => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          this.setToken(user.token);
        }
        return user;
      }));
  }

  changePasswordByToken(token: string, newPassword: string) {
    return this.httpClient.post<any>(`${environment.API_URL}/reset_password/confirm/`, { token: token, password: newPassword }).toPromise();
  }

  resetPassword(username: string) {
    return this.httpClient.post<any>(`${environment.API_URL}/reset_password/`, { username: username }).toPromise();
  }

  setToken(token) {
    localStorage.setItem('token', token);
    localStorage.setItem('authentication_types', this.authentication_types);
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('authentication_types');
    this.currentUserSource.next(null);
    if (localStorage.getItem('isPublik')=='true' && (environment.toggle_feature_publik_connexion)) {
      localStorage.removeItem('isPublik');
      this.logoutPublik();
    } else if (localStorage.getItem('isKeyCloak')=='true'&& (environment.toggle_feature_keycloak_connexion)) {
      localStorage.removeItem('isKeyCloak');
      this.logoutKeycloak();
    }
    else
      this.router.navigate(['/login']);
  }

  getUserdata(): Observable<User> {
    return this.httpClient.get<User>(`${environment.API_URL}/users/me/`);
  }

  setUserData(user: User) {
    this.currentUserSource.next(user);
  }

  getUserContracts() {
    return this.httpClient.get<any[]>(`${environment.API_URL}/users/contracts/`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getUser() {
    return new Promise((resolve, reject) => {
      const userData = this.currentUserSource.value;

      if (userData === null) {
        this.getUserdata().subscribe((user) => {
          this.setUserData(user);
          resolve(user);
        }, (error) => {
          reject(error);
        });
      } else {
        resolve(userData);
      }
    });
  }

  acceptRGPD() {
    this.httpClient.put(
      `${environment.API_URL}/users/me/`,
      { accept_rgpd: true },
    ).subscribe();
  }

  public handleExpiredToken(token: string): boolean {
    if (token != null && token != undefined && token != 'undefined') {
      const base64Url = token.split('.')[1];
      if (base64Url != undefined && base64Url != 'undefined') {
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = JSON.parse(decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('')));
        let timestampStr = new Date().getTime().toString();
        let nowTs = parseInt(timestampStr.substr(0, (timestampStr.length - 3)));
        return jsonPayload.exp > nowTs;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public isGoogleToken(token: string): boolean {
    if (token != null && token != undefined && token != 'undefined') {
      const base64Url = token.split('.')[1];
      if (base64Url != undefined && base64Url != 'undefined') {
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = JSON.parse(decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('')));
        return jsonPayload.iss.includes('google');
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  getCurrentUserEmail(): string {
    return this.currentUserSource.value?.email || '';
  }
  
}
