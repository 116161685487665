import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalFormat'
})
export class DecimalFormatPipe implements PipeTransform {

  transform(value: number): string {
    if (value % 1 !== 0) {
      return value.toFixed(1);
    }
    return value.toString();
  }

}