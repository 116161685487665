import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Demand } from '@app/shared/models/demand';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { Subscription } from 'rxjs';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { environment } from '@env/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-exploitant-demand',
  templateUrl: './exploitant-demand.component.html',
  styleUrls: ['./exploitant-demand.component.scss'],
})
export class ExploitantDemandComponent implements OnInit, OnDestroy {
  public demand: Demand;
  subscription: Subscription;
  isSmallScreen: boolean = false;
  isLoading: boolean = false;
  public reloadHistory: EventEmitter<any> = new EventEmitter<any>();
  public endReload: EventEmitter<any> = new EventEmitter<any>();

  previousDemand;
  nextDemand;
  toggle_feature_navigation_demands = environment.toggle_feature_navigation_demands;

  constructor(
    private route: ActivatedRoute,
    private demandService: DemandService,
    private breakPoint: BreakpointObserver,
    private titleService: Title,
    private router: Router
  ) {
    this.subscription = this.demandService.getDemandUpdated().subscribe((demand) => {
      this.demand = demand;
    });
  }

  ngOnInit() {
    const maxWidth = '(max-width: 991px)';
    const minWidth = '(min-width: 992px)';
    this.isSmallScreen = this.breakPoint.isMatched(maxWidth);
    this.breakPoint.observe([maxWidth, minWidth])
      .subscribe((result) => {
        this.isSmallScreen = result.breakpoints[maxWidth];
      });

    this.route.params.subscribe(params => {
      this.demand = this.route.snapshot.data.demand;

      this.previousDemand = this.demandService.getPreviousDemand(this.demand.id);
      this.nextDemand = this.demandService.getNextDemand(this.demand.id);

      if (params.attachementId !== undefined) {
        let storage_full_uri = 'demands/'+this.demand.id+'/attachments/download/'+params.attachementId+'/';
        this.demandService.getDownloadUrl(storage_full_uri).subscribe((res: any) => {
          window.open(res.url, '_blank');
        });
      }
      if(this.demand) {
        this.titleService.setTitle("Demande n°" + this.demand.id + " - Contrat " + this.demand.contract + " - " + environment.app_name_short.toUpperCase())
        this.isLoading = false;
      }
    });

    this.reloadHistory.asObservable().subscribe((res) => {
      this.reloadResolve();
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showAssignment(): boolean {
    return !demandMatchState(this.demand, [
      DemandStateCode.A_ENVOYER,
      DemandStateCode.ENVOYEE,
    ]);
  }

  goToPreviousDemand() {
    if (this.previousDemand)
      this.isLoading = true;
      this.demandService.openDemandDetail(this.previousDemand);
  }

  goToNextDemand() {
    if (this.nextDemand)
      this.isLoading = true;
      this.demandService.openDemandDetail(this.nextDemand);
  }

  reloadResolve() {
    this.router.navigated = false;
    this.router.navigate([this.router.url]).then(() => {
      this.demand = this.route.snapshot.data.demand;
      this.endReload.emit(true);
      this.ngOnInit();
    });
  }
}
