import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IReview } from '@app/shared/models/review';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor(private httpClient: HttpClient) { }


  getReviews() {
    return new Promise((resolve, reject) => {
      return this.httpClient.get(`${environment.API_URL}/reviews`).subscribe((reviews: IReview[]) => {
        resolve(reviews);
      },
      err => {
        reject(err);
      });
    });
  }

  getReviewByDemand(id : number) {
    return new Promise((resolve, reject) => {
      return this.httpClient.get(`${environment.API_URL}/reviews/review-by-demand?demandId=${id}`).subscribe((review: IReview) => {
        resolve(review);
      },
      err => {
        reject(err);
      });
    });
  }

  createReview(review: IReview) {
    return new Promise((resolve, reject) => {
      return this.httpClient.post(`${environment.API_URL}/reviews/`, {review:review}).subscribe((review: IReview) => {
        resolve(review);
      },
      err => {
        reject(err);
      });
    });
  }

}
