import { Component, AfterViewInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription, filter } from 'rxjs';

import { ISkipLink } from '@app/shared/models/skiplink';
import { SkipLinksService } from '@app/shared/services/skiplinks/skiplinks.service';

@Component({
  selector: 'app-skiplinks',
  templateUrl: './skiplinks.component.html',
  styleUrls: ['./skiplinks.component.scss']
})
export class SkiplinksComponent implements AfterViewInit {
  
  public linkList : ISkipLink[] = [];
  public isHidden : boolean = false;
  private subscription: Subscription;

  constructor(
    private router: Router,
    private skipLinksService: SkipLinksService,
  ) {}

  ngAfterViewInit(): void {
    this._setLinkList();
  }

  // evite le scroll trop bas par rapport a l'element DOM
  @HostListener('window:hashchange', ['$event'])
  onHashChange(event: Event): void {
    this.handleHashChange();
  }

  // evite le scroll trop bas par rapport a l'element DOM
  private handleHashChange(): void {
    const fragment = window.location.hash.substr(1);
    if (fragment) {
      const element = document.getElementById(fragment);
      
      if (element) {
        // Faire défiler jusqu'à l'ancre
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

        // Ajuster manuellement la position après le défilement
        const offset = 0;
        window.scrollTo({ top: window.scrollY + offset, behavior: 'smooth' });
      }
    } else {
      // Si l'ancre est vide, faire défiler vers le haut
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  // function to init linklist
  private _setLinkList(): void {
    // first init 
    setTimeout(() => {this.linkList = this._collectSkipLinks();},0);

    // Subscription
    this.subscription = this.skipLinksService.getObservable().subscribe(() => {
      setTimeout(() => {
        const tempLinkList = this._collectSkipLinks();
        // Except auto close with demand prio
        if(tempLinkList[1]?.label === "Demandes nécessitant une intervention") {
          this.linkList[1] = tempLinkList[1];
          this.linkList[2] = tempLinkList[2];
          this.linkList[3] = tempLinkList[3];
        } else if (this.linkList.length === 1) {
          tempLinkList.splice(0, 1);
          this.linkList.push(...tempLinkList)
        } else {
          this.linkList = tempLinkList;
        }
      }, 50);
    });

    // Reload skiplink on route change
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))  
      .subscribe((event: NavigationEnd) => {
        setTimeout(() => {this.linkList = this._collectSkipLinks();},0);
    });
  }

  // Collect DOM element with ID like skiplink-
  // For every element, collect id for href and arialabel for label
  private _collectSkipLinks(): ISkipLink[] {
    let tempLinkList : ISkipLink[] = [];
    const elements = document.querySelectorAll('[id^=skiplink-]');
    elements.forEach(element => {
      tempLinkList.push({"href":'#'+element.id,"label":element.ariaLabel});
    });
    return tempLinkList.filter((v,i,a)=>a.findIndex(v2=>(v2.label===v.label))===i);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

// TO ADD SKIPLINKS
// At position where you want to add skiplink
// Add an ID starting with `skiplink-`
// Add an ariaLabel for the text button
// This component will found it automaticly in DOM

// SKIPLINKS can be reload from external component with skiplinks service