import { Component, OnInit, Input, OnDestroy, SimpleChanges, HostListener, ViewChild } from '@angular/core';
import { first, clone, flatten } from 'lodash';
import { Demand } from 'src/app/shared/models/demand';
import { User, UserType } from 'src/app/shared/models/user';
import { environment } from '@env/environment';
import {
  trigger,
  transition,
  state,
  style,
  animate,
} from '@angular/animations';
import { getUserMode, userIsExploitant } from '@app/shared/helpers/user-modes-helper';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { Subscription } from 'rxjs';
import { IAttachment } from '@app/shared/models/attachment';
import { IAssignation } from '@app/shared/models/assignations';
import * as _ from 'lodash';
import { demandMatchState, getDemandStatusLabel } from '@app/shared/helpers/demand-helper';
import * as moment from 'moment';
import { NavigationStart, Router } from '@angular/router';
import Toast from '@app/shared/helpers/toast';
import { isAssigned } from '@app/shared/helpers/user-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { IUpdateHistory } from '@app/shared/models/updatehistory';
import translate from '@assets/i18n/fr.json'
import { DatePipe } from '@angular/common';

interface ICurrentState {
  label: string;
  code: string;
}
interface IMessage {
  user: User;
  message: string;
  created_at: string;
  left?: boolean;
  mode?: UserType;
  attachments: IAttachment[];
  isAssignmentMessage?: boolean;
  isUpdateMessage?: boolean;
}
interface IHistoryPoint {
  current_state?: ICurrentState;
  created_at: string;
  user?: User;
  created_by?: User;
  messages: IMessage[];
  isP?: boolean;
  showMsg?: boolean;
  mode?: string;
  message?: string;
  comment?: string;
  attachments: IAttachment[];
}

@Component({
  selector: 'app-treatment-monitoring-print',
  templateUrl: './treatment-monitoring-print.component.html',
  styleUrls: ['./treatment-monitoring-print.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          display: 'block',
          opacity: 1,
        }),
      ),
      state(
        'closed',
        style({
          opacity: 0,
          display: 'none',
        }),
      ),
      transition('* => *', [animate('0.3s')]),
    ]),
  ],
})
export class TreatmentMonitoringPrintComponent implements OnInit, OnDestroy {
  @Input() demand: Demand;
  @Input() showEveryMessages: boolean = true;
  @Input() forPdf: boolean;
  toggle_feature_internal_comment_history: boolean = environment.toggle_feature_internal_comment_history;
  toggle_feature_add_internal_comment_history: boolean = environment.toggle_feature_add_internal_comment_history;
  toggle_feature_internal_comment = environment.toggle_feature_internal_comment;
  toggle_feature_internal_reference = environment.toggle_feature_internal_reference;
  client_name: string = environment.client_name;
  public userMode = getUserMode();
  history: Array<IHistoryPoint | any>;
  subscription: Subscription;
  attachmentNumber: number = 0;
  updateHistoryMessage = translate['demand-update-history'];

  getDemandStatusLabel = getDemandStatusLabel;

  sub: Subscription;
  user: User;

  labelDate1: string;
  labelDate2: string;

  @ViewChild('int_comment') internal_comment;
  @ViewChild('int_ref') internal_reference;
  @ViewChild('receiveDate') receive_date;
  @ViewChild('closeDate') close_date;

  /* The number of assignments among a transition */
  public countUpdateHistory: (messages: IMessage[]) => number = _.partialRight(
    this.countMessagesBy,
    (message) => message.isUpdateMessage,
  );
  /* The number of assignments among a transition */
  public countAssignments: (messages: IMessage[]) => number = _.partialRight(
    this.countMessagesBy,
    (message) => message.isAssignmentMessage,
  );
  /* The number of regular messages among a transition */
  /* TODO: Update for file icon */
  public countComments: (messages: IMessage[]) => number = _.partialRight(
    this.countMessagesBy,
    (message) => (!message.isAssignmentMessage && !message.isUpdateMessage) || message.comment,
  );

  constructor(
    private demandService: DemandService,
    private router: Router,
    private authService: AuthenticationService,
    private datePipe: DatePipe) {
    this.sub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (this.demand) {
          if (this.toggle_feature_internal_comment) {
            this.updateDemandInternalComment();
          }
          if (this.toggle_feature_internal_reference) {
            this.updateDemandInternalReference();
          }
        }
      }
    });

    this.authService.getUser().then((_user) => {
      this.user = _user as User;
    });

    this.subscription = this.demandService.getDemandUpdated().subscribe((demand) => {
      this.demand = demand;
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.labelDate1 = "Date réelle de réception de la Demande";
    this.labelDate2 = this.client_name == 'setom' ? "Date réelle de réalisation de la Demande" : "Date réelle de clôture de la Demande";
    this.buildHistory();
    if (userIsExploitant()) {
      this.addAssignmentsToHistory();
      this.addUpdateToHistory();
      this.groupMessageByDate();
      this.addInternalCommentToHistory();
    } else {
      this.addUpdateToHistory();
      this.groupMessageByDate();
    }
    //this.showCurrentStatusMessages();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.demand) {
      this.ngOnInit();
    }
  }

  @HostListener('window:beforeprint')
  onbeforeprint() {
    // Open all messages when printing (= expansion panels opened)
    let elmsToDisplay = Array.from(document.getElementsByClassName("messages") as HTMLCollectionOf<HTMLElement>)
    elmsToDisplay.forEach(el => {
      el.style.display = "block"
      el.style.opacity = "1"
    })
  }

  @HostListener('window:afterprint')
  onafterprint() {
    // Reinitiate the page (ngOnInit is called through the subscription in constructor)
    this.demandService.updateDemand(this.demand)
  }

  messagesToShow(messages: IMessage[]) {
    // When printing, this method prevent from displaying an empty <div>
    if (this.forPdf && (messages.length === 0 || messages.every(msg => msg.isAssignmentMessage === true))) return false
    return true
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  buildHistory() {
    /* Merge transitions and point d'avancements */
    const transitions = _.cloneDeep(this.demand.transitions);
    if (this.toggle_feature_internal_comment_history) {
      this.history = this.sortByCreatedAt(
        transitions.concat(
          this.demand.point_avancement.map((x) => {
            x.isP = true;
            return x;
          }),
        ),
      );
    } else {
      this.history = this.sortByCreatedAt(
        transitions.concat(
          this.demand.point_avancement.filter((point) => point.type === "PA").map((x) => {
            x.isP = true;
            return x;
          }),
        ),
      );
    }
    /* Add point d'avancements to their status */
    this.history.forEach((point, i, arr) => {
      if (point.isP && (point.type !== 'COM' || (point.type === 'COM' && userIsExploitant()))) {
        let x = 1;
        while (arr[i + x].isP) { x++; }
        arr[i + x].messages.push({
          user: point.created_by,
          message: point.message || 'Point d\'avancement demandé',
          created_at: point.created_at,
          left: point.mode.toLowerCase() == UserType.DEMANDEUR,
          attachments: point.attachments,
          type: point.type,
        });
        this.sortByCreatedAt(arr[i + x].messages);
      }
    });

    /* Remove point d'avancements from history as they are in transition messages now */
    this.cleanHistory((h) => !h.isP);
    this.history.forEach((point) => {
      let attachmentNumber: number = 0;
      let pointAttachmentNumber: number = 0;
      let hadAttach: boolean = false;
      point.hadAttachments = {
        bool: false,
        value: 0
      }
      if (point.attachments && point.attachments.length !== 0) {
        hadAttach = true;
        pointAttachmentNumber = pointAttachmentNumber + point.attachments.length;
      }
      if (point.messages && point.messages.length !== 0) {
        point.messages.forEach((msg) => {
          if (msg.attachments && msg.attachments.length !== 0) {
            hadAttach = true;
            attachmentNumber = attachmentNumber + msg.attachments.length;
          }
        });
        point.hadAttachments = {
          bool: hadAttach,
          value: attachmentNumber + pointAttachmentNumber
        }
      }
    });
  }

  private addAssignmentsToHistory() {
    let assignationsHistory = _.cloneDeep(this.demand.assignations.history);

    /* Create the original assignment for deleted ones */
    assignationsHistory = flatten(
      assignationsHistory.map((assignment) => {
        if (assignment.deleted) {
          const original_assign = clone(assignment);
          assignment.created_at = assignment.updated_at;
          original_assign.deleted = false;
          return [assignment, original_assign];
        }
        return assignment;
      }));

    this.history = this.sortByCreatedAt(
      this.history.concat(
        assignationsHistory.map((assignment) => {
          assignment.isAssignment = true;
          return assignment;
        }),
      ),
    );

    /* Add assignation into transition message */
    this.history.forEach((point, i, arr) => {
      if (point.isAssignment) {
        let x = 1;
        while (arr[i + x].isAssignment) { x++; }
        arr[i + x].messages.push({
          user: point.deleted ? point.unassigned_by : point.assigned_by,
          message: this.getAssignmentMessage(point),
          comment: this.getAssignmentComment(point),
          created_at: point.created_at,
          isAssignmentMessage: true,
          left: false,
        });
        this.sortByCreatedAt(arr[i + x].messages);
      }
    });

    /* Remove assignments from history as they are in transition messages now */
    this.cleanHistory((h) => !h.isAssignment);
  }

  private addUpdateToHistory() {
    let updatesHistory = _.cloneDeep(this.demand.updateHistory);

    /* Create the original update for deleted ones */
    updatesHistory = flatten(
      updatesHistory.map((update) => {
        return update;
      }));

    this.history = this.sortByCreatedAt(
      this.history.concat(
        updatesHistory.map((update) => {
          update.isUpdate = true;
          return update;
        }),
      ),
    );

    /* Add update into transition message */
    this.history.forEach((point, i, arr) => {
      if (point.isUpdate) {
        if(["internal_comment", "internal_comment_link"].includes(point.field)) {
          return
        }
        let x = 1;
        while (arr[i + x].isUpdate) { x++; }
        arr[i + x].messages.push({
          user: point.user,
          message: this.getUpdateHistoryMessage(point),
          created_at: point.created_at,
          isUpdateMessage: true,
          left: point.mode.toLowerCase() == UserType.DEMANDEUR,
        });
        this.sortByCreatedAt(arr[i + x].messages);
      }
    });

    /* Remove update from history as they are in transition messages now */
    this.cleanHistory((h) => !h.isUpdate);
  }

  private getUpdateHistoryMessage(updateHistory: IUpdateHistory, deleted?): string {
    // check env
    if(this.client_name == "setom") {
      if (['close_date'].includes(updateHistory.field)) {
        return `${this.updateHistoryMessage[updateHistory.field + "_setom"]} :
        ${this.datePipe.transform(updateHistory.original, 'dd/MM/yyyy HH:mm') == null || updateHistory.original == "" ? "'-'" : `'${this.datePipe.transform(updateHistory.original, 'dd/MM/yyyy HH:mm')}'`
          }
         en
        ${this.datePipe.transform(updateHistory.modified, 'dd/MM/yyyy HH:mm') == null || updateHistory.modified == ""  ? "'-'" : `'${this.datePipe.transform(updateHistory.modified, 'dd/MM/yyyy HH:mm')}'`
          }.`;
      }
    }
    // Check if is an attachments
    if (['attachments'].includes(updateHistory.field)) {
      let attachmentsType: string = updateHistory.field
      this.demand.attachments.forEach(obj => {
        if ((obj.file_name == updateHistory.modified) && obj.kind == "URL") {
          attachmentsType = "attachments_url"
        }
        if ((obj.file_name == updateHistory.modified) && obj.kind == "FILE") {
          attachmentsType = "attachments_file"
        }
      })
      return `${this.updateHistoryMessage[attachmentsType]} sous le nom suivant : '${updateHistory.modified}'.`;
    }
    // Check if field is a date
    if (['realisation_datetime', 'close_date', 'receive_date'].includes(updateHistory.field)) {
      return `${this.updateHistoryMessage[updateHistory.field]} :
      ${this.datePipe.transform(updateHistory.original, 'dd/MM/yyyy HH:mm') == null || updateHistory.original == "" ? "'-'" : `'${this.datePipe.transform(updateHistory.original, 'dd/MM/yyyy HH:mm')}'`
        }
       en
      ${this.datePipe.transform(updateHistory.modified, 'dd/MM/yyyy HH:mm') == null || updateHistory.modified == "" ? "'-'" : `'${this.datePipe.transform(updateHistory.modified, 'dd/MM/yyyy HH:mm')}'`
        }.`;
    }
    // Do not show before/after
    if (['description', 'actions_to_go'].includes(updateHistory.field)) {
      return `${this.updateHistoryMessage[updateHistory.field]}.`;
    }
    // check If is boolean
    if(['send_ilotier'].includes(updateHistory.field)) {
      return `${this.updateHistoryMessage[updateHistory.field]} :
      ${updateHistory.original == null || updateHistory.original == "" ? "'-'" : `'${(updateHistory.original == 'False' ? 'non' : 'oui')}'`
        }
       en
       ${updateHistory.modified == null || updateHistory.modified == "" ? "'-'" : `'${(updateHistory.modified == 'False' ? 'non' : 'oui')}'`
        }.`;
    }
      // Reopen
      if (updateHistory.action == "reopen_imp") {   
       
        return this.updateHistoryMessage[updateHistory.action] 
      }

    // Default
    return `${this.updateHistoryMessage[updateHistory.field]} :
    ${
      updateHistory.original == null || updateHistory.original == "" ? "'-'" : `'${updateHistory.original.split('_').join(' ')}'`
    }
      en
    ${
      updateHistory.modified == null || updateHistory.modified == "" ? "'-'" : `'${updateHistory.modified.split('_').join(' ')}'`
    }
    .`;
  }

  private getAssignmentComment(assignment: IAssignation): string {
    if (assignment.comment && !assignment.deleted) return `a écrit : ${assignment.comment}`;
    if (assignment.comment_desa && assignment.deleted) return `a écrit : ${assignment.comment_desa}`;
  }

  private getAssignmentMessage(assignment: IAssignation, deleted?): string {
    deleted = deleted !== undefined ? deleted : assignment.deleted;
    const action = deleted ? 'retiré' : 'ajouté';

    let messageEnd = '';
    if (assignment.assign_type === 'INTERVENANT') {
      const complement = deleted ? 'de' : 'à';
      messageEnd = complement + ' la liste des intervenants';
    } else {
      messageEnd = deleted ? 'du rôle de référent' : 'en tant que référent';
    }
    return `a ${action} ${assignment.user.email} ${messageEnd}`;
  }

  // Group messages with close date (For assignations)
  private groupMessageByDate() {
    let getDiffDate = ((x, y) => moment.duration(moment(x).diff(moment(y))));
    let newIndex = (x, y) => {
      x.push({
        comment: y.comment,
        date: y.created_at,
        isAssignmentMessage: y.isAssignmentMessage ? true : false,
        isUpdateMessage: y.isUpdateMessage ? true : false,
        left: y.left ? y.left : y.mode ? y.mode.toLowerCase() == UserType.DEMANDEUR : undefined,
        messages: [y],
        user: y.user,
        type: y.type ? y.type : null
      });
    }
    this.history.forEach(hist => {
      let mergedAssign = [];
      hist.messages.forEach(message => {
        if (mergedAssign.length == 0) newIndex(mergedAssign, message);
        else {
          let group = mergedAssign.find(group =>
            getDiffDate(group.date, message.created_at).asMilliseconds() < 5000
          );
          if (group) {
            group.messages.push(message)
          }
          else newIndex(mergedAssign, message);
        }
      });
      hist.groupMessages = this.orderAssignation(mergedAssign);
    });
  }

  public getMessage(message) {
    return message.messages.map(x => x.message).join("<br>");
  }

  // Order assignations actions by assigned person
  private orderAssignation(assignations) {
    assignations.forEach(x => {
      if (x && x.isAssignmentMessage) {
        x.messages.forEach((message, index, arr) => {
          let splited = message.message.split(' ');
          if (splited[1] == 'retiré') {
            // If we find another action with the same person, we put this action after the first one
            var matchingIndex = arr.findIndex(mess => mess.message.split(' ')[1] == 'ajouté' && mess.message.split(' ')[2] == splited[2]);
            if (matchingIndex != -1) {
              x.messages.splice(index + 1, 0, x.messages[matchingIndex]);
              x.messages.splice(index < matchingIndex ? matchingIndex + 1 : matchingIndex, 1);
            }
          }
        });
      }
    });

    return assignations;
  }

  private cleanHistory(fn) {
    this.history = this.history.filter(fn);
  }

  private showCurrentStatusMessages() {
    const historyCurrentStatus = this.history.find((h) => {
      return h.current_state && h.current_state.code === this.demand.workflow_current_state.code;
    });
    historyCurrentStatus.showMsg = historyCurrentStatus.messages.length > 0;
  }

  public showPointMessages(point: IHistoryPoint): boolean {
    return point.showMsg;
  }

  private countMessagesBy(
    messages: IMessage[],
    filterFn: (messages: IMessage) => boolean,
  ): number {
    return messages
      .filter(filterFn)
      .length;
  }

  private sortByCreatedAt(arr) {
    return (
      arr.sort((x, y) =>
        new Date(x.created_at) < new Date(y.created_at) ? 1 : -1,
      )
    );
  }

  private addInternalCommentToHistory() {
    if (this.toggle_feature_add_internal_comment_history) {
      if (this.demand.history_comments && this.demand.history_comments.length) {
        const history_comments = _.cloneDeep(this.demand.history_comments);
        const commentToPoint = history_comments.map((x) => {
          let y = {
            user: x.created_by,
            message: x.message,
            created_at: x.date,
            left: false,
            isAssignmentMessage: false
          }
          return y;
        });
        this.history = this.sortByCreatedAt(
          this.history.concat(
            commentToPoint.map((com) => {
              com.isComment = true;
              return com;
            }),
          ),
        );
        this.history.forEach((point, i, arr) => {
          if (point.isComment) {
            let x = 1;
            while (arr[i + x].isComment) { x++; }
            arr[i + x].messages.push({
              user: point.user,
              message: 'a écrit : ' + point.message,
              created_at: point.created_at,
              isCommentMessage: true,
              left: false,
            });
            this.sortByCreatedAt(arr[i + x].messages);
          }
        });
        /* Remove comments from history as they are in transition messages now */
        this.cleanHistory((h) => !h.isComment);
      }
    }
  }

  historyMessageRightSide(message: IMessage): boolean {
    return !(message.left ||
      (message.mode && (message.mode.toLowerCase() == UserType.DEMANDEUR)));
  }
  // FIXME:: Either make this available globally,
  // Or use it if it's already defined
  fullName(point: IHistoryPoint): string {
    const user = point.user || point.created_by;
    if (user.first_name === 'Lineo') {
      user.first_name = 'Action';
      user.last_name = 'automatique';
    }
    return `${user.first_name} ${user.last_name}`;
  }

  bubbleColor(point: IHistoryPoint): string {
    return `dmd-status--${getUserMode() ||
      'demandeur'}_${point.current_state ? point.current_state.code.slice(8).toLowerCase() : ''}`;
  }

  toggleMsg(point: IHistoryPoint): void {
    point.showMsg = !point.showMsg;
  }

  updateDemandInternalComment() {
    if (this.internal_comment
      && this.demand.internal_comment !== this.internal_comment.nativeElement.value
      && !(this.demand.internal_comment === null && this.internal_comment.nativeElement.value === '')) {

      this.demand.internal_comment = this.internal_comment.nativeElement.value;
      this.demandService.updateDemandInternalComment(this.demand.id, this.internal_comment.nativeElement.value).then(() => {
        Toast.info("Commentaire interne mis à jour.");
      })
        .catch((err) => {
          console.log(err);
          Toast.info("Problème lors de la mise à jour du commentaire interne.");
        });
    }
  }

  updateDemandInternalReference() {
    if (this.internal_reference
      && this.demand.internal_reference_number !== this.internal_reference.nativeElement.value
      && !(this.demand.internal_reference_number === null && this.internal_reference.nativeElement.value === '')) {

      this.demand.internal_reference_number = this.internal_reference.nativeElement.value;
      this.demandService.updateDemandInternalReference(this.demand.id, this.internal_reference.nativeElement.value).then(() => {
        Toast.info("N° Référence interne mis à jour.");
      })
        .catch((err) => {
          console.log(err);
          Toast.info("Problème lors de la mise à jour du N° référence interne.");
        });
    }
  }

  canChangeInternalFields() {
    return (!demandMatchState(this.demand, [DemandStateCode.CLOTUREE,]) && isAssigned(this.user, this.demand))
      || demandMatchState(this.demand, [DemandStateCode.ENVOYEE]);
  }
  
}
