import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ajout-url-modal',
  templateUrl: './ajout-url-modal.component.html',
  styleUrls: ['./ajout-url-modal.component.scss']
})
export class AjoutUrlModalComponent implements OnInit {

  @ViewChild('input') input;
  @ViewChild('inputUrl') inputUrl;

  constructor(
    public matDialogRef: MatDialogRef<AjoutUrlModalComponent>,
    private breakPoint: BreakpointObserver,
  ) { }

  title = "Ajouter un lien";
  isUrlOK = true;

  ngOnInit() {
    const maxWidth = '(max-width: 991px)';
    const minWidth = '(min-width: 992px)';
    if (this.breakPoint.isMatched(maxWidth)) {
      this.matDialogRef.updateSize('100vw', '100vh');
    }
    this.breakPoint.observe([maxWidth, minWidth])
      .subscribe((result) => {
        if (result.breakpoints[maxWidth]) {
          this.matDialogRef.updateSize('100vw', '100vh');
        } else {
          this.matDialogRef.updateSize('600px');
        }
      });
  }

  confirm() {
    // Check that the url is valid
    let url = this.inputUrl.nativeElement.value;
    let pattern: RegExp = new RegExp('http+');
    this.isUrlOK = pattern.test(url);
    if (this.isUrlOK) {
      this.matDialogRef.close({
        status: true,
        description: this.input.nativeElement.value,
        url: this.inputUrl.nativeElement.value,
      });
    }
  }

  refuse() {
    this.matDialogRef.close({
      status: false,
      description: null,
      url: null,
    });
  }

}
