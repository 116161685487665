import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemandPrioCardComponent } from './demand-prio-card.component';
import { MatChipsModule } from '@angular/material/chips';
import {MaterialModule} from "@app/material/material.module";

@NgModule({
    imports: [
        CommonModule,
        MatChipsModule,
        MaterialModule,
    ],
  exports: [
    DemandPrioCardComponent,
  ],
  declarations: [
    DemandPrioCardComponent,
  ],
})
export class DemandPrioCardModule { }
