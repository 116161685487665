import { Component, OnInit, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Demand } from '@app/shared/models/demand';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import { Subject, Subscription } from 'rxjs';
import { DemandService } from '@app/shared/services/demand/demand.service';
import moment from 'moment';
import { environment } from '@env/environment';

@Component({
  selector: 'app-demand-print',
  templateUrl: './demand-print.component.html',
  styleUrls: ['./demand-print.component.scss']
})
export class DemandPrintComponent implements OnInit, AfterViewInit, OnDestroy {
  
  public demand: Demand;
  public userMode : string = null;
  private subscription: Subscription;
  public updateStream = new Subject()

  constructor(
    private route: ActivatedRoute,
    private demandService: DemandService,
  ) {
    this.subscription = this.demandService.getDemandUpdated().subscribe((demand) => {
      this.demand = demand;
    });
  }

  ngOnInit() {
    this.userMode = getUserMode().toLowerCase();
    this.route.params.subscribe(this.demand = this.route.snapshot.data.demand);
  }

  ngAfterViewInit(): void {
    this.updateStream.subscribe(() => {
      setTimeout( () => { this.print() }, 250 );
    })
  }

  print() {
    /* Ading the don't print class through js
    as elements are not accessible in template */
    document.querySelector('.mat-tab-labels')?.classList.add('d-print-none');
    let documentCurrentTitle =  this.getTitle();
    document.title = documentCurrentTitle;
    this.AddMessageSection();
    window.print();
    document.title = documentCurrentTitle;
  }

  private AddMessageSection(): void {
    /* Add the demandeur message element to the printed section
      if user is exploitant and there is a message */
    const messageElement = document.querySelector('.aside-message');
    if (messageElement) {
      document.querySelector('#pdfDemandStatus')
        ?.insertAdjacentElement('afterend', messageElement);
    }
  }

  getTitle() {
    const dateNow = moment().format('YYYYMMDD_HHmmss');
    return `demande${this.demand.id}_${environment.app_name_short}_${getUserMode()}_${dateNow}.pdf`;
  }

  @HostListener('window:afterprint')
  afterprint() {
    document.title = this.getTitle();
    window.location.replace(window.location.href.replace("demands/print/", "demands/"))
    window.location.reload()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showAssignment(): boolean {
    return !demandMatchState(this.demand, [
      DemandStateCode.A_ENVOYER,
      DemandStateCode.ENVOYEE,
    ]);
  }
}
