import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PriorityDemandResponse } from '@app/shared/models/priority-demand-response';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { PriorityDemand } from '@app/shared/models/priority-demand';
import { SkipLinksService } from '@app/shared/services/skiplinks/skiplinks.service';

@Component({
  selector: 'app-demands-prio',
  templateUrl: './demands-prio.component.html',
  styleUrls: ['./demands-prio.component.scss'],
})
export class DemandsPrioComponent implements OnInit {
  public priorityDemands = [];
  public priorityDemandsCount = 0;
  private defaultLength = 4;
  public isLoading = false;
  public toggleSeeMore = true;

  constructor(
    private router: Router,
    private demandService: DemandService,
    private skipLinksService: SkipLinksService,
  ) {
  }

  ngOnInit() {
    this.getPriorityDemands().then((e) => {
      setTimeout(() => {this.skipLinksService.next();},0)
    });
  }

  getPriorityDemands(size?: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.isLoading = true;
      this.demandService.getPriorityDemands(size || this.defaultLength).subscribe((response: PriorityDemandResponse) => {
        this.priorityDemandsCount = response.count;
        this.priorityDemands = response.results;
        this.isLoading = false;
        resolve();
      });
    });
  }

  goToDemandPage(demand: PriorityDemand) {
    this.router.navigate([`/demands/${ demand.id }`]);
  }

  seeMore() {
    this.getPriorityDemands(50).then(() => {
      this.toggleSeeMore = false;
      if(this.priorityDemandsCount > this.defaultLength) {
        setTimeout(()=>{                           
          document.getElementById("btn-" + this.priorityDemands[this.defaultLength].id.toString()).focus()
      }, 50);
      }
    });

  }

  seeLess() {
    this.priorityDemands = this.priorityDemands.slice(0, 4);
    this.toggleSeeMore = true;
  }

}
