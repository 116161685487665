<ng-template #footerButtons>
  <div id="skiplink-demand-action" aria-label="Actions demande" >
    <app-demand-pdf-btn [defaultStyle]="false"></app-demand-pdf-btn>
    <button *ngIf="demand.attachments?.length > 0 || paGotAttachment" mat-stroked-button class="demand-action-btn all-btn download-all-btn mat-primary" (click)="allDownload()">
      <mat-icon>attach_file</mat-icon> TOUT TÉLÉCHARGER
    </button>
    <app-demand-upload-btn *ngIf="hasPermission() && isAddAttachementEnable()" (reloadHistory)="reloadHistory.emit(true)" [demand]="demand"></app-demand-upload-btn>
    <div class="btn-wrapper" *ngIf="hasPermission() && isAddAttachementEnable()">
      <button (click)="addUrl()" mat-stroked-button class="uppercased demand-action-btn mat-primary">
          <mat-icon class="mat-18" style="margin-bottom: 5px;">link</mat-icon>
          Ajouter un lien
      </button>
    </div>
    <app-btn-apporter-precisions class="btn-transition-outlined" [demand]="demand" *ngIf="hasPermission()"></app-btn-apporter-precisions>
    <app-btn-demander-point-avancement [demand]="demand" *ngIf="hasPermission()"></app-btn-demander-point-avancement>
    <app-btn-abandon-demand [demand]="demand" *ngIf="hasPermission()"></app-btn-abandon-demand>
    <app-btn-envoyer-demand [demand]="demand" *ngIf="hasPermission()"></app-btn-envoyer-demand>
    <app-btn-rejeter-cloture [demand]="demand" *ngIf="hasPermission()"></app-btn-rejeter-cloture>
    <app-btn-accepter-cloture class="btn-transition-outlined" [demand]="demand" *ngIf="hasPermission()"></app-btn-accepter-cloture>
    <app-btn-rejeter-abandon [demand]="demand" *ngIf="hasPermission()"></app-btn-rejeter-abandon>
    <ng-container *ngIf="isUserAuthorizedForReopenDemand && demand?.workflow_current_state.code === 'DEMANDE_CLOTUREE'">
      <button mat-stroked-button 
        *ngIf="!isReopenRequested" 
        (click)="openDialog();" 
        class="uppercased mat-primary"
        [ngClass]="defaultStyle ? 'demand-action-btn-pdf-default' : 'demand-action-btn-pdf'">
        <mat-icon>replay</mat-icon>Réouverture de la demande
      </button>
    </ng-container>
    
    <app-btn-accepter-abandon [demand]="demand" class="btn-transition-outlined" *ngIf="hasPermission()"></app-btn-accepter-abandon>
  </div>
</ng-template>

<main class="main-view">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="progressbar"></mat-progress-bar>
  <app-lineo-breadcrumb [childPageName]="'Consultation demande'"></app-lineo-breadcrumb>
  <div id="main-content">
    <h2 class="header title">
      Consultation demande
        <button aria-label="Demande précédente" mat-button (click)="goToPreviousDemand()" [disabled]="!previousDemand"
          *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand)"
          style="font-size: 30px;" class="mat-primary chevron">
          &lt;
        </button>
        <button aria-label="Demande suivante" mat-button (click)="goToNextDemand()" [disabled]="!nextDemand"
          *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand)"
          style="font-size: 30px;" class="mat-primary chevron">
          &gt;
        </button>
    </h2>
    <app-demand-page-header [demand]="demand"></app-demand-page-header>
    <app-demand-page-content [demand]="demand"></app-demand-page-content>
  </div>
</main>

<footer class="dmd-status demand-footer hide-mobile">
  <app-demand-status-info [demand]="demand">
  </app-demand-status-info>

  <div class="dmd-status-btn-wrap--demandeur" style="display: flex; align-items: center;">
    <ng-content *ngTemplateOutlet="footerButtons"></ng-content>
  </div>
</footer>
<!-- Mobile / Tablet footer -->
<mat-accordion class="footer-accordion hide-desktop">
  <mat-expansion-panel [disabled]="false">
    <mat-expansion-panel-header>
      <app-demand-status-info [demand]="demand" [showDate]="true">
      </app-demand-status-info>
    </mat-expansion-panel-header>
    <div class="footer-body" [ngStyle]="{'margin-top': isSmallScreen ? '35px' : '0'}">
      <ng-content *ngTemplateOutlet="footerButtons"></ng-content>
    </div>
  </mat-expansion-panel>
</mat-accordion>
