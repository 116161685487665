import { Component, OnInit, Inject } from '@angular/core';
import * as _ from 'lodash';
import { Chart } from 'angular-highcharts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-indicator-detail-graph',
  templateUrl: './indicator-detail-graph.component.html',
  styleUrls: ['./indicator-detail-graph.component.scss']
})
export class IndicatorDetailGraphComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialogRef: MatDialogRef<IndicatorDetailGraphComponent>,
  ) { }

  chart;
  indicator;

  ngOnInit() {
    this.indicator = this.inputData.indicator;

    this.chart = new Chart({
      chart: {
        type: this.indicator.graphType,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        formatter(): string {
          return this.point.name + ': <b>' + this.point.y + '</b>';
        },
      },
      xAxis: {
        categories: this.inputData.graph.serie1.map((i) => i['name']),
      },
      yAxis: {
        title: null,
      },
      title: {
        text: null,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          events: {
            click: (event) => {
              // TODO - Recherche des demandes ayant pour objet : event.point.key
              //console.log(event, event.point.key);
            }
          }
        },
      },
      series: [{
        data: this.inputData.graph.serie1,
        color: "#757575",
        size: '100%',
        innerSize: '85%',
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
        name: 'Clôturée',
      }, {
        data: this.inputData.graph.serie2,
        color: 'orange',
        size: '100%',
        innerSize: '85%',
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
        name: 'En cours',
      }],
    } as any);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
