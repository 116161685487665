import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExploitantComponent } from './exploitant.component';
import { ExploitantDemandComponent } from './exploitant-demand/exploitant-demand.component';
import { DemandResolver } from '../demands/components/demand/demand.resolver';
import { DemandPrintComponent } from '@app/shared/components/demand-print/demand-print.component';
import { environment } from '@env/environment';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: ExploitantComponent,
    title: 'Accueil - ' + environment.app_name_short.toUpperCase(),
  },
  {
    path: 'demands/:id',
    component: ExploitantDemandComponent,
    runGuardsAndResolvers: 'always',
    resolve: { demand: DemandResolver },
    title: 'Consultation demande - ' + environment.app_name_short.toUpperCase(),
  },
  {
    path: 'demands/print/:id',
    component: DemandPrintComponent,
    resolve: { demand: DemandResolver },
    title: 'Impression demande - ' + environment.app_name_short.toUpperCase(),
  },
  {
    path: 'demands/:id/attachments/download/:attachementId',
    component: ExploitantDemandComponent,
    resolve: { demand: DemandResolver },
    title: environment.app_name_short.toUpperCase(),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExploitantRoutingModule { }
