import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { CreateNotificationModalComponent } from '@app/components/modals/create-notification-modal/create-notification-modal.component';
import { DetailNotificationModalComponent } from '@app/components/modals/detail-notification-modal/detail-notification-modal.component';
import Toast from '@app/shared/helpers/toast';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import { AppNotification } from '@app/shared/models/notification';
import { Region } from '@app/shared/models/region';
import { Territory } from '@app/shared/models/territory';
import { User } from '@app/shared/models/user';
import { ContractsService } from '@app/shared/services/contracts.service';
import { NotificationService } from '@app/shared/services/notification/notification.service';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import * as moment from 'moment';
import { SkipLinksService } from "@app/shared/services/skiplinks/skiplinks.service";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  userContracts = [];
  contract_label = environment.contract_label;
  client_name = environment.client_name;
  isFilterEnabled = true;
  isLoading = true;
  user: User;
  isParam: boolean = false;
  isNotificationAutomatiqueActive: boolean = true;

  notifContracts = [];
  selectedContracts: string[] = [];
  applySelectedContracts: string[] = [];
  contracts = [];
  allContractsSelected = false;
  allContractsSelectedIndeterminate = false;

  toogle_feature_show_contract_upper_levels = environment.toogle_feature_show_contract_upper_levels;
  regions: Region[] = [];
  selectedRegions: string[] = [];
  territories: Territory[] = [];
  selectedTerritories: string[] = [];

  domains = [];
  selectedDomains: number[] = [];
  applySelectedDomains: number[] = [];

  @ViewChild('select') slctContract;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private contractsService: ContractsService,
    private datePipe: DatePipe,
    private skipLinksService: SkipLinksService,
  ) { }

  /* Notifications */
  notificationColumns = [
    {
      label: 'Titre',
      key: 'title',
      sticky: false,
      sortable: true,
      filter: 'text',
      type: 'attach_link'
    },
    {
      label: 'Date',
      key: 'date',
      sticky: false,
      sortable: true,
      filter: 'date',
      type: 'date',
    },
    {
      label: 'Émetteur',
      key: 'sender',
      sticky: false,
      sortable: true,
      filter: 'text'
    },
    {
      label: 'Description',
      key: 'text',
      sticky: false,
      sortable: true,
      filter: 'text',
      whiteSpace: 'pre-line'
    },
    {
      label: 'Action',
      key: 'action',
      sortable: false,
      stickyEnd: true,
      filter: 'none'
    }
  ];

  /* Notifications automatiques */
  automaticNotificationColumns = [
    {
      label: 'N°',
      key: 'demand',
      sticky: false,
      sortable: true,
      filter: 'number',
      type: 'link'
    },
    {
      label: 'Titre',
      key: 'title',
      sticky: false,
      sortable: true,
      filter: 'text'
    },
    {
      label: 'Date',
      key: 'date',
      sticky: false,
      sortable: true,
      filter: 'date',
      type: 'date',
    },
    {
      label: 'Description',
      key: 'text',
      sticky: false,
      sortable: true,
      filter: 'text'
    },
    {
      label: 'Impact',
      key: 'impact',
      sticky: false,
      sortable: true,
      filter: 'select'
    },
    {
      label: 'Action',
      key: 'action',
      sortable: false,
      stickyEnd: true,
      filter: 'none'
    }
  ];

  displayedNotificationColumns = [];
  receivedNotifications = [];
  receivedAllNotifications = [];

  displayedAutomaticNotificationColumns = [];
  receivedAutomaticNotifications = [];
  receivedAllAutomaticNotifications = [];
  now: string;
  isFilterPanelOpen = true;
  isContractPerimeterToggle = false;

  tempFilteredItem: any[] = [];

  ngOnInit() {
    this.now = moment(new Date()).format('YYYY-MM-DD');
    this.isContractPerimeterToggle = false;
    this.selectedContracts = [];

    this.displayedNotificationColumns = this.notificationColumns.map((column) => column.key);
    this.displayedAutomaticNotificationColumns = this.automaticNotificationColumns.map((column) => column.key);

    this.authService.getUser().then((_user) => {
      this.user = _user as User;
      this.isParam = this.user.roles.find((role) => role.id === "PARAMETREUR");

      this.authService.getUserContracts().subscribe((contracts: any[]) => {
        this.userContracts = contracts.sort((a, b) => (a.position - b.position) == 0 ? a.code.localeCompare(b.code) : (a.position - b.position));

        this.notificationService.getDomains().then((domains: any[]) => {
          this.domains = domains.sort((a, b) => a.label.localeCompare(b.label));
          this.selectedDomains = this.domains.map((domain) => domain.id);

          this.loadNotifications();
          this.setIsFilterEnabled(null);
        });
      });
    });
  }

  filterItem(ev) {
    if (ev.length > 0) {
      this.tempFilteredItem = this.slctContract.itemsList.filteredItems.filter((x) => x.value.label.toLowerCase().includes(ev.toLowerCase()) || x.value.code.includes(ev));
    } else {
      this.tempFilteredItem = this.slctContract.itemsList.filteredItems;
    }
  }

  loadNotifications() {
    this.isLoading = true;

    this.notificationService.getAllNotifications().then((receivedNotifications: AppNotification[]) => {
      let notifs = receivedNotifications
        .sort((a: AppNotification, b: AppNotification) => b.date.localeCompare(a.date))
        .map((n) => {
          return {
            ...n,
            //sender: n.sender != null ? n.sender.last_name + " " + n.sender.first_name : 'Système',
            sender: n.sender != null ? n.sender.email : undefined,
            date: moment(n.date)
          }
        });

      this.receivedAllNotifications = notifs.filter(element => element.demand === null);
      this.receivedAllAutomaticNotifications = notifs.filter(element => element.demand !== null);

      //Make an array of contracts from notifications without duplicates
      let tempContracts = [];
      notifs.forEach((notif) => {
        tempContracts = tempContracts.concat(notif.contracts);
      });
      let contractsWithNotif = _.uniq(tempContracts);

      this.notifContracts = this.userContracts.filter((contract) => {
        return contractsWithNotif.indexOf(contract.code) != -1;
      })
        .sort((a, b) => {
          return (a.position - b.position) == 0 ? a.code.localeCompare(b.code) : (a.position - b.position);
        });

      this.contracts = this.notifContracts;

      if (this.toogle_feature_show_contract_upper_levels) {
        this.regions = this.contractsService.getRegionsFromContracts(this.notifContracts);
      }

      this.isLoading = false;

      this.applyFilter();
    });
  }

  isContractExpired(contract): boolean {
    return contract.date_fin_exploitation && contract.date_fin_exploitation < this.now;
  }

  getContractTooltip(contract) {
    let tooltip = `${contract.code} - ${contract.label}`;
    if (this.isContractExpired(contract)) { tooltip += ` (${this.datePipe.transform(contract.date_fin_exploitation, 'dd/MM/yyyy')})`; }
    return tooltip;
  }

  goToDemandPage(event) {
    const demandURI = {
      demandeur: '/demands/',
      exploitant: '/exploitant/demands/',
    }[getUserMode()];
    //this.router.navigate([`${demandURI}${event.selectedItem.demand}`]);

    // Pour ouverture dans une nouvelle fenetre
    let url = this.router.createUrlTree([`${demandURI}${event.selectedItem.demand}`]);
    window.open('#' + url.toString(), '_detailDemand');
  }

  createNewNotification() {
    const dialogRef = this.dialog.open(CreateNotificationModalComponent, {
      autoFocus: false,
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === true) {
        let notification = result.data;
        let send_mail = result.data.send_mail
        delete notification.send_mail
        notification.sender_id = this.user.id;
        notification.date = moment().format();
        this.notificationService.createNotification(notification).then((savedNotification: AppNotification) => {
          if (send_mail) this.notificationService.notificationSendMail(savedNotification);
          Toast.info("Votre notification a bien été créée.", "Notification créée");
          this.loadNotifications();
        });
      }
    });
  }

  openNotification(event) {
    const dialogRef = this.dialog.open(DetailNotificationModalComponent, {
      autoFocus: false,
      disableClose: false,
      data: {
        notification: event.selectedItem,
        userContracts: this.userContracts,
        isParam: this.isParam
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  /** FILTERS */
  toggleAllPerimetre() {
    if (!this.isContractPerimeterToggle) {
      if (this.toogle_feature_show_contract_upper_levels) {
        this.selectedRegions = [];
        this.onRegionsChange(); // set selectedTerritories and contracts
      }
      this.selectedContracts = [];
    } else {
      this.selectedContracts = [];
    }
    this.onContractsChange();
  }

  onFieldSelectOpened(type) {
    if (type == 'contract') {
      this.reorderField(false, type, this.contracts, this.selectedContracts);
    }
    setTimeout(() => {
      const scrollContainer = document.querySelector('.ng-dropdown-panel-items');
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    }, 50);
  }

  reorderField(onSearchInputChange: boolean = true, type, fieldType, selectFieldType) {

    let compareValue = 'code';
    if (type == 'object' || type == 'intervenant') { compareValue = 'label'; }

    if (!onSearchInputChange) { fieldType = fieldType.sort((a, b) => {
      if (a[compareValue] < b[compareValue]) { return -1; }
      if (a[compareValue] > b[compareValue]) { return 1; }
    });
    }

    fieldType.sort((a, b) => {
      if (!selectFieldType.includes(a[compareValue]) && selectFieldType.includes(b[compareValue])) { return 1; }
      if (selectFieldType.includes(a[compareValue]) && !selectFieldType.includes(b[compareValue])) { return -1; }
      return 0;
    });

    if (type == 'contract') { this.contracts = fieldType.slice(); }
  }

  onContractsChange() {
    if (this.selectedContracts.length === 0) {
      this.allContractsSelected = false;
      this.allContractsSelectedIndeterminate = false;
    }
    else if (this.selectedContracts.length === this.contracts.length) {
      this.allContractsSelected = true;
      this.allContractsSelectedIndeterminate = false;
    } else {
      this.allContractsSelected = false;
      this.allContractsSelectedIndeterminate = true;
    }
  }

  selectAllContracts() {
    if (this.allContractsSelected) {
      this.selectedContracts = this.tempFilteredItem.length > 0 ? this.tempFilteredItem.map((x) => x.value.code) : this.slctContract.itemsList._filteredItems.map((x) => x.value.code);
    } else {
      this.selectedContracts = [];
    }
  }

  reinitFilter() {
    if (this.toogle_feature_show_contract_upper_levels) {
      this.selectedRegions = [];
      this.onRegionsChange(); // set selectedTerritories and contracts
    }
    this.selectedContracts = [];
    this.onContractsChange();
    this.selectedDomains = this.domains.map((domain) => domain.id);
    this.isContractPerimeterToggle = false;
    this.applyFilter();
  }

  applyFilter() {
    this.receivedNotifications = this.receivedAllNotifications
      .filter((notification) => {
        if (!this.isContractPerimeterToggle || !this.isParam) return true;

        if (this.selectedContracts.length > 0 && notification.contracts.length > 0) {
          return notification.contracts.some(contract => this.selectedContracts.indexOf(contract) != -1);
        } else {
          return true;
        }
      });

    this.receivedAutomaticNotifications = this.receivedAllAutomaticNotifications
      .filter((notification) => {
        let isOk = true;
        if (isOk) {
          if (this.selectedContracts.length > 0 && notification.contracts.length > 0) {
            isOk = notification.contracts.some(contract => this.selectedContracts.indexOf(contract) !== -1);
          } else {
            isOk = true;
          }
        }

        if (isOk) {
          isOk = notification.domains.some(domain => this.selectedDomains.indexOf(domain) !== -1);
        }

        return isOk;
      });

    this.applySelectedContracts = this.selectedContracts;
    this.applySelectedDomains = this.selectedDomains;
  }

  isSelectionDifferentOfApplied() {
    if (this.applySelectedDomains.length !== this.selectedDomains.length) return true;

    let result = false;
    this.applySelectedDomains.forEach((domain) => {
      if (this.selectedDomains.indexOf(domain) === -1) {
        result = true;
      }
    });
    if (result) return result;

    if (this.applySelectedContracts.length !== this.selectedContracts.length) return true;

    this.applySelectedContracts.forEach((contract) => {
      if (this.selectedContracts.indexOf(contract) === -1) {
        result = true;
      }
    });
    return result;
  }

  onTabChange(event) {
    // Hack to display the filter correctly after a tab change, otherwise the tab overlaps the table
    if (this.isFilterPanelOpen) {
      this.isFilterPanelOpen = false;
      setTimeout(() => {
        this.isFilterPanelOpen = true;
        this.skipLinksService.next();
        this.setIsFilterEnabled(event);
      });
    }

    this.isNotificationAutomatiqueActive = event.index === 0;
    this.tempFilteredItem = [];
  }

  onRegionsChange() {
    // Change the list of territories depending of the selected regions
    this.territories = [];
    if (this.selectedRegions.length > 0) {
      this.regions.forEach((region) => {
        if (this.selectedRegions.indexOf(region.code) != -1) {
          this.territories = this.territories.concat(region.territories);
        }
      });
    }

    // If a region is unselected, delete its territories from the list of selected territory
    this.selectedTerritories = this.selectedTerritories.filter((territory) => {
      return this.territories.some((t) => t.code === territory);
    });

    this.filterContracts();
  }

  onTerritoriesChange() {
    this.filterContracts();
  }

  filterContracts() {
    this.contracts = this.contractsService.filterContractsByRegionsAndTerritories(this.notifContracts, this.selectedRegions, this.selectedTerritories);

    // If the list of contract change, check that the selected contracts are still in the list
    this.selectedContracts = this.selectedContracts.filter((selectedContract) => {
      return this.contracts.some((contract) => contract.code === selectedContract);
    });
  }

  onContractSearch(term: string, contract: any) {
    let label = contract.code + ' - ' + contract.label;
    return label.toLowerCase().includes(term.toLowerCase());
  }

  private setIsFilterEnabled(event) {
    if(event == null) return
    if(environment.client_name == 'ileo') {
      if(event.index == 0) {
        this.isFilterEnabled = true;
      }
      if(event.index == 1) {
        this.isFilterEnabled = false;
      }
    }
  }
}
