<div class="row default-padding">
    <div class="col-xs-12 col-md-12">
        <h3 class="title">Régularisation demande</h3>
        <div>
            <div *ngIf="demand.receive_date != null">
                <span>{{labelDate1}} : {{demand.receive_date | date: 'dd-MM-yyyy HH:mm'}}</span>
            </div>
            <div *ngIf="demand.close_date != null">
                <span>{{labelDate2}} : {{demand.close_date | date: 'dd-MM-yyyy HH:mm'}}</span>
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-md-12">
        <h3 class="title">Historique</h3>
        <div class="history">
            <div *ngFor="let point of history;">
            <div class="point">
                <div class="sub-title">
                    <div class="bubble round" [ngClass]="[bubbleColor(point)]"></div>
                    <div class="actions" *ngIf="point.messages?.length">
                        <!--Regular messages-->
                        <button
                        mat-icon-button
                        [attr.aria-label]="'Il y a ' + countComments(point.groupMessages) + ' messages'"
                        (click)="toggleMsg(point)"
                        *ngIf="countComments(point.groupMessages)"
                        >
                        <mat-icon [matBadge]="countComments(point.groupMessages)" matBadgeSize="small" >sms</mat-icon>
                        </button>
                        <!--Attached files-->
                        <button
                        mat-icon-button
                        [attr.aria-label]="'Il y a ' + point.hadAttachments.value + ' Fichiers joints'"
                        (click)="toggleMsg(point)"
                        *ngIf="point.hadAttachments && point.hadAttachments.bool"
                        >
                        <mat-icon [matBadge]="point.hadAttachments.value" matBadgeSize="small" >attach_file</mat-icon>
                        </button>
                        <!--Assignments-->
                        <button
                        mat-icon-button
                        aria-label="Utilisateur"
                        (click)="toggleMsg(point)"
                        *ngIf="countAssignments(point.messages)"
                        [attr.aria-label]="'Il y a ' + countAssignments(point.messages) + ' utilisateurs assignés'"
                        >
                        <mat-icon [matBadge]="countAssignments(point.messages)" matBadgeSize="small">person</mat-icon>
                        </button>
                        <!--Updates-->
                        <button
                        mat-icon-button
                        [attr.aria-label]="'Il y a ' + countUpdateHistory(point.messages) + ' modifications'"
                        (click)="toggleMsg(point)"
                        *ngIf="countUpdateHistory(point.messages)"
                        [attr.aria-label]="'Il y a ' + countUpdateHistory(point.messages) + ' utilisateurs assignés'"
                        >
                        <mat-icon [matBadge]="countUpdateHistory(point.messages)" matBadgeSize="small">edit_note</mat-icon>
                        </button>
                        <!--Réduire / développer-->
                        <button
                        mat-icon-button
                        [attr.aria-label]="point.showMsg? 'Développer' + getDemandStatusLabel(point.current_state?.code) : 'Réduire' + getDemandStatusLabel(point.current_state?.code)"
                        (click)="toggleMsg(point)"
                        class="hide-mobile"
                        [ngClass]="{ rotate: point.showMsg }">
                        <mat-icon class="arrow">keyboard_arrow_down</mat-icon>
                        </button>
                    </div>
                    {{ getDemandStatusLabel(point.current_state?.code) }}
                </div>
                <div class="created-at">
                    Le {{ point.created_at | date: 'dd-MM-yyyy' }} à
                    {{ point.created_at | date: 'HH:mm' }}
                </div>
                <div class="user-name" *ngIf="(client_name !== 'sabom' || userMode == 'exploitant') ">{{ fullName(point) }}</div>
                <!--popup Historique-->
                <div class="messages"  *ngIf="messagesToShow(point.groupMessages)" [@openClose]="showPointMessages(point) ? 'open' : 'closed'">
                <div class="message" *ngFor="let group of point.groupMessages">
                    <div class="body" [ngClass]="{ right: historyMessageRightSide(group) }">
                    <div class="created-at" *ngIf="!group.isAssignmentMessage">Le {{
                        group.date | date:
                        'dd-MM-yyyy' }} à {{ group.date | date:
                        'HH:mm' }}</div>
                    <div *ngIf="!group.isCommentMessage && !group.isAssignmentMessage"
                        class="msg-bubble break-words" [ngClass]="{
                        'msg-bubble--assignment': group.isAssignmentMessage,
                        'msg-bubble--internal-comment': group.type === 'COM',
                        'msg-bubble--history-comment': group.isCommentMessage
                        }">
                        <div *ngFor="let message of group.messages">
                        <span>{{ message.message || 'Message vide' }}</span>
                        <app-attachments *ngIf="message.type !== 'PA' && !message.isAssignmentMessage"
                            [demandId]="demand.id" [attachments]="
                        message.attachments?.length
                            ? message.attachments
                            : point.attachments
                        " [inBubble]="true"></app-attachments>
                        <app-attachments *ngIf="message.type === 'PA'" [demandId]="demand.id"
                            [attachments]="message.attachments" [inBubble]="true"></app-attachments>
                        <br>
                        </div>
                    </div>
                    <div class="msg-bubble break-words" *ngIf="!group.isAssignmentMessage" [ngClass]="{
                        'msg-bubble--assignment': group.isAssignmentMessage && group.comment != null,
                        'msg-bubble--internal-comment': group.type === 'COM' && group.comment != null,
                        'msg-bubble--comment': group.comment == null
                        }">
                        {{ group.comment }}
                    </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>