import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { Demand } from '@app/shared/models/demand';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { IMessage } from 'src/app/shared/models/message';
import * as _ from 'lodash';
import {
  demandMatchState, getLastPointAvancement, getLastTransitionMessage,
} from '@app/shared/helpers/demand-helper';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import { Router } from '@angular/router';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { UserType, User } from '@app/shared/models/user';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { environment } from '@env/environment';
import { MatDialog } from '@angular/material/dialog';
import { TextInputDialogComponent } from '@app/components/modals/text-input-dialog-modal/text-input-dialog.component';


@Component({
  selector: 'app-exploitant-demand-aside',
  templateUrl: './exploitant-demand-aside.component.html',
  styleUrls: ['./exploitant-demand-aside.component.scss'],
})
export class ExploitantDemandAsideComponent implements OnInit {
  @Input() demand: Demand;
  @Output() reloadHistory: EventEmitter<any> = new EventEmitter<any>();
  toggle_feature_internal_comment_history: Boolean = environment.toggle_feature_internal_comment_history;
  toggle_feature_add_internal_comment_history: Boolean = environment.toggle_feature_add_internal_comment_history;
  toggle_feature_moveo_oi: Boolean = environment.toggle_feature_moveo_oi;
  public mode: any = null;
  public isDemandFinalState = false;
  public isDemandFinalStateExploitant = false;
  public hasMessage = false;
  displayMessage: IMessage;
  public displayQuote = true;
  user: User;
  isUserAuthorizedForReopenDemand: boolean = false;
  isReopenRequested = false;
  isRequestSent = false;

  paGotAttachment: boolean = false;
  dialog: any;

  constructor(
    private router: Router,
    private demandService: DemandService,
    private authService: AuthenticationService,
    public Dialog: MatDialog
  ) {
    this.demandService.getDemandUpdated().subscribe((demand) => {
      this.demand = demand;
      this.ngOnInit();
    });

    this.authService.getUser().then((user: User) => {
      this.user = user;
    });
  }
  checkIfUserIsAuthorizedForReopenDemand() {
    const userEmail = this.authService.getCurrentUserEmail();
    this.isUserAuthorizedForReopenDemand = environment.authorizedEmailsForReopenDemand.includes(userEmail);
  }

  ngOnInit() {
    this.checkIfUserIsAuthorizedForReopenDemand();
    for (let y = 0; y < this.demand.point_avancement.length; y++) {
      if (this.demand.point_avancement[y].attachments.length > 0) {
        this.paGotAttachment = true;
      }
    }
    this.demand.point_avancement.reverse();
    this.mode = getUserMode();
    this.isDemandFinalState = false;
    if (
      this.demand.workflow_current_state.code === DemandStateCode.ABANDONNEE ||
      this.demand.workflow_current_state.code === DemandStateCode.CLOTUREE
    ) {
      this.isDemandFinalState = true;
    }
    this.isDemandFinalStateExploitant = this.checkFinalStateExploitant();
    this.getDisplayMessage();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  getDisplayMessage() {
    // rule : pick the most recent one among
    // point avancement and transition message.
    this.displayMessage = undefined;
    // We get the last PA message from last transition
    const lastPointAvancement = getLastPointAvancement(this.demand);
    // We get the last transition message from DEMANDEUR (here is EXPLOITANT view so we display messages from DEMANDEUR)
    const lastTransition = getLastTransitionMessage(this.demand, UserType.DEMANDEUR);

    if (lastTransition && lastPointAvancement) {
      if (Date.parse(lastTransition.created_at) > Date.parse(lastPointAvancement.created_at)) {
        this.displayMessage = this.formatMessage(lastTransition, true)
      } else if (lastPointAvancement.mode === UserType.DEMANDEUR.toUpperCase()) {
        // here is EXPLOITANT view so we display messages from DEMANDEUR
        this.displayMessage = this.formatMessage(lastPointAvancement)
      }
    } else if (lastTransition) {
      this.displayMessage = this.formatMessage(lastTransition, true)
    } else if (lastPointAvancement && lastPointAvancement.mode === UserType.DEMANDEUR.toUpperCase()) {
      // here is EXPLOITANT view so we display messages from DEMANDEUR
      this.displayMessage = this.formatMessage(lastPointAvancement)
    }
  }

  formatMessage(message: any, transition: boolean = false) {
    return {
      user: transition ? message.user : message.created_by,
      message: transition ? message.message : 'Point d\'avancement demandé',
      created_at: message.created_at,
    }
  }

  checkFinalStateExploitant() {
    return demandMatchState(this.demand, [
      DemandStateCode.EN_ATTENTE_CLOTURE,
      DemandStateCode.EN_ATTENTE_ABANDON,
    ]);
  }

  canEditDemand(): boolean {
    return !demandMatchState(this.demand, [
      DemandStateCode.REJETEE,
      DemandStateCode.EN_COURS,
      DemandStateCode.SUSPENDUE,
      DemandStateCode.A_COMPLETER,
      DemandStateCode.CLOTUREE,
      DemandStateCode.ABANDONNEE,
      DemandStateCode.EN_ATTENTE_CLOTURE,
      DemandStateCode.EN_ATTENTE_ABANDON,
      DemandStateCode.AFFECTEE,
    ]);
  }

  editDemand(): void {
    this.demandService.changeEditMode(true);
    this.router.navigate(['demands/edit', this.demand.id], {
      queryParams: {
        redirectUrl: `exploitant/demands/${this.demand.id}`,
      },
    });
  }

  canAddInternalComment():boolean {
    if(!environment.toggle_feature_add_internal_comment_history) return false;
    return demandMatchState(this.demand, [
      DemandStateCode.AFFECTEE,
      DemandStateCode.EN_COURS,
      DemandStateCode.SUSPENDUE,
      DemandStateCode.REJETEE
    ]);
  }

  hasPermission() {
    return this.user && this.user.super_user === true ? this.demand.created_by.id === this.user.id : true;
  }

  allDownload() {
    this.demandService.allAttachmentDownload(this.demand);
  }
  reopenDemand(result): void {
    this.demandService.reopenDemand(this.demand.id, result).subscribe({
      next: (response) => {
      },
      error: (error) => {
      
        console.error('Erreur lors de la réouverture de la demande', error);
      }
      
    });
    this.isReopenRequested = true;
  }
  
  reloadPage(){
    window.location.reload();
  }
  openDialog(): void {
    const dialogRef = this.Dialog.open(TextInputDialogComponent, {
      width: '250px',
    });
  
    dialogRef.afterClosed().subscribe(result => {    
      this.reopenDemand(result);
      this.reloadPage();
    });
  }
 
}

