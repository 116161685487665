<main class="main-view">
  <app-lineo-breadcrumb></app-lineo-breadcrumb>
  <div id="skiplink-indicator" aria-label="Indicateurs">
    <button mat-raised-button class="uppercased indicator-btn mat-primary" (click)="goToIndicators()"
        [class.new-demand-is-displayed]="toggle_feature_new_demand_exploit" 
        *ngIf="!toggle_feature_right_side_home && toggle_feature_indicators">
      <mat-icon>bar_chart</mat-icon>
      <div>Indicateurs</div>
    </button>
    <button mat-fab class="indicator-btn-mobile mat-primary" (click)="goToIndicators()" 
        *ngIf="!toggle_feature_right_side_home && toggle_feature_indicators">
      <mat-icon>bar_chart</mat-icon>
    </button>
  </div>
  <div>
    <button mat-raised-button class="uppercased new-demand-btn mat-primary" (click)="goDemands()"
        *ngIf="toggle_feature_new_demand_exploit">
      <mat-icon>add</mat-icon>
      <div>nouvelle demande</div>
    </button>
    <button mat-fab class="new-demand-btn-mobile mat-primary" (click)="goDemands()"
        *ngIf="toggle_feature_new_demand_exploit">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div id="skiplink-demand-list" aria-label="Toutes les demandes">
    <app-demands-table-new role="exploitant"></app-demands-table-new>
  </div>
</main>
