import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { ConfirmationModalComponent } from '@app/components/modals/confirmation-modal/confirmation-modal.component';
import { FilterComponent } from '@app/shared/components/filter/filter.component';
import { FilterConstant } from '@app/shared/components/filter/filter.constant';
import { convertDelai, demandMatchState, getDemandStatusLabel, getStatusLabel } from '@app/shared/helpers/demand-helper';
import { FilterService } from '@app/shared/helpers/filter.service';
import Toast from '@app/shared/helpers/toast';
import { getUserMode, userIsDemandeur, userIsExploitant } from '@app/shared/helpers/user-modes-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { User, UserType } from '@app/shared/models/user';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { environment } from '@env/environment';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import * as moment from "moment";
import { debounceTime, Subscription } from 'rxjs';
import Tether from 'tether';
import { GoogleMapsComponent } from '../google-maps/google-maps.component';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-demands-table-new',
  templateUrl: './demands-table-new.component.html',
  styleUrls: ['./demands-table-new.component.scss']
})
export class DemandsTableNewComponent implements OnInit {
  @Input() role: string;
  encapsulation: ViewEncapsulation.None;
  toggle_feature_all_demand_exploitant = environment.toggle_feature_all_demand_exploitant;
  toggle_feature_delete_demand_exploitant = environment.toggle_feature_delete_demand_exploitant;
  toggle_feature_to_send_filter = environment.toggle_feature_to_send_filter;
  constructor(
    public dialog: MatDialog,
    private filterService: FilterService,
    private datePipe: DatePipe,
    private demandsService: DemandService,
    private filterConstant: FilterConstant,
    private breakPoint: BreakpointObserver,
    private router: Router,
    private authenticationService: AuthenticationService,
    private ref: ChangeDetectorRef,
  ) {
    this.listFilterTextType = filterConstant.TEXT_TYPE;
    /*setInterval(() => {
      this.ref.detectChanges();
    }, 100);*/

  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private resultsShownCacheKey = 'demandsTableResultsShown';
  private pageIndexCacheKey = 'demandsTablePageIndex';

  dataSource: MatTableDataSource<any>;

  listFilterTextType;
  toggle_feature_full_export: Boolean = environment.toggle_feature_full_export;
  toggle_feature_display_urgence_in_red: Boolean = environment.toggle_feature_display_urgence_in_red;
  toggle_feature_map_on_demands: Boolean = environment.toggle_feature_map_on_demands;
  toogle_feature_search_full_text: Boolean = environment.toogle_feature_search_full_text;
  toogle_feature_search_full_text_bd: Boolean = environment.toogle_feature_search_full_text_bd;
  toggle_feature_duplicate_for_exploit: Boolean = environment.toggle_feature_duplicate_for_exploit;
  toggle_feature_id_color: Boolean = environment.toggle_feature_id_color;
  client_name: String = environment.client_name;
  getDemandStatusLabel = getDemandStatusLabel;
  getStatusLabel = getStatusLabel;
  DemandStateCode = DemandStateCode;

  columns = [];
  displayedColumns: String[] = [];
  data = [];
  datas= [];
  globalFilterText;
  isColumnsFiltered:boolean=false;

  searchQuery = new FormControl();
  isSearchFull = false;
  isSearchFullBD = false;
  resultSearchFull: any[] = [];

  tetherFilterVisible = false;

  currentSort;

  isSmallScreen: boolean;

  isLoading = false;
  pendingExport = false;

  // list des demandes filteres de angular
  listFiltereFront: any[] = [];

  quickFilters = {
    A_AFFECTER: false,
    TOUTES: false,
    AFFECTEE: false,
    A_COMPLETER: false,
    EN_COURS: false,
    SUSPENDUE: false,
    REJETEE: false,
    ALL: true,
    A_ENVOYER: false
  };

  columnsByRole = {
    demandeur: environment.demandeur_columns,
    exploitant: environment.exploitant_columns,
  };

  // LABELS
  activity_label = environment.activity_label;
  contract_label = environment.contract_label;

  searchFullTooltip = "Recherche sur l'intégralité des champs d'une demande et ses pièces jointes. Recherche mot à mot et insensible à la casse (majuscules, caractères spéciaux).";
  searchFullTooltipBD = "Recherche sur l'intégralité des champs d'une demande, y compris si elle est archivée. Recherche mot à mot non sensible à la casse (majuscules, caractères spéciaux) ou Recherche exacte en encadrant l'expression par \" \".";

  // demand delete
  isDeleteLoading = false;
  isParametreur = false;
  private user: User;
  sub: Subscription;
  client_is_setom = environment.client_name === 'setom' ? true : false;
  column_label = this.client_is_setom ? 'Date réelle de réalisation' : 'Date réelle de clôture';

  ngOnInit() {
    this.columns = [
      {
        label: 'N°',
        key: 'id',
        active: true,
        activable: false,
        sticky: true,
        sortable: true,
        filter: 'number'
      },
      {
        label: 'Demandeur',
        key: 'created_by',
        active: true,
        activable: true,
        sortable: true,
        filter: 'select'
      },
      {
        label: this.activity_label,
        key: 'concerned_activity',
        active: true,
        activable: true,
        sortable: true,
        filter: 'select'
      },
      {
        label: this.contract_label,
        key: 'contract',
        active: true,
        activable: true,
        sortable: true,
        filter: 'select'
      },
      {
        label: 'Objet de la demande',
        key: 'demand_object',
        active: true,
        activable: true,
        sortable: true,
        filter: 'select',
      },
      {
        label: 'Impact de la nuisance observée',
        key: 'observed_impact',
        active: true,
        activable: true,
        sortable: true,
        filter: 'select',
      },
      {
        label: 'Adresse',
        key: 'location_full_address',
        active: true,
        activable: true,
        sortable: true
      },
      {
        label: 'Commune',
        key: 'location_locality',
        active: true,
        activable: true,
        sortable: true,
        filter: 'select',
      },
      {
        label: 'Date de réception',
        key: 'reception_date',
        active: true,
        activable: true,
        sortable: true,
        filter: 'date',
        type: 'date',
      },
      {
        label: 'Infos',
        key: 'infos',
        sortable: true,
        active: true,
        activable: true,
        filter: 'select',
      },
      {
        label: 'Date d\'envoi',
        key: 'send_date',
        active: true,
        sortable: true,
        activable: true,
        filter: 'date',
        type: 'date',
      },
      {
        label: 'Echéance souhaitée',
        key: 'realisation_datetime',
        active: true,
        sortable: true,
        activable: true,
        filter: 'date',
        type: 'date',
      },
      {
        label: 'Statut',
        key: 'workflow_current_state',
        active: true,
        activable: true,
        sortable: true,
        filter: 'select'
      },
      {
        label: 'Demande prise en charge par',
        key: 'in_charge',
        active: true,
        sortable: true,
        activable: true,
        filter: 'select'
      },
      {
        label: 'Référence externe',
        key: 'external_reference_number',
        active: true,
        sortable: true,
        activable: true,
        filter: 'select'
      },
      {
        label: 'Intervenant(s)',
        key: 'intervenant',
        active: true,
        sortable: true,
        activable: true,
        filter: 'select'
      },
      {
        label: 'Canal de contact',
        key: 'communication_channel',
        active: true,
        sortable: true,
        activable: true,
        filter: 'select'
      },
      {
        label: 'Délai contractuel (minutes)',
        key: 'delais',
        active: true,
        sortable: true,
        activable: true,
        filter: 'number'
      },
      {
        label: 'Type de délai',
        key: 'delais_type',
        active: true,
        sortable: true,
        activable: true,
      },
      {
        label: 'Délai contractuel',
        key: 'delais_converted',
        active: true,
        sortable: true,
        activable: true,
      },
      {
        label: 'Référence interne',
        key: 'internal_reference_number',
        active: true,
        sortable: true,
        activable: true,
      },
      {
        label: 'Réclamation client',
        key: 'customer_complaint',
        active: false,
        sortable: false,
        activable: false,
      },
      {
        label: 'Date réelle de réception',
        key: 'receive_date',
        active: false,
        sortable: false,
        activable: false,
        filter: 'date',
        type: 'date',
      },
      {
        label: this.column_label,
        key: 'close_date',
        active: false,
        sortable: false,
        activable: false,
        filter: 'date',
        type: 'date',
      },
      {
        label: 'Date passage statut',
        key: 'last_transition_created_at',
        active: true,
        sortable: true,
        activable: true,
        filter: 'date',
        type: 'date',
      },
      {
        label: 'Action',
        key: 'action',
        sortable: true,
        active: true,
        activable: false,
        stickyEnd: true,
        filter: 'none'
      }
    ];

    this.createDisplayedColumns();

    this.isLoading = true;
    this.demandsService.getNewAllDemands().then((demands) => {
      this.data = this.formatDemands(demands)
      this.createDataSource(this.data);
      this.loadTableConfiguration();

      this.authenticationService.getUser().then((_user) => {
        this.user = _user as User;
        this.isParametreur = this.user.roles.find((role) => role.id == 'PARAMETREUR') !== undefined ? true : false;
      });
      this.returnLengthFilter('A_ENVOYER');

      this.isLoading = false;
      this.setIsColumnsFiltered();
    });

    this.searchQuery.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe((val) => {
      this.globalFilterText = val;
      if (this.isSearchFull) {
        this.dataSource.filter = undefined;
        this.doFullSearch();
      }
      if (this.isSearchFullBD) {
        this.createDataSource(this.data);
        this.applyGlobalFilter();
        this.onToggleSearchBD();
      }
      if (!this.isSearchFull && !this.isSearchFullBD) {
        this.paginator.firstPage();
        this.applyGlobalFilter();
      }
      this.saveTableConfiguration();
      setTimeout(()=>{
        this.ref.detectChanges();
      });
    });

    const maxWidth = '(max-width: 991px)';
    const minWidth = '(min-width: 992px)';
    this.isSmallScreen = this.breakPoint.isMatched(maxWidth);
    this.breakPoint.observe([maxWidth, minWidth])
      .subscribe((result) => {
        this.isSmallScreen = result.breakpoints[maxWidth];
      });
  }

  isHighImpact(data): boolean {
    const demand = !this.isSearchFullBD ? this.data.find((demand) => demand.id === data.id) : this.datas.find((demand) => demand.id === data.id);
    return demand ? demand.observed_impact == "Élevé" : false;
  }

  formatDemands(demands) {
    return demands.map((demand: any) => {
      return {
        id: demand.id,
        communication_channel: demand.communication_channel ? demand.communication_channel : undefined,
        created_by: demand.created_by ? demand.created_by : undefined,
        concerned_activity: demand.concerned_activity ? demand.concerned_activity : '',
        contract: demand.contract && demand.contract_label ? demand.contract + ' - ' + demand.contract_label : '',
        demand_object: demand.demand_object ? demand.demand_object : '',
        observed_impact: demand.observed_impact ? demand.observed_impact : '',
        location_full_address: demand.location_full_address ? demand.location_full_address : undefined,
        location_locality: demand.location_locality ? demand.location_locality : undefined,
        reception_date: demand.transitions === null ? undefined : moment(demand.transitions),
        infos: demand.infos ? demand.infos : undefined,
        send_date: demand.transitions === null ? undefined : moment(demand.transitions),
        realisation_datetime: demand.realisation_datetime === null ? undefined : moment(demand.realisation_datetime),
        workflow_current_state: getDemandStatusLabel(demand.workflow_current_state_code),
        in_charge: demand.assignations === null ? undefined : demand.assignations,
        external_reference_number: demand.external_reference_number == null ? undefined : demand.external_reference_number,
        intervenant: demand.intervenant ? demand.intervenant : undefined,
        intervenants: demand.ints ? demand.ints : undefined,
        workflow_current_state_code: demand.workflow_current_state_code ? demand.workflow_current_state_code : undefined,
        is_assigned_to_me: demand.is_assigned_to_me ? demand.is_assigned_to_me : undefined,
        location_latitude: demand.location_latitude === null ? 0.0 : demand.location_latitude,
        location_longitude: demand.location_longitude === null ? 0.0 : demand.location_longitude,
        delais: demand.delais ? demand.delais : undefined,
        delais_type: demand.delais !== 0 ? (demand.delais_type !== 'OUV' ? 'Calendaire' : 'Ouvré') : '',
        delais_converted: demand.delais ? convertDelai(demand.delais, demand.delais_type) : '',
        internal_reference_number: demand.internal_reference_number ? demand.internal_reference_number : '',
        customer_complaint: demand.customer_complaint == true ? "Oui" : "Non",
        last_transition_created_at: demand.last_transition_created_at === null ? undefined : moment(demand.last_transition_created_at),
        receive_date: demand.receive_date ? demand.receive_date : '',
        close_date: demand.close_date ? demand.close_date : ''
      }
    });
  }

  createDataSource(data) {
    this.dataSource = new MatTableDataSource(data);
    this.paginator.firstPage();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.globalFilterFunction;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'location_full_address': return item.location_full_address.toUpperCase();
        case 'infos': return item.infos ? String.removeAccents(item.infos).toUpperCase() : item.infos;
        default: return item[property];
      }
    };

    if (!this.isSearchFull) {
      if (this.globalFilterText === '') {
        this.dataSource.filter = undefined;
      } else {
        this.dataSource.filter = this.globalFilterText;
      }
    }

    // For correctely sort the delais
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'delais_converted') {
        return item.delais;
      } else {
        return item[property];
      }
    };

  }

  formatDate(value) {
    if (value) { return moment(value).format('DD-MM-YYYY'); }
    return '';
  }

  getChipValue(column) {
    let text = column.searchData;
    // if specific format exist for this colum
    switch (column.isFiltered && column.filter) {
      case 'date':
        const dateStart = this.formatDate(column.searchData.start);
        const dateEnd = this.formatDate(column.searchData.end);

        // option filter
        if (column.filterType === 'isBetween') {
          if (!column.searchData.start) {
            text = "jusqu'au " + dateEnd;
          } else if (!column.searchData.end) {
            text = 'à partir du ' + dateStart;
          } else {
            text = 'du ' + dateStart + ' au ' + dateEnd;
          }
        } else if (column.filterType === 'isDown') {
          text = "jusqu'au " + dateEnd;
        } else if (column.filterType === 'isUp') {
          text = 'à partir du ' + dateStart;
        } else if (column.filterType === 'isEqual') {
          text = 'égale à ' + dateStart;
        } else {
          text = this.getCommonChipValue(column);
        }
        break;

      case 'select':
        if (column.searchData && column.searchData.length > 0) {
          let txt = '';
          column.searchData.forEach(v => { txt += v.label + ', '; });
          text = txt.length > 100 ? txt.substring(0, 100).concat('...') : txt.substring(0, txt.length - 2);
        }
        break;

      case 'number':
        text = this.getCommonChipValue(column);
        break;

      default:
        // option filter
        text = this.getCommonChipValue(column);
        break;
    }
    return text;
  }

  getCommonChipValue(column) {
    let text = column.searchData;
    switch (column.filterType) {
      case 'isBlank':
        text = 'est vide';
        break;

      case 'isNotBlank':
        text = "n'est pas vide";
        break;

      //****************** NUMBER ******************//
      case 'isEqual':
        text = "égal à '" + column.searchData.start + "'";
        break;

      case 'isNotEqual':
        text = "différent de '" + column.searchData.start + "'";
        break;

      case 'isUp':
        text = "supérieur à '" + column.searchData.start + "'";
        break;

      case 'isUpOrEqual':
        text = "supérieur ou égal à '" + column.searchData.start + "'";
        break;

      case 'isDown':
        text = "inférieur à '" + column.searchData.end + "'";
        break;

      case 'isDownOrEqual':
        text = "inférieur ou égal à '" + column.searchData.end + "'";
        break;

      case 'isBetween':
        text = "entre '" + column.searchData.start + "' et '" + column.searchData.end + "'";
        break;

      default:
        let ftt = this.listFilterTextType.find((ftt) => ftt.key === column.filterType);
        if (ftt) {
          text = ftt.label + " '" + column.searchData + "'";
        } else {
          text = "'" + column.searchData + "'";
        }
    }

    return text;
  }

  applyGlobalFilter() {
    if (this.dataSource) {
      if (this.globalFilterText === '') {
        this.dataSource.filter = undefined;
      } else {
        this.dataSource.filter = this.globalFilterText;
      }
    }
  }

  globalFilterFunction = (data: any, filterString: string): boolean => {
    let listWords = filterString.split(' ');
    let result = true;
    for (let i = 0; i < listWords.length; i++) {
      if (listWords[i] !== '' && result) {
        let found = false;

        this.displayedColumns.forEach((colKey) => {
          let column = this.getColumnByKey(colKey);
          if (column && column.active) {
            if (data[column.key] && data[column.key].toString()) {
              let filterNormalize = String.removeAccents(listWords[i]).toLowerCase();
              let valueNormalize = (column.key === 'realisation_datetime' || column.key === 'reception_date' || column.key === 'send_date' || column.key === 'last_transition_created_at') ?
                data[column.key].format('DD/MM/YY HH:mm') : String.removeAccents(data[column.key].toString()).toLowerCase();

              if (valueNormalize.includes(filterNormalize)) {
                found = true;
              }
            }
          }
        });

        result = result && found;
      }
    }
    return result;
  }

  getColumnByKey(key) {
    return this.columns.find((column) => column.key === key);
  }

  onPageChange(event: PageEvent) {
    sessionStorage.setItem(this.pageIndexCacheKey, String(event.pageIndex));
    localStorage.setItem(this.resultsShownCacheKey, event.pageSize.toString());
  }

  getCachedPageIndex() {
    return sessionStorage.getItem(this.pageIndexCacheKey) || 0;
  }

  resultsShown() {
    return localStorage.getItem(this.resultsShownCacheKey) || 10;
  }

  isUrgentDemand(demand): boolean {
    // check if realisation date time is not
    // in the the next five coming days.
    // if no datetime given, not affected.
    if (!demand.realisation_datetime) {
      return false;
    }
    // check 5 Days to go
    const dateNow = moment(new Date());
    const demandRealisationDateTime = moment(demand.realisation_datetime);
    const gapDurationDays = moment.duration(demandRealisationDateTime.diff(dateNow)).asDays();
    return (gapDurationDays <= 5 && !demandMatchState(
      demand, [
      DemandStateCode.EN_ATTENTE_CLOTURE, DemandStateCode.EN_ATTENTE_ABANDON,
      DemandStateCode.CLOTUREE, DemandStateCode.ABANDONNEE])
    );
  }

  filterListByStatusKey(list, key) {
    return list.filter((d) => {
      switch (key) {
        case 'TOUTES':
          return d.is_assigned_to_me === true;
        case 'AFFECTEE':
          return d.workflow_current_state_code === 'DEMANDE_AFFECTEE' && d.is_assigned_to_me === true;
        case 'A_COMPLETER':
          return d.workflow_current_state_code === 'DEMANDE_A_COMPLETER' && d.is_assigned_to_me === true;
        case 'EN_COURS':
          return d.workflow_current_state_code === 'DEMANDE_EN_COURS' && d.is_assigned_to_me === true;
        case 'SUSPENDUE':
          return d.workflow_current_state_code === 'DEMANDE_SUSPENDUE' && d.is_assigned_to_me === true;
        case 'REJETEE':
          return d.workflow_current_state_code === 'DEMANDE_REJETEE' && d.is_assigned_to_me === true;
        case 'A_AFFECTER':
          return d.workflow_current_state_code === 'DEMANDE_ENVOYEE';
        case 'ALL':
          return list;
        case 'A_ENVOYER':
          return d.workflow_current_state_code === 'DEMANDE_A_ENVOYER';
      }
    });
  }

  // ################# Actions ################# //
  openFilter(event, column, isShiftedLeft = true) {
    let columnData = { ...column };

    // Create list of select value if it is not a fixed list
    if (columnData.filter === 'select' && (!columnData.format || !columnData.format.data)) {
      let listSelectItem = this.dataSource.data.map((d) => {
        let isBlank = d[columnData.key] == undefined || d[columnData.key] == null || d[columnData.key] == '';

        return {
          value: isBlank ? 'isBlank' : d[columnData.key],
          label: isBlank ? '(Vide)' : d[columnData.key]
        }
      });

      let seen = {};
      listSelectItem = listSelectItem.filter(function (item) {
        return seen.hasOwnProperty(item.value) ? false : (seen[item.value] = true);
      })
        .sort(function (a, b) {
          return a.label.localeCompare(b.label);
        });

      columnData.format = {
        type: 'select',
        data: listSelectItem
      }
    }

    let templateRef = {
      data: columnData,
      autoFocus: false,
      backdropClass: "backdrop-filter",
      ariaLabel: "Filtre " + columnData?.label,
      ariaModal:true,
    }

    if (window.innerWidth > 991) {
      let panelWidth = columnData.filter === 'date' ? 580 : 410;
      let left = isShiftedLeft ? event.x - event.target.offsetParent.clientWidth : event.x;
      if (left + panelWidth >= event.view.innerWidth) {
        left = event.view.innerWidth - panelWidth - 15;
      } else if (left < 0) {
        left = 10;
      }

      // If the filter panel for select is too close to the bottom, up it
      let top = event.y + 20;
      if (columnData.filter === 'select') {
        if (top + 400 > event.view.innerHeight) {
          top = event.view.innerHeight - 400;
        }
      }
      templateRef['position'] = {
        top: top + 'px',
        left: left + 'px'
      }
    }

    const dialogRef = this.dialog.open(FilterComponent, templateRef);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        column.searchData = result.searchData;
        column.filterType = result.filterType;
        column.isFiltered = result.isFiltered;
        if (this.isSearchFullBD) {
          this.createDataSource(this.data);
          this.applyGlobalFilter();
          this.onToggleSearchBD();
          this.setIsColumnsFiltered();
        } else {
          this.filterData();
          this.saveTableConfiguration();
          this.setIsColumnsFiltered();
        }
      }
    });
  }

  filterData(dataAFilter = undefined) {
    let result = [];
    if (dataAFilter != undefined) {
      result = dataAFilter;
    } else {
      result = this.data;
    }


    // Filter on state
    for (const _key of Object.keys(this.quickFilters)) {
      if (this.quickFilters[_key] === true) {
        result = this.filterListByStatusKey(result, _key);
      }
    }

    // Filter on column
    this.displayedColumns.forEach((displayedColumn) => {
      let column = this.columns.find((column) => column.key === displayedColumn);
      if (column && column.isFiltered) {
        if (column.filter === 'date') {
          result = this.filterService.filterDate(result, column);
        } else if (column.filter === 'select') {
          result = this.filterService.filterSelect(result, column);
        } else if (column.filter === 'number') {
          result = this.filterService.filterNumber(result, column);
        } else {
          result = this.filterService.filterText(result, column);
        }
      }
    });

    // Filter full text
    if (this.isSearchFull) {
      result = result.filter((demand) => {
        return this.resultSearchFull.some((r) => r.demand_id === demand.id)
      });

      result = result.map((demand) => {
        const resultSearchFull = this.resultSearchFull.find((r) => r.demand_id === demand.id);
        return {
          ...demand,
          resultSearchFull: resultSearchFull && resultSearchFull.foundFields !== '' ? resultSearchFull.foundFields.join(', ') : undefined
        };
      });
    }

    this.createDataSource(result);
  }

  removeFilter(column) {
    column.searchData = undefined;
    column.filterType = undefined;
    column.isFiltered = false;
    if (this.isSearchFullBD) {
      this.createDataSource(this.data);
      this.applyGlobalFilter();
      this.onToggleSearchBD();
      this.setIsColumnsFiltered();
    } else {
      this.filterData();
      this.saveTableConfiguration();
      this.setIsColumnsFiltered();
    }
  }

  resetFilters() {
    this.columns.forEach((column) => {
      column.searchData = undefined;
      column.filterType = undefined;
      column.isFiltered = false;
    });

    this.globalFilterText = undefined;
    this.resultSearchFull = [];
    this.isSearchFull = false;
    this.isSearchFullBD = false;
    this.searchQuery.setValue(undefined, { emitEvent: false });

    for (const _key of Object.keys(this.quickFilters)) {
      this.quickFilters[_key] = false;
    }
    this.quickFilters.ALL = true;

    this.filterData();

    // Reset sort
    this.dataSource.sort.sort(<MatSortable>({ id: undefined, start: 'asc' }));
  }

  onSort(event, applyToTable = false) {
    if (applyToTable) {
      this.dataSource.sort.sort(<MatSortable>({ id: event.active, start: event.direction }));

      // Hack to display the arrow
      const activeSortHeader = this.dataSource.sort.sortables.get(event.active);
      activeSortHeader['_setAnimationTransitionState']({
        fromState: event.direction,
        toState: 'active',
      });
    }

    // Reset last sort
    this.currentSort = undefined;

    if (event.direction !== "") {
      let column = this.getColumnByKey(event.active);

      if (column) {
        this.currentSort = {
          key: column.key,
          kind: "ordering",
          name: column.label,
          type: event.direction.toUpperCase(),
        }
      }
    }
    this.saveTableConfiguration();
  }

  createDisplayedColumns() {
    this.displayedColumns = this.columnsByRole[this.role].filter((colKey) => {
      let foundColumn = this.getColumnByKey(colKey);
      return foundColumn && foundColumn.active;
    });
  }

  openFilterColumnClick() {
    this.tetherFilterVisible = !this.tetherFilterVisible
  }

  toggleFilterColumnClick(column, event) {
    event.preventDefault();
    column.active = !column.active;
    this.createDisplayedColumns();
    this.saveTableConfiguration();
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (this.tetherFilterVisible === true) {
      const targetElement = event.target as HTMLElement;
      if (!targetElement.closest('[click-bypass]')) {
        this.tetherFilterVisible = false;
      }
    }
  }

  saveTableConfiguration() {
    let filters = this.columns.filter((column) => column.isFiltered).map((column) => {
      return {
        "name": column.label,
        "key": column.key,
        "kind": "filter",
        "filterType": column.filterType,
        "searchData": column.searchData
      }
    });

    if (this.globalFilterText && this.globalFilterText.length > 0) {
      let globalFilter: any = {
        "name": "Recherche",
        "key": this.globalFilterText,
        "type": "FULLTEXT",
        "kind": "search"
      };
      filters = filters.concat(globalFilter);
    }

    if (this.currentSort) {
      filters = filters.concat(this.currentSort);
    }

    let tableFilters = {
      "filters": filters,
      "displayedColumns": this.displayedColumns,
      quickFilters: this.quickFilters
    };
    localStorage.setItem(`table-filters-${getUserMode()}`, JSON.stringify(tableFilters));
  }

  loadTableConfiguration() {
    const saved = localStorage.getItem(`table-filters-${getUserMode()}`);

    if (saved !== undefined && saved !== null) {
      let tableFilters = JSON.parse(saved);

      this.columns.forEach((column) => {
        // Columns display
        if (tableFilters.displayedColumns.indexOf(column.key) === -1 && column.activable === true) {
          column.active = false;
        }

        // Columns filter
        if (tableFilters.filters) {
          let foundFilter = tableFilters.filters.find((filter) => filter.kind === "filter" && filter.key === column.key);
          if (foundFilter) {
            column.filterType = foundFilter.filterType;
            column.searchData = foundFilter.searchData;
            column.isFiltered = true;
          }
        }
      });

      this.createDisplayedColumns();

      // Global filter
      if (tableFilters.filters) {
        let globalFilter = tableFilters.filters.find((filter) => filter.kind === "search");
        if (globalFilter && globalFilter.key !== undefined && globalFilter.key !== null && globalFilter.key.length > 0) {
          this.searchQuery.setValue(globalFilter.key, { emitEvent: false });
          this.globalFilterText = globalFilter.key;
        }
      }

      // Quick filter
      if (tableFilters.quickFilters) {
        this.quickFilters = tableFilters.quickFilters;
      }

      this.filterData();

      // Sort
      if (tableFilters.filters) {
        let columnSort = tableFilters.filters.find((filter) => filter.kind === "ordering");
        if (columnSort) {
          this.onSort({ active: columnSort.key, direction: columnSort.type.toLowerCase() }, true);
        }
      }
    }
  }

  goToDemandPage(demand) {
    const tableDemands = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
    this.demandsService.setTableDemands(tableDemands);

    if (demand.workflow_current_state_code === DemandStateCode.A_ENVOYER) {
      this.router.navigate(['demands/edit', demand.id], {
        queryParams: {
          beforeSendMode: true,
        },
      });
    } else {
      const demandURI = {
        demandeur: '/demands/',
        exploitant: '/exploitant/demands/',
      }[this.role];
      this.router.navigate([`${demandURI}${demand.id}`]);
    }
  }

  deleteDemand(demand) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      disableClose: false,
      autoFocus: false,
      width: '60%',
      data: {
        title: 'Souhaitez-vous vraiment supprimer cette demande ?',
        description: 'Tous les contenus liés ne seront plus consultables.',
        labelRefuse: 'Annuler',
        labelConfirm: 'Je supprime',
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.demandsService.deleteInMemoryDemand(UserType.EXPLOITANT, demand);
        this.demandsService.deleteInMemoryDemand(UserType.DEMANDEUR, demand);
        this.sub = this.demandsService.deleteDemand(demand).subscribe(() => {
          Toast.info("Votre demande n° " + demand.id + " a bien été supprimée.", "Demande supprimée");
          demand.isDeleteLoading = false;
          this.ngOnInit();
        }, (err) => {
          Toast.error("Erreur lors de la suppression de la demande n° " + demand.id + ".");
          demand.isDeleteLoading = false;
          this.ngOnInit();
        });
      } else {
        demand.isDeleteLoading = false;
        this.ngOnInit();
      }
    });
  }

  exportCSVBackend() {
    this.pendingExport = true;
    const date_str = moment().format('YYYYMMDD_HHmmss');
    const filterDataId = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort).map(e => e.id);
    this.demandsService.getExportCSV(filterDataId).then((data: any) => {
      this.pendingExport = false;
      saveAs(new Blob([data], { type: 'application/zip' }),
        `export_${environment.app_name_short}_${getUserMode()}_${date_str}.csv`);
      this.pendingExport = false;
    }
    ).catch((err) => {
      Toast.error("Erreur lors de l'export.")
    });
  }

  exportCSV() {

    const datas = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
    let exportColumns = _.clone(this.displayedColumns);
    if(this.client_name !== 'setom') {
      exportColumns.push('customer_complaint');
    }
    if (this.client_name !== 'sabom') {
      exportColumns.push('receive_date');
      exportColumns.push('close_date');
    }
    // Title
    let content = "";
    exportColumns.forEach((colKey) => {
      if (colKey !== "action") {
        if (content !== "") {
          content += ";";
        }
        let column = this.getColumnByKey(colKey);
        content += column.label;
      }
    });

    // Data
    datas.forEach((data) => {
      let line = "";
      exportColumns.forEach((colKey) => {
        if (colKey !== "action") {
          if (line !== "") {
            line += ";";
          }
          let column = this.getColumnByKey(colKey);
          let value = '';
          if (data[column.key]) {
            if (column.type === 'date' &&
              column.key != 'receive_date' &&
              column.key != 'close_date') {
              value = data[column.key].format('DD/MM/YY HH:mm');
            } else {
              if(column.key == "intervenant") {
                if(data["intervenants"] != null || data["intervenants"] != undefined) {
                  value = '"' + data["intervenants"] + '"';
                } else {
                  value = data[column.key];
                }
              } else {
                value = data[column.key];
              }
            }
          }
          line += value;
        }
      });

      content += "\n" + line;
    });

    const date_str = moment().format('YYYYMMDD_HHmmss');
    saveAs(
      new Blob(["\ufeff", content], { type: 'text/csv' }),
      `export_${environment.app_name_short}_${getUserMode()}_${date_str}.csv`,
    );
  }

  exportFullCSV() {
    this.pendingExport = true;
    const date_str = moment().format('YYYYMMDD_HHmmss');

    const filterDataId = this.dataSource.filteredData.map(e => e.id);
    this.demandsService.getZipCSV(filterDataId).then((data: any) => {
      saveAs(new Blob([data], { type: 'application/zip' }),
        `export_${environment.app_name_short}_${getUserMode()}_${date_str}.zip`);
      this.pendingExport = false;
    }
    ).catch((err) => {
      Toast.error("Erreur lors de l'export.")
    });

    this.exportCSV();
  }

  quickFiltersChange(key: string) {
    for (const _key of Object.keys(this.quickFilters)) {
      this.quickFilters[_key] = _key === key;
    }
    if (this.isSearchFullBD) {
      this.createDataSource(this.data);
      this.applyGlobalFilter();
      this.onToggleSearchBD();
      this.setIsColumnsFiltered();

    } else {
      this.filterData();
      this.saveTableConfiguration();
      this.setIsColumnsFiltered();
    }

  }

  openMap(): void {
    const dialogRef = this.dialog.open(GoogleMapsComponent, {
      panelClass: 'styleDialog',
      width: '100%',
      maxHeight: '93vh',
      minHeight: '90vh',
      autoFocus: true,
      disableClose: false,
      data: {
        demands: this.dataSource.filteredData
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  userIsDemandeur() {
    return userIsDemandeur();
  }

  duplicateDemand(demand) {
    this.router.navigate(['demands/new'], { queryParams: { duplicate: demand.id } });
  }

  onToggleSearch() {
    if (this.isSearchFull) {
      this.dataSource.filter = undefined;
      this.doFullSearch();
    } else {
      this.resultSearchFull = [];
      this.filterData();
      this.applyGlobalFilter();
    }
  }

  // Recherche full text BDD
  onToggleSearchBD() {
    this.isLoading = true;

    // list des demandes filteres au front
    this.listFiltereFront = this.dataSource.filteredData;

    if (this.isSearchFullBD && this.globalFilterText != undefined && this.globalFilterText.trim() != '') {
      this.demandsService.getNewAllDemands(undefined, this.globalFilterText).then((demands) => {
        this.datas = this.formatDemands(demands)
        // suppression des duplicate et concate avec le list filtre au front
        const dataIds = this.datas.map(d => {
          return d['id'];
        });

        this.listFiltereFront.forEach(e => {
          if (!dataIds.includes(e['id'])) {
            this.datas.push(e);
          }
        });
        this.datas = this.datas.sort((a, b) => {
          return b.id - a.id;
        });

        // Filter on state
        this.filterData(this.datas);
        this.dataSource.filter = undefined;
        this.saveTableConfiguration();
        this.isLoading = false;

      });

    } else {
      if (this.globalFilterText == undefined || (this.globalFilterText != undefined && this.globalFilterText.trim() == '')) {
        this.filterData();
        this.isLoading = false;
      } else {
        this.createDataSource(this.data);
        this.loadTableConfiguration();
        this.isLoading = false;
      }
    }

  }

  doFullSearch() {
    this.isLoading = true;

    if (this.globalFilterText && this.globalFilterText != '') {
      let searchFields = [
        { code: "demand_id", label: "N° demande" },
        { code: "assignations", label: "Référent" },
        { code: "intervenant", label: "Intervenant" },
        { code: "concerned_activity", label: this.activity_label },
        { code: "contract", label: this.contract_label },
        { code: "communication_channel", label: "Canal de contact" },
        { code: "contract_label", label: this.contract_label },
        { code: "created_by", label: "Demandeur" },
        { code: "demand_object", label: "Objet de la demande" },
        { code: "infos", label: "Infos" },
        { code: "location_full_address", label: "Adresse" },
        { code: "location_locality", label: "Commune" },
        { code: "realisation_datetime", label: "Echéance souhaitée" },
        { code: "send_date_str", label: "Date d'envoi" },
        { code: "observed_impact", label: "Impact de la nuisance observée" },
        { code: "description", label: "Description" },
        { code: "attachment.content", label: "Pièce jointe" },
        { code: "attachment.author", label: "Auteur Pièce jointe" },
        { code: "file_name", label: "Titre pièce jointe" },
        { code: "actions_to_go", label: "Actions attendues" },
        { code: "beneficiary_email", label: "Email du bénéficiaire" },
        { code: "beneficiary_first_name", label: "Prénom du bénéficiaire" },
        { code: "beneficiary_last_name", label: "Nom du bénéficiaire" },
        { code: "beneficiary_phone", label: "Téléphone du bénéficiaire" },
        { code: "beneficiary_type", label: "Type de bénéficiaire" },
        { code: "external_reference_number", label: "N° Référence externe" },
        { code: "ouvrage_type", label: "Type d'ouvrage" },
        { code: "workflow_current_state", label: "Statut" },
        { code: "last_transition_created_at", label: "Date passage statut" },
      ];

      this.demandsService.fullSearch(this.globalFilterText, searchFields).then((result) => {
        this.isLoading = false;
        this.resultSearchFull = [];
        result.forEach((r) => {
          let foundFields = [];
          if (r.highlight) { foundFields = Object.keys(r.highlight); }

          // Check if a demand has already a result
          let rsf = this.resultSearchFull.find((rsf) => rsf.demand_id == r._source.demand_id);
          if (!rsf) {
            rsf = {
              demand_id: r._source.demand_id,
              foundFields: foundFields.map((ff) => {
                const found = searchFields.find((sf) => sf.code === ff);
                if (found) {
                  // For attachment, add the name
                  if (r._source.file_name) {
                    return found.label + ' : ' + r._source.file_name;
                  }
                  return found.label;
                }
                return '';
              }),
            };
            this.resultSearchFull.push(rsf);
          } else {
            rsf.foundFields = rsf.foundFields.concat(
              foundFields.map((ff) => {
                const found = searchFields.find((sf) => sf.code === ff);
                if (found) {
                  // For attachment, add the name
                  if (r._source.file_name) {
                    return found.label + ' : ' + r._source.file_name;
                  }
                  return found.label;
                }
                return '';
              })
            );
          }
        });
        this.filterData();
      })
        .catch((err) => {
          this.isLoading = false;
          Toast.info("Le serveur de recherche n'est pas disponible");
          this.isSearchFull = false;
          this.onToggleSearch();
        });
    } else {
      this.isLoading = false;
      this.resultSearchFull = this.data.map((d) => { return { demand_id: d.id, foundFields: '' } });
      this.filterData();
    }
  }

  showDuplicate(): Boolean {
    if (userIsDemandeur()) {
      return true;
    } else if (userIsExploitant() && this.toggle_feature_duplicate_for_exploit) {
      return true;
    } else if (userIsExploitant() && !this.toggle_feature_duplicate_for_exploit) {
      return false;
    }
  }

  public setIdColor(itemData): string {
    return this.toggle_feature_id_color && environment.status_colors[itemData.workflow_current_state_code]
      ? environment.status_colors[itemData.workflow_current_state_code]
      : 'inherit';
  }

  returnLengthFilter(filterName: string): number {
    switch (filterName) {
      case 'ALL':
        return this.dataSource !== undefined ? this.dataSource.filteredData.length : 0;
      case 'TOUTES':
        return this.dataSource !== undefined ? this.dataSource.filteredData.filter(x => x.is_assigned_to_me === true).length : 0;
      case 'A_AFFECTER':
        return this.dataSource !== undefined ? this.dataSource.filteredData.filter(x => x.workflow_current_state_code === 'DEMANDE_ENVOYEE').length : 0;
      case 'AFFECTEE':
        return this.dataSource !== undefined ? this.dataSource.filteredData.filter(x => x.workflow_current_state_code === 'DEMANDE_AFFECTEE' && x.is_assigned_to_me === true).length : 0;
      case 'A_COMPLETER':
        return this.dataSource !== undefined ? this.dataSource.filteredData.filter(x => x.workflow_current_state_code === 'DEMANDE_A_COMPLETER' && x.is_assigned_to_me === true).length : 0;
      case 'EN_COURS':
        return this.dataSource !== undefined ? this.dataSource.filteredData.filter(x => x.workflow_current_state_code === 'DEMANDE_EN_COURS' && x.is_assigned_to_me === true).length : 0;
      case 'SUSPENDUE':
        return this.dataSource !== undefined ? this.dataSource.filteredData.filter(x => x.workflow_current_state_code === 'DEMANDE_SUSPENDUE' && x.is_assigned_to_me === true).length : 0;
      case 'REJETEE':
        return this.dataSource !== undefined ? this.dataSource.filteredData.filter(x => x.workflow_current_state_code === 'DEMANDE_REJETEE' && x.is_assigned_to_me === true).length : 0;
      case 'A_ENVOYER':
        return this.dataSource !== undefined ? this.dataSource.filteredData.filter(x => x.workflow_current_state_code === 'DEMANDE_A_ENVOYER').length : 0;
    }
  }

  private setIsColumnsFiltered() {
    this.isColumnsFiltered = this.columns.find(col => col.isFiltered === true);
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
}
