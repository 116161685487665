<div style="padding: 16px 16px 16px 0;">
  <div style="margin-left: 16px;">
    <app-lineo-breadcrumb [childPageName]="'Préférences de notification'"></app-lineo-breadcrumb>
  </div>

  <div>
    <mat-card *ngIf="suspend" style="margin-bottom: 25px;margin-left: 18px;margin-right: 1px;">
      <div class="d-flex justify-content-between" style="color: #E09F25">
        <div class="d-flex flex-row" style="align-items: center;font-size: 14px;font-weight: 500;">
          <mat-icon style="margin-right: 16px;">info</mat-icon>
          La suspension des notifications est activée pour la période du {{suspend.dateDebut | date: 'dd/MM/yyyy'}} au {{suspend.dateFin | date: 'dd/MM/yyyy'}}, votre backup, si vous en avez désigné un, est averti
        </div>
        <div class="d-flex flex-row" style="align-items: center;">
          <button mat-button class="uppercased yellow-btn" (click)="stopSuspend()" style="margin-left: 24px">
            Arrêter
          </button>
          <button mat-button class="uppercased yellow-btn" (click)="suspendNotifications()" style="margin-left: 24px">
            Modifier
          </button>
        </div>
      </div>
    </mat-card>
    <mat-sidenav-container style="background-color: transparent;" [hasBackdrop]="false" id="skiplink--preference-notification-filter" aria-label="Filtres">
      <!-- FILTER -->
      <mat-sidenav id="sidenav" #sidenav [autoFocus]="true" [mode]="'side'" [opened]="isFilterPanelOpen && !isLoading" (closedStart)="isFilterPanelOpen = false"
        style="border-radius: 0 20px 0 20px; box-shadow: 0 2px 10px 0 rgba(0,0,0,0.24); height:fit-content;" *ngIf="isFilterEnabled">
        <div class="d-flex justify-content-between filter-panel-title-div">
          Filtres
          <button
            (click)="sidenav.toggle()"
            class="btn-sidenav-back"
            aria-label="Cacher filtre"
          >
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <div class="d-flex flex-row filter-panel-toggle-div">
          Tout le périmètre
          <mat-slide-toggle [(ngModel)]="isContractPerimeterToggle" (change)="toggleAllPerimetre()" style="margin: 0px 16px;" aria-label="Tout le périmètre"></mat-slide-toggle>
          {{ contract_label }}
        </div>
        <div *ngIf="isContractPerimeterToggle" class="filter-panel-toggle-div">
          <mat-checkbox aria-label="Contrats Configurés" [checked]="configuredChecked" (change)="filterListCtr($event.checked,0,false)">{{ checkboxLabel1 }}</mat-checkbox> <br/> <br/>
          <mat-checkbox aria-label="Contrats Non configurés" [checked]="notConfiguredChecked" (change)="filterListCtr($event.checked,1,false)">{{ checkboxLabel2 }}</mat-checkbox>
        </div>
        <div class="filter-div">
          <mat-form-field style="width: 100%;" *ngIf="toogle_feature_show_contract_upper_levels">
            <mat-label>Région(s) ({{selectedRegions.length}})</mat-label>
            <mat-select [(value)]="selectedRegions" multiple [disabled]="!isContractPerimeterToggle" (selectionChange)="onRegionsChange()">
              <mat-option *ngFor="let region of regions" [value]="region.code">{{region.code + ' - ' + region.label}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="width: 100%;" *ngIf="toogle_feature_show_contract_upper_levels">
            <mat-label>Territoire(s) ({{selectedTerritories.length}})</mat-label>
            <mat-select [(value)]="selectedTerritories" multiple [disabled]="!isContractPerimeterToggle" (selectionChange)="onTerritoriesChange()">
              <mat-option *ngFor="let territory of territories" [value]="territory.code">{{territory.code + ' - ' + territory.label}}</mat-option>
            </mat-select>
          </mat-form-field>

          <ng-select #contractSelect
              [virtualScroll]="true"
              [disabled]="!isContractPerimeterToggle" notFoundText="Aucun résultat trouvé"
              placeholder="{{contract_label}} *" clearable="true"
              [(ngModel)]="selectedContract" clearAllText="Vider"
              [searchFn]="onContractSearch"
              (change)="onContractsChange()" appendTo="body"
              [items]="contracts" bindValue="id">
            <ng-template ng-label-tmp let-contract="item" let-clear="clear">
              <div class="ng-value-label" [ngClass]="{'ended-contract': isContractExpired(contract) }">
                {{ contract.code }} - {{ contract.label }}
                <span *ngIf="isContractExpired(contract)">&nbsp;({{ contract.date_fin_exploitation | date: 'dd/MM/yyyy' }})</span>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-contract="item">
              <div [title]="getContractTooltip(contract)" [ngClass]="{'ended-contract': isContractExpired(contract) }">
                {{ contract.code }} - {{ contract.label }}
                <span *ngIf="isContractExpired(contract)">&nbsp;({{ contract.date_fin_exploitation | date: 'dd/MM/yyyy' }})</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <mat-divider></mat-divider>
        <div class="d-flex flex-column" style="padding: 24px">
          <button mat-button class="uppercased mat-primary" (click)="reinitFilter()" style="margin-bottom: 16px;border: 1px solid">
            Réinitialiser les filtres
          </button>
          <button mat-raised-button class="uppercased mat-primary"
            [disabled]="(!selectedContract && isContractPerimeterToggle) || applySelectedContract === selectedContract"
            (click)="applyFilter()">
            Appliquer
          </button>
        </div>
      </mat-sidenav>

      <!-- MAIN CONTENT -->
      <mat-sidenav-content id="skiplink-preference-notification" aria-label="Préférences de notification">
        <div class="d-flex justify-content-center align-items-center"
          style="height: calc(100vh - 64px - 19px - 16px - 36px);margin-top: -55px;"
          *ngIf="isLoading">
          <mat-spinner></mat-spinner>
        </div>
        <div class="d-flex flex-row" *ngIf="!isLoading">
          <button (click)="openFilter()" *ngIf="!isFilterPanelOpen && isFilterEnabled" class="filter-button">
            <mat-icon>filter_list</mat-icon>
          </button>

          <div style="margin-left: 30px;width: 100%;">
            <mat-card style="border-radius: 0 20px 0 20px;margin-bottom: 48px;">
              <div class="d-flex justify-content-end" style="margin-bottom: 20px;">
                <button mat-raised-button class="uppercased mat-warn" (click)="resetNotifications()" style="margin-left: 24px">
                  Remettre à zéro
                </button>
                <button mat-raised-button class="uppercased mat-primary"
                  [disabled]="selectedCanals.length === 0 || selectedDomains.length === 0 || !hasUnsavedChanges || this.applySelectedContract !== this.selectedContract || (!selectedContract && isContractPerimeterToggle)"
                  (click)="save()" style="margin-left: 24px">
                  <span
                    [matTooltip]="(selectedCanals.length === 0 || selectedDomains.length === 0 || !hasUnsavedChanges ||
                    this.applySelectedContract !== this.selectedContract || (!selectedContract &&
                    isContractPerimeterToggle)) ? 'Appliquer le filtre et effectuer des modifications pour
                    enregistrer' : ' '">Enregistrer</span>
                </button>
                <!-- TODO - A remettre plus tard
                <button mat-raised-button class="uppercased" color="primary" style="margin-left: 24px;"
                    (click)="openPropagation()" [disabled]="!isPropageActive || hasUnsavedChanges">
                  Propager
                </button>
                <button mat-raised-button class="uppercased" color="primary" (click)="suspendNotifications()" style="margin-left: 24px">
                  Suspendre
                </button>-->
              </div>

              <!-- CANAL & DOMAINE -->
              <div class="d-flex flex-row" style="margin-bottom: 24px;">
                <div style="width: 50%; margin-right: 30px;">
                  <h5 style="font-weight: bold">Canal</h5>
                  <mat-form-field style="width: 100%;">
                    <mat-label>Canal</mat-label>
                    <mat-select [(ngModel)]="selectedCanals" multiple (selectionChange)="onCanalsChange()" aria-describedby="selected_canals_error" required>
                      <mat-option *ngFor="let canal of canals" [value]="canal.code">{{canal.label}}</mat-option>
                    </mat-select>
                    <mat-error
                      id="selected_canals_error"
                      *ngIf="selectedCanals.length === 0">
                      Canal est obligatoire
                    </mat-error>
                  </mat-form-field>
                </div>
                <div style="width: 50%;">
                  <h5 style="font-weight: bold">Domaine</h5>
                  <mat-form-field style="width: 100%;">
                    <mat-label>Domaine</mat-label>
                    <mat-select [(ngModel)]="selectedDomains" multiple (selectionChange)="onDomainsChange()" aria-describedby="selected_domains_error" required>
                      <mat-option *ngFor="let domain of domains" [value]="domain.id">{{domain.label}}</mat-option>
                    </mat-select>
                    <mat-error
                      id="selected_domains_error"
                      *ngIf="selectedDomains.length === 0"
                    >
                      Domaine est obligatoire
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <!-- NOTIFICATIONS -->
              <div class="d-flex justify-content-between" style="align-items: center;margin-bottom: 28px;">
                <h5 style="font-weight: bold">Notifications automatiques</h5>
                <div class="d-flex flex-row" style="align-items: center;font-size: 14px">
                  Tout recevoir ?&nbsp;&nbsp;&nbsp;&nbsp;
                  Non
                  <mat-slide-toggle
                    aria-label="Notifications automatiques, Tout recevoir ?"
                    [(ngModel)]="isAllNotificationToggle"
                    (change)="toggleAllNotification()"
                    style="margin: 0px 16px;"
                  ></mat-slide-toggle> Oui
                </div>
              </div>

              <div style="margin-bottom: 24px;">
                <div *ngFor="let notification of notifications">
                  <div class="d-flex justify-content-between" style="align-items: center;padding: 12px 16px;">
                    <div class="d-flex justify-content-between" style="width: 100%;">
                      {{notification.title}}
                      <button
                        [matTooltip]="notification.description"
                        [attr.aria-label]="notification.title"
                        matTooltipPosition="left"
                        class="detail-info-btn"
                      >
                        <mat-icon >info</mat-icon>
                      </button>
                    </div>
                    <mat-slide-toggle
                      [aria-label]="notification.title"
                      style="margin-right: 21px;"
                      [(ngModel)]="notification.isToggle"
                      (change)="toggleNotification(notification)"
                    >
                    </mat-slide-toggle>
                  </div>
                  <mat-divider style="position: relative;"></mat-divider>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
