import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Demand } from '@app/shared/models/demand';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import { environment } from '@env/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-demand-pdf-btn',
  templateUrl: './demand-pdf-btn.component.html',
  styleUrls: ['./demand-pdf-btn.component.scss'],
})
export class DemandPdfBtnComponent implements OnInit {

  public demand: Demand;
  @Input() defaultStyle : boolean = true;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.demand = this.route.snapshot.data.demand;
  }

  @HostListener('window:beforeprint')
  beforeprint() {
    if(!window.location.href.includes("print")) {
      window.location.replace(window.location.href.replace("demands/", "demands/print/"))
      window.location.reload()
    }
  }

  print() {
    window.location.replace(window.location.href.replace("demands/", "demands/print/"))
    window.location.reload()
  }

  getTitle() {
    const dateNow = moment().format('YYYYMMDD_HHmmss');
    return `demande${this.demand.id}_${environment.app_name_short}_${getUserMode()}_${dateNow}.pdf`;
  }
}
