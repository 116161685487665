<div>
    <div class="infos-tooltip-title">
        <div class="tooltip-icon">
            <mat-icon
                matTooltip="∑ (Temps passé entre statuts 'Envoyée' et 'Clôturée' ou 'Abandonnée' - Temps passé statut 'A compléter' - Temps passé statut 'Suspendue') / ∑ des demandes dont le statut est passé à 'Envoyée' au mois m (calcul en heures ouvrées). Cas de régularisation non pris en compte."
                matTooltipPosition="after"
            >
                info
            </mat-icon>
        </div>
        <span class="headline">
            Délai moyen de résolution
        </span>
    </div>
    <app-demands-statistics-loader [isLoading]="isLoading"></app-demands-statistics-loader>
  <div *ngIf="!isLoading" class="chart-repartition" [chart]="chart"></div>
</div>
