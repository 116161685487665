import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IConfirmationModaleWording } from 'src/app/components/modals/confirmation-modal/confirmation-modal-wording.interface';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { DemandService } from 'src/app/shared/services/demand/demand.service';
import { Value } from '@app/shared/models/value';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Demand } from '@app/shared/models/demand';
import Toast from '@app/shared/helpers/toast';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { getUserHome, userIsDemandeur, userIsExploitant } from '@app/shared/helpers/user-modes-helper';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { User } from '@app/shared/models/user';
import { environment } from '@env/environment';
import { ContractsService, Zone } from '@app/shared/services/contracts.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-demands',
  templateUrl: './demands.component.html',
  styleUrls: ['./demands.component.scss'],
})
export class DemandsComponent implements OnInit {

  public beneficiaryTypes: Value[] = [];
  public emplacementTypes: Value[] = [];
  public activityTypes: Value[] = [];
  public impactTypes: Value[] = [];
  public ouvrageTypes: Value[] = [];
  public objectTypes: Value[] = [];
  public userContracts: Value[] = [];
  public saveConfirmationWording: IConfirmationModaleWording;
  public deleteConfirmationWording: IConfirmationModaleWording;
  public demand: Demand;
  public user: User;
  formEvents: Subject<void> = new Subject<void>();
  isLoading = true;
  contractZones: Zone[];
  previousDemand;
  nextDemand;
  toggle_feature_navigation_demands = environment.toggle_feature_navigation_demands;
  client_name = environment.client_name;

  isDuplicate: boolean = false;

  // LABELS
  client_to_display = environment.client_to_display;

  editionMode: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private demandService: DemandService,
    private contractService: ContractsService
  ) {
    this.saveConfirmationWording = {
      title: this.client_to_display + ' n\'a pas moyen de contacter le bénéficiaire',
      description:
        `Il est préférable que l’adresse mail ou le numéro de téléphone
        soient remplis pour faciliter le traitement de votre demande.</br>
        Veuillez cliquer sur ANNULER pour saisir ces données ou
        sur JE CONFIRME pour envoyer la demande.`,
      labelRefuse: 'Annuler',
      labelConfirm: 'Je confirme',
    };

    this.deleteConfirmationWording = {
      title: 'Souhaitez-vous vraiment supprimer cette demande en cours de création ?',
      description:
        `Toutes les informations saisies dans ce formulaire seront supprimées.
         Si vous souhaitez les conserver, appuyez sur le bouton ANNULER.`,
      labelRefuse: 'Annuler',
      labelConfirm: 'Je supprime',
    };

    this.isDuplicate = this.route.snapshot.queryParamMap.get('duplicate') !== null;
    this.getRefData();
  }

  ngOnInit() {
    this.demandService.currentEditMode.subscribe(editMode => this.editionMode = editMode);
    this.route.params.subscribe(params => {
      this.isLoading = true;

      this.user = this.route.snapshot.data.user;

      if (this.isDuplicate) {
        this.demand = this.duplicateDemand(this.route.snapshot.data.demand);
      } else {
        this.demand = this.route.snapshot.data.demand;
      }

      if (this.demand && !this.isDuplicate) {
        this.previousDemand = this.demandService.getPreviousDemand(this.demand.id);
        this.nextDemand = this.demandService.getNextDemand(this.demand.id);

        // Forbid user to edit an uneditable demand
        let canEdit = true;
        if (userIsDemandeur()) {
          canEdit = demandMatchState(this.demand, [
            DemandStateCode.A_ENVOYER,
            DemandStateCode.A_COMPLETER
          ]);
        } else {
          canEdit = !demandMatchState(this.demand, [
            DemandStateCode.REJETEE,
            DemandStateCode.EN_COURS,
            DemandStateCode.SUSPENDUE,
            DemandStateCode.A_COMPLETER,
            DemandStateCode.CLOTUREE,
            DemandStateCode.ABANDONNEE,
            DemandStateCode.EN_ATTENTE_CLOTURE,
            DemandStateCode.EN_ATTENTE_ABANDON,
            DemandStateCode.AFFECTEE,
          ]);
        }

        if (!canEdit) {
          this.router.navigate([getUserHome()]);
        }
      }

      this.isLoading = false;
    });
  }

  goHome() {
    this.router.navigate([getUserHome()]);
  }

  checkDemandForm(data: any) {
    if (data.isSent && !data.demand.beneficiary_email
      && !data.demand.beneficiary_phone && !userIsExploitant()) {
      this.openSaveModal(data);
    } else if (data.isSent && !data.demand.beneficiary_email
      && !data.demand.beneficiary_phone && userIsExploitant() && !this.editionMode) {
      this.openSaveModal(data);
    } else {
      this.saveDemand(data);
    }
  }

  openSaveModal(demandData) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '60%',
      data: this.saveConfirmationWording,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // If the user confirm the saving of the demand
        this.saveDemand(demandData);
      } else {
        this.formEvents.next();
      }
    });
  }

  openDeleteModal(demand: any) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '60%',
      data: this.deleteConfirmationWording,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // If the user confirm the deleting of the demand
        if (_.has(demand, 'id')) {
          if (demand.workflow_current_state.code === DemandStateCode.A_ENVOYER) {
            this.demandService.deleteDemand(demand).subscribe();
            this.demandService.deleteInMemoryDemand("demandeur", demand);
            this.demandService.deleteInMemoryDemand("exploitant", demand);
          } else {
            this.demandService.abortDemand(demand).subscribe();
          }
        }
        Toast.info(`Votre demande n° ${demand.id}  a bien été supprimée.`, 'Demande supprimée');
        this.goHome();
      }
    });
  }

  updateDemand(data) {
    this.demandService.update(data.demand as Demand, this.user).subscribe((updatedDemand) => {
      if (data.demand.attachments.length > 0) {
        this.demandService.createAttachments(updatedDemand, data.demand.attachments, 'demand', undefined, "true").subscribe(() => {
        }, () => {
          this.saveHandler(data, updatedDemand.id, updatedDemand);
        }, () => {
          this.saveHandler(data, updatedDemand.id, updatedDemand);
        });
      } else {
        this.saveHandler(data, updatedDemand.id, updatedDemand);
      }
    });
  }

  saveDemand(data) {
    if (_.has(data.demand, 'id') && data.demand.id) {
      this.updateDemand(data);
    } else {
      this.demandService.create(data.demand as Demand).subscribe(
        (createdDemand) => {
          if (data.demand.attachments.length > 0) {
            this.demandService.createAttachments(createdDemand, data.demand.attachments, 'demand').subscribe(() => {
              this.saveHandler(data, createdDemand.id);
            });
          } else {
            this.saveHandler(data, createdDemand.id);
          }
        }, (error) => {
          Toast.error(`Une erreur est survenue`, 'Demande non envoyée');
        }, () => {
          this.formEvents.next();
        });
    }
  }

  saveHandler(data: any, demandId?: number, updatedDemand: any = undefined) {
    this.formEvents.next();

    if (data.isSent) {
      if (_.has(data, 'options') && _.has(data.options, 'toast')) {
        Toast.info(data.options.toast.message, data.options.toast.title);
      } else {
        Toast.info(`Votre demande n° ${demandId} a bien été envoyée.`, 'Demande envoyée');
      }

      // if (userIsDemandeur()) {
      if (_.has(data.demand, 'id') && data.demand.id && !data.beforeSendMode) {
        if (updatedDemand
          && updatedDemand.next_states
          && updatedDemand.next_states.length > 0
          && updatedDemand.next_states[0].code === 'DEMANDE_AFFECTEE') {
          // update case
          this.demandService.updateTransition(demandId, 'DEMANDE_AFFECTEE').subscribe(
            () => this.router.navigate([data.options.redirect]),
          );
        } else {
          this.router.navigate([data.options.redirect]);
        }
      } else {
        // create case
        this.demandService.updateTransition(demandId, 'DEMANDE_ENVOYEE').subscribe((transition: any) => {
          this.demandService.transitionSendMail(demandId, transition.transition_id);
          this.goHome();
        });
      }
      // } else {
      //   this.router.navigate([data.options.redirect]);
      // }
    } else {
      Toast.info('Vous pouvez la retrouver à partir de la page d\'accueil.',
        `Demande n° ${demandId} enregistrée mais non envoyée`);
      this.goHome();
    }
  }

  duplicateDemand(toDuplicate: Demand) {
    const duplicated = new Demand();
    const propertiesToMap = [
      'beneficiary_first_name',
      'beneficiary_last_name',
      'beneficiary_email',
      'communication_channel',
      'beneficiary_phone',
      'beneficiary_type',

      'location_latitude',
      'location_longitude',
      'location_full_address',
      'location_emplacement',
      'location_locality',
      'location_postal_code',
      'location_route',
      'location_street_number',
      'location_street_type',

      'concerned_activity',
      'contract',
      'contract_label',
      'demand_object',
      'ouvrage_type',

      'actions_to_go',
    ];
    propertiesToMap.forEach((key) => {
      // duplicated
      duplicated[key] = toDuplicate[key];
    });

    // Check if is_creator_beneficiary
    duplicated.is_creator_beneficiary = this.user.first_name === toDuplicate.beneficiary_first_name
      && this.user.last_name === toDuplicate.beneficiary_last_name;

    return duplicated;
  }

  getRefData() {
    this.demandService.getBeneficiaryTypes().subscribe((values) => {
      this.beneficiaryTypes = values;
    });

    this.demandService.getEmplacementTypes().subscribe((values) => {
      this.emplacementTypes = values;
    });

    this.demandService.getActivityTypes().subscribe((values) => {
      this.activityTypes = values;
    });

    this.demandService.getImpactTypes().subscribe((values) => {
      this.impactTypes = values;
    });
    this.demandService.getOuvrageTypes().subscribe((values) => {
      this.ouvrageTypes = values;
    });

    this.demandService.getObjectTypes().subscribe((values) => {
      this.objectTypes = values;
    });

    this.contractService.getContractZones().subscribe(x => this.contractZones = x)

    this.authService.getUserContracts().subscribe((contracts: any[]) => {
      contracts = contracts.sort((a, b) => (a.position - b.position) == 0 ? a.code.localeCompare(b.code) : (a.position - b.position));
      this.authService.getUser().then((user: User) => {
        // For super user, limit the list to BORDEAUX METROPOLE
        if (user.super_user === true && this.client_name === 'sabom') {
          this.userContracts = contracts.filter((contrat) => contrat.code === '33-0');
        } else {
          this.userContracts = contracts;
        }
      });
    });
  }

  goToPreviousDemand() {
    if (this.previousDemand)
      this.demandService.openDemandDetail(this.previousDemand);
  }

  goToNextDemand() {
    if (this.nextDemand)
      this.demandService.openDemandDetail(this.nextDemand);
  }
}
