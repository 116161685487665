import { Component } from "@angular/core";
import { environment } from "@env/environment";
const { version: appVersion } = require("../../../../../package.json");

@Component({
  selector: "app-about-modal",
  templateUrl: "./about-modal.component.html",
  styleUrls: ["./about-modal.component.scss"],
})
export class AboutModalComponent {
  public fiche_memo_link = environment.fiche_memo_link;
  public title: string = "Bienvenue sur la nouvelle version de LINEO";
  public introduction: string =
    "LINEO - version L'Isle";
  public newStuff = {
    "PAGE DEMANDE": [
      "Bouton \"ENVOYER UN COMMENTAIRE INTERNE\" sur la page demande Exploitant aux statuts suivants : Affectée/En cours/Suspendue/Rejetée",
      "Intégration du commentaire interne à l’historique des demandes exploitant uniquement, sous forme de bulle blanche",
      "Commentaire interne non visible sur les PDF",
      "Décommission de l'actuel commentaire interne, réinjecté dans l'historique interne exploitant",
      "Notification de réception d'un nouveau commentaire interne (nouvelle préférence de notification s’intégrant dans les configurations déjà existantes des utilisateurs)"
    ]
  };
  public appVersion;
  constructor() {
    this.appVersion = appVersion;
  }

  public keepOrder() {
    /* Keyvalue Pipe sorts by key by default
     this allows us to keep the orignal order */
    return 0;
  }
}
