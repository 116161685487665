import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-text-input-dialog',
  templateUrl: './text-input-dialog.component.html',
  styleUrls: ['./text-input-dialog.component.scss']
})
export class TextInputDialogComponent {
  userInput: string = '';

  constructor(
    public dialogRef: MatDialogRef<TextInputDialogComponent>
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
