<div class="row-no-margin custom-breadcrumb d-print-none">
  <div class="col-sm-12">
    <div class="custom-breadcrumb__user-mode">Vue <span class="capitalized">{{userMode}}</span> -</div>
    <nav role="navigation" aria-label="vous êtes ici :">
      <ol class="custom-breadcrumb__home-link">
        <li *ngIf="!isHomeRoute()">
          <button
            role="link"
            tabindex="0"
            (click)="goHome()"
            class="clickable"
            routerLinkActive="router-link-active"
            [aria-current]="page === isHomeRoute()"
            class="a-style"
          >Accueil</button>
        </li>
        <li *ngIf="isHomeRoute()"><span aria-current="page">Accueil</span></li>
        <li *ngIf="childPageName"><span class="separ" aria-hidden="true">/</span> <span aria-current="page">{{ childPageName }}</span></li>
      </ol>
    </nav>
  </div>
</div>
