<header id="skiplink-header" aria-label="Barre de navigation principale" #lineoHeader class="hyper-vision-header d-print-none">
  <div class="left-side">
    <!--Mobile Menu-->
    <!-- <button mat-icon-button class="mobile-menu-icon">
      <mat-icon>menu</mat-icon>
    </button> -->
    <!--Desktop/Tablet Brand-->
    <div class="brand" *ngIf="client_name !== 'national'">
      <img src="assets/header_logo-{{client_name}}.png" alt="" />
    </div>
    <div class="brand-national" *ngIf="client_name === 'national'">
      <img src="assets/logos/interactions-w@3x.png" alt="Hubgrade intéractions" />
    </div>
    <!--Mobile Brand-->
    <div class="mobile-brand" *ngIf="client_name !== 'national'">
      <img src="assets/header_logo-{{client_name}}.png" alt="" />
    </div>
    <div class="mobile-brand-national" *ngIf="client_name === 'national'">
      <span>Hubgrade</span>
    </div>

    <div class="separator"></div>

    <div class="current-app mobile-current-app" *ngIf="client_name === 'national'">{{app_name_long}}</div>
    <div class="current-app" *ngIf="client_name !== 'national'">{{app_name_long}}</div>
  </div>
  <div class="right-side" *ngIf="user">
    <!--<button mat-icon-button class="apps-icon" (click)="popupToggle('apps')" click-bypass>
      <mat-icon>apps</mat-icon>
    </button>-->

    <!--
      Notifications
    -->
    <button
      id="notifications"
      mat-icon-button
      class="menu-icon"
      (click)="readNotification();popupToggle('notifications')"
      click-bypass
      *ngIf="toggle_feature_notification"
      [attr.aria-expanded]="isPopup('notifications')"
    >
      <span class="visually-hidden">Notifications</span>
      <mat-icon>notifications</mat-icon>
      <span
        class="notifications-number"
        *ngIf="numberOfUnreadNotification > 0"
      ><span class="visually-hidden">Nombre de Notifications non lues</span> {{numberOfUnreadNotification}}</span>
    </button>
    <div class="notifications-popup" [hidden]="!isPopup('notifications')"
      [class.withoutDeco]="client_name == 'national' && !isParam" 
      role="group" aria-label="Notifications"
      click-bypass>
      <div class="notifications-div">
        <div *ngFor="let notification of notifications; let i = index;" (click)="openDemand(notification)" (keypress)="checkKey($event)" 
          class="notification-div" [class.notif-auto]="notification.demand !== null"
          [class.notif-pushed]="notification.demand === null"
          role="link" tabindex="0">
          <div class="title">{{notification.title}}</div>
          <div class="date">{{notification.date | date: 'dd/MM/yyyy à HH:mm' }}</div>
          <div class="description">{{notification.text}}</div>
          <a *ngIf="notification.link != null" class="link" target="_blank"
            [href]="notification.link">{{notification.link_label}}</a>
          <mat-divider *ngIf="i < notifications.length - 1"></mat-divider>
        </div>
      </div>
      <button mat-button class="button-notif uppercased mat-primary" (click)="seeAllNotification()">
        Voir toutes les notifications
      </button>
    </div>

    <!--
      Avatar POPUP
    -->
    <button 
      id="avatar"
      mat-icon-button 
      class="user-avatar" 
      (click)="popupToggle('avatar')" 
      [attr.aria-expanded]="isPopup('avatar')"
      click-bypass>
      <span class="visually-hidden">Mon profile</span>
      <img onerror="this.src='/assets/logos/blank-profile-picture.png'" class="user-img" [src]="user.profile_picture"
        alt="" *ngIf="user.profile_picture !== null; else initialeBlock" />
      <ng-template #initialeBlock matTooltip="{{ user.first_name }} {{ user.last_name }}" matTooltipPosition="right">
        {{ user?.first_name ? user.first_name[0] : user.email[0].toUpperCase() }}{{ user.last_name ? user.last_name[0] :
        user.email[1].toUpperCase() }}
      </ng-template>
    </button>
    <div class="avatar-popup" [hidden]="!isPopup('avatar')" [class.with-notification]="toggle_feature_notification"
      [class.withoutDeco]="client_name == 'national' && !isParam" [ngClass]="{'short': isCollectivite()}" 
      role="group" aria-label="Mon profil"
      click-bypass>

      <div class="left-side-popup">
        <img onerror="this.src='/assets/logos/blank-profile-picture.png'" class="user-img" [src]="user.profile_picture"
          alt="" *ngIf="user.profile_picture !== null" />
        <img class="user-img" src="/assets/logos/blank-profile-picture.png"
          alt="" *ngIf="user.profile_picture === null" />
      </div>
      <div class="right-side-popup">
        <div class="full-name">{{ user.first_name }} {{ user.last_name }}</div>
        <div class="email">{{ user.email }}</div>
        <div class="type">Typologie : {{ user.profile_type === 'Interne' && client_name === 'national' ? "Interne veolia" : user.profile_type }}</div>
        <div class="type" *ngIf="client_name !== 'national' && user.super_user">Superviseur</div>
        <div class="role">Profil(s) : {{ rolesTostring() }}</div>
        <a *ngIf="client_name == 'national' && externalUser" href="{{urlHubgradePass}}" target="_blank">
          <img id="hubgradepass-icon" src="/assets/logos/HubgradePass.png" alt="Hubgrade Pass Icon">
          <span id="hubgradepass-text">Hubgrade Pass</span>
        </a>
      </div>

      <div class="bottom-side" *ngIf="!isCollectivite()">
        <mat-radio-group [(ngModel)]="mode" (change)="modeChanged($event)">
          <mat-radio-button [disableRipple]="true" value="demandeur">Demandeur</mat-radio-button>
          <mat-radio-button [disableRipple]="true" value="exploitant" class="exploitant">Exploitant
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <!--
      Apps POPUP
     -->
    <!--<div class="apps-popup" [hidden]="!isPopup('apps')" style="--triangle-right: 100px" click-bypass>
      <div class="heading">
        <a href="https://hv360.veolia.fr/Poste de travail" class="home" target="_blank">
          <mat-icon>home</mat-icon>
        </a>
        <div class="title">
          <span>Hypervision</span> 360
        </div>
        <div class="body">

          <a class="app" *ngFor="let app of hypervision_apps" href="{{app?.url}}" target="_blank">

            <img class="logo" src="{{app?.logo}}" />
            <div class="name">{{app?.label}}</div>
          </a>
        </div>
      </div>
    </div>-->

    <!--
      Menu POPUP
    -->
    <button id="menu" mat-icon-button class="menu-icon" (click)="popupToggle('menu')" click-bypass [attr.aria-expanded]="isPopup('menu')">
      <span class="visually-hidden">Ouvrir le menu</span>
      <mat-icon>more_vert</mat-icon>
    </button>
    <div class="menu-popup" [hidden]="!isPopup('menu')" [class.withoutDeco]="client_name == 'national' && !isParam"
      role="group" aria-label="Menu"
      click-bypass>
      <mat-list>
        <mat-nav-list>
          <a class="button-preferences-notif" mat-list-item [routerLink]="'/preferences-notification'"
            (click)="closePopup()" *ngIf="toggle_feature_notification_preference">
            <mat-icon matListIcon>notifications</mat-icon>
            <h3 matLine>Préférences de notification</h3>
          </a>
          <a mat-list-item href="{{fiche_memo_link}}" target="_blank" (click)="closePopup()">
            <mat-icon matListIcon>info</mat-icon>
            <h3 matLine>Fiche mémo</h3>
          </a>
          <a mat-list-item href="{{reporting_link}}" *ngIf="mode == 'exploitant' && client_name == 'national'" target="_blank" (click)="closePopup()">
            <mat-icon matListIcon>query_stats</mat-icon>
            <h3 matLine>Reporting</h3>
          </a>
          <a mat-list-item [href]="suggestion_url" *ngIf="suggestion_url !== ''" target="_blank" (click)="closePopup()">
            <mat-icon matListIcon>contact_mail</mat-icon>
            <h3 matLine>Demander création de compte/évolution</h3>
          </a>
          <a mat-list-item [href]="bug_url" *ngIf="bug_url !== ''" target="_blank" (click)="closePopup()">
            <mat-icon matListIcon>report_problem</mat-icon>
            <h3 matLine>Signaler un dysfonctionnement</h3>
          </a>
          <button mat-list-item *ngIf="toogle_feature_modal_news && client_name != 'ileo'" (click)="showAboutModal()">
            <mat-icon matListIcon>history</mat-icon>
            <h3 matLine>À propos de</h3>
          </button>
          <button mat-list-item (click)="rgpd()">
            <mat-icon matListIcon>lock</mat-icon>
            <h3 matLine>Protection des Données (RGPD)</h3>
          </button>
          <a mat-list-item [routerLink]="'/accessibilite'" (click)="closePopup()">
            <mat-icon matListIcon>accessibility</mat-icon>
            <h3 matLine>Accessibilité numérique</h3>
          </a>
        </mat-nav-list>
      </mat-list>
    </div>
    <!--
      Logout
    -->
    <button mat-icon-button class="menu-icon" (click)="logout()" click-bypass matTooltip="Déconnexion"
      *ngIf="client_name !== 'national' || (client_name === 'national' && isParam)">
      <span class="visually-hidden">Déconnexion</span>
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </div>
</header>
