import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SkipLinksService {
  private skipLinksSubject = new Subject<void>();

  public next(): void {
    this.skipLinksSubject.next();
  }

  public getObservable(): Observable<void> {
    return this.skipLinksSubject.asObservable();
  }
}
