<div 
  [ngClass]="{'star-rating-view' : !forIndicator, 'star-rating-view-indicator' : forIndicator}" 
  *ngIf="viewMode"
  [attr.aria-label]="'La note est de ' + (rating | decimalFormat) + ' étoiles'"
  tabindex="0"
  role="img"
>
  <ng-container *ngFor="let star of stars; let i = index">
    <mat-icon class="selected">
      {{ getStarIcon(i) }}
    </mat-icon>
  </ng-container>
</div>

<div class="star-rating" *ngIf="!viewMode" aria-label="Note de satisfaction">
  <ng-container *ngFor="let star of stars; let i = index">
    <div tabindex="0" class="div-icon" 
    [attr.aria-label]="'Étoile ' + (i + 1) + ' sur ' + stars.length" role="radio" 
    [attr.aria-checked]="rating == i + 1" (click)="saveRating(i + 1)"
    (keydown)="onKeydown($event)">
      <mat-icon
        [ngClass]="{'selected': rating >= i + 1}"
        (mouseover)="rate(i + 1)"
        (mouseleave)="rate(selectedRate)"
        style="color: #5f6368;">
        {{ getStarIcon(i) }}
      </mat-icon>
    </div>
  </ng-container>
</div>