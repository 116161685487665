<form [formGroup]="notificationForm">
  <div mat-dialog-title>
    <h5 style="font-weight: bold">Créer une nouvelle notification</h5>
    <div class="rgpd-block">
      <mat-icon class="rgpd-icon rgpd-icon-spacing">lock</mat-icon>
      <i class="rgpd-info rgpd-info-first-override">
        Évitez les informations nominatives et/ou personnelles
      </i>
    </div>
  </div>

  <div mat-dialog-content class="content">

    <div class="d-flex flex-column field">
      <mat-form-field class="form-field-div">
        <mat-label>Titre</mat-label>
        <input #title matInput formControlName="title" maxlength="50" aria-describedby="title_error">
        <mat-hint align="end">
          <span class="visually-hidden">Compteur de caractères</span>
          {{title.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 50
        </mat-hint>
        <mat-error id="title_error">Le titre est obligatoire</mat-error>
      </mat-form-field>

      <mat-form-field class="form-field-div">
        <mat-label>Description</mat-label>
        <textarea #description matInput formControlName="text" maxlength="300" class="description" aria-describedby="description_text_error">
        </textarea>
        <mat-hint align="end">
          <span class="visually-hidden">Compteur de caractères</span>
          {{description.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 300
        </mat-hint>
        <mat-error id="description_text_error">La description est obligatoire</mat-error>
      </mat-form-field>

      <mat-form-field class="form-field-div">
        <input #linkLabel matInput placeholder="Libellé du lien" formControlName="link_label" maxlength="200" aria-describedby="link_label_error">
        <mat-hint align="end">
          <span class="visually-hidden">Compteur de caractères</span>
          {{linkLabel.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 200
        </mat-hint>
        <mat-error id="link_label_error">Le lien doit avoir un libellé</mat-error>
      </mat-form-field>

      <mat-form-field class="form-field-div">
        <input #link matInput placeholder="Lien" formControlName="link" maxlength="2000" pattern="^http.+$" aria-describedby="link_notification_error">
        <mat-hint align="end">
          <span class="visually-hidden">Compteur de caractères</span>
          {{link.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 2000
        </mat-hint>
        <mat-error id="link_notification_error">Le lien n'est pas valide, celui-ci doit commencer par http ou https</mat-error>
      </mat-form-field>
    </div>

    <mat-divider [vertical]="true" style="margin: 10px 48px"></mat-divider>

    <div class="d-flex flex-column">
      <mat-form-field class="form-field-div">
        <mat-label>Profil(s)</mat-label>
        <mat-select [(value)]="selectedProfils" formControlName="profils" multiple (selectionChange)="onProfilsChange()" aria-describedby="profils_error">
          <mat-option *ngFor="let profil of profils" [value]="profil.code">{{profil.label}}</mat-option>
        </mat-select>
        <mat-error id="profils_error">Au moins un profil doit être sélectionné</mat-error>
      </mat-form-field>

      <div class="d-flex flex-row" style="margin-bottom: 16px;" *ngIf="toggle_feature_pushed_notification_mail">
        Envoyer aussi par mail aux profils Collectivité
        <mat-slide-toggle [(ngModel)]="sendMail" formControlName="send_mail" style="margin: 0px 16px;" [disabled]="isSendMailToggle" aria-label="Envoyer aussi par mail aux profils Collectivité"></mat-slide-toggle>
        <mat-icon [matTooltip]="collectivity_help" matTooltipPosition="left" style="margin-right: 15px;">
          info
        </mat-icon>
      </div>

      <div *ngIf="isPerimetreContractuelEnabled">
        <h6 style="font-weight: bold;margin-bottom: 31px;" class="form-field-div">Périmètre contractuel</h6>

        <div *ngIf="isPerimetreContractuelEnabled" class="d-flex flex-row" style="margin-bottom: 16px;">
          Tout le périmètre
          <mat-slide-toggle [(ngModel)]="isContratPerimeterToggle" [ngModelOptions]="{standalone: true}"
            (change)="toggleAllPerimetre();" style="margin: 0px 16px;" aria-label="Tout le périmètre"></mat-slide-toggle>
          {{contract_label}}(s)
        </div>

        <mat-form-field style="width: 100%;" *ngIf="toogle_feature_show_contract_upper_levels">
          <mat-label>Région(s) ({{selectedRegions.length}})</mat-label>
          <mat-select [(value)]="selectedRegions" multiple [disabled]="!isContratPerimeterToggle" (selectionChange)="onRegionsChange()">
            <mat-option *ngFor="let region of regions" [value]="region.code">{{region.code + ' - ' + region.label}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field style="width: 100%;" *ngIf="toogle_feature_show_contract_upper_levels">
          <mat-label>Territoire(s) ({{selectedTerritories.length}})</mat-label>
          <mat-select [(value)]="selectedTerritories" multiple [disabled]="!isContratPerimeterToggle" (selectionChange)="onTerritoriesChange()">
            <mat-option *ngFor="let territory of territories" [value]="territory.code">{{territory.code + ' - ' + territory.label}}</mat-option>
          </mat-select>
        </mat-form-field>

        <ng-select #select *ngIf="isPerimetreContractuelEnabled"
            [virtualScroll]="true" notFoundText="Aucun résultat trouvé" placeholder="{{contract_label}}(s) ({{notificationForm.get('contracts').value.length}}) *"
            formControlName="contracts" [multiple]="true" [items]="contracts" bindValue="id" appendTo="body"
            [closeOnSelect]="false" style="width: 100%;" [clearable]="false"
            [searchFn]="onContractSearch" [searchable]="false" dropdownPosition="bottom"
            class="ng-select-custom" (open)="onFieldSelectOpened('contract')" aria-describedby="contracts_error">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value">
              <span class="ng-value-label">{{items.length}} Elément(s) sélectionné(s)</span>
            </div>
          </ng-template>
          <ng-template ng-header-tmp>
            <div class="d-flex align-items-center">
              <mat-checkbox [(ngModel)]="allContractsSelected" (change)="selectAllContracts()" color="primary"
                  [(indeterminate)]="allContractsSelectedIndeterminate" style="margin-right: 8px;"
                  [ngModelOptions]="{standalone: true}">
              </mat-checkbox>
              <input style="width: 100%; line-height: 24px" type="text" (input)="select.filter($event.target.value)"/>
            </div>
          </ng-template>



          <ng-template ng-option-tmp let-contract="item" let-contract$="item$" let-index="index">

            <div class="pure-material-checkbox" title="{{getContractTooltip(contract)}}">
              <input id="contract-{{index}}" type="checkbox" [ngModel]="contract$.selected" [ngModelOptions]="{standalone: true}"/>
              <span [ngClass]="{'ended-contract': isContractExpired(contract) }">
                {{ contract.code }} - {{ contract.label }}
                <span *ngIf="isContractExpired(contract)">&nbsp;({{ contract.date_fin_exploitation | date: 'dd/MM/yyyy' }})</span>
              </span>
            </div>
          </ng-template>
        </ng-select>
        <mat-error id="contracts_error"
          *ngIf="isContratPerimeterToggle && notificationForm.get('contracts').touched
          && notificationForm.get('contracts').value.length === 0"
        >
          {{contract_label}} est obligatoire
        </mat-error>
      </div>
    </div>

  </div>

  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="uppercased mat-primary" style="margin-right: 16px;">
      Annuler
    </button>
    <button mat-button class="uppercased mat-primary" (click)="apply()">
      Ajouter
    </button>
  </div>
</form>
