<mat-accordion *ngIf="userMode === 'exploitant'">
  <mat-expansion-panel [expanded]="memoExpanded" (click)="saveExpanded(1)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class="title">Mémo exploitant</h3>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="memo-div">
      <div *ngIf="userMode === 'exploitant' && toggle_feature_internal_reference">
        <div class="row-no-margin no-child-padding d-flex align-items-center">
          <h4 class="title-h3 size-memo ref-title">
            N° Références internes :
          </h4>
          <button (click)="$event.stopPropagation()"  mat-icon-button aria-label="Informations supplémentaire" class="btn-info-memo clickable"
            matTooltip="Numéros d'abonné, d’intervention, de patrimoine, de devis, etc...">
            <mat-icon>info</mat-icon>
          </button>

          <mat-form-field class="d-print-none interne-ref" (click)="$event.stopPropagation()">
            <input matInput #int_ref [value]="demand.internal_reference_number" style="font-size: 14px;" maxlength="50"
              (blur)="updateDemandInternalReference()" [disabled]="!canChangeInternalFields()">
            <mat-hint align="end">
              <span class="visually-hidden">Compteur de caractères</span>
              {{int_ref.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 50
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    

      <div *ngIf="userMode === 'exploitant' && toggle_feature_customer_complaint">
        <div class="client-div">
          <h4 class="title-h3 size-memo" style="width: 235px">
            Réclamation client :
          </h4>
          <div class="client-child">
            <h6>
              S'agit-il d'une réclamation client ?
            </h6>

            <div style="padding-left: 10px;">
              <mat-label [ngClass]="getCustomerComplaintState() == true ? 'labelBeforeSlideDisable': 'labelBeforeSlide'">Non</mat-label>
              <mat-slide-toggle (click)="$event.stopPropagation()" [checked]="demand?.customer_complaint" formControlName="is_creator_beneficiary" (change)="debounceCustomerComplaintClick($event)"
                [disabled]="getCustomerComplaintState()" aria-label="S'agit-il d'une réclamation client ?">
                Oui
              </mat-slide-toggle>
            </div>
        </div>
        </div>
      </div>
      
    </div>
    <app-review-list *ngIf="demand?.workflow_current_state?.code === 'DEMANDE_CLOTUREE' && toggle_feature_review" [demand]="demand" (click)="$event.stopPropagation()"></app-review-list>
  </mat-expansion-panel>
</mat-accordion>
<mat-accordion *ngIf="client_name !== 'sabom'">
  <mat-expansion-panel [expanded]="reguExpanded" (click)="saveExpanded(2)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class="title">Régularisation demande</h3>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="regu-content">
      <div class="regu-field-block">
        <mat-form-field class="regu-field">
          <mat-label>{{labelDate1}}</mat-label>
          <button *ngIf="!this.receiveDateIsEmpty && userMode === 'exploitant'" matSuffix class="clickable icon-urba"
            aria-label="Vider l'horaire" (click)="resetDate(1); $event.stopPropagation()"
            [disabled]="userMode !== 'exploitant' || isClosedDemand">
            <mat-icon>clear</mat-icon>
          </button>
          <button matSuffix aria-label="calendrier" class="clickable icon-urba" [owlDateTimeTrigger]="dt1"
            [disabled]="userMode !== 'exploitant' || isClosedDemand">
            <mat-icon>calendar_today</mat-icon>
          </button>
          <input matInput #receiveDate [(value)]="demand.receive_date" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"
            aria-describedby="date de réception" (dateTimeInput)="updateReceiveDate()"
            [readonly]="userMode !== 'exploitant' || isClosedDemand"
            [disabled]="userMode !== 'exploitant' || isClosedDemand">
          <owl-date-time #dt1 [firstDayOfWeek]="1" [disabled]="userMode !== 'exploitant' || isClosedDemand"></owl-date-time>
        </mat-form-field>
        <p *ngIf="showReceiveDateErrMsg" color="#5A5A5A">{{receiveDateErrMsg}}</p>
      </div>
      <div class="regu-field-block">
        <mat-form-field class="regu-field">
          <mat-label>{{labelDate2}}</mat-label>
          <button *ngIf="!this.closeDateIsEmpty && userMode === 'exploitant'" matSuffix class="clickable icon-urba"
            aria-label="Vider l'horaire" (click)="resetDate(2); $event.stopPropagation()"
            [disabled]="userMode !== 'exploitant' || isClosedDemand || receiveDateIsEmpty">
            <mat-icon>clear</mat-icon>
          </button>
          <button matSuffix aria-label="calendrier" class="clickable icon-urba" [owlDateTimeTrigger]="dt2"
            [disabled]="userMode !== 'exploitant' || isClosedDemand || receiveDateIsEmpty">
            <mat-icon>calendar_today</mat-icon>
          </button>
          <input matInput #closeDate [(value)]="demand.close_date" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2"
            aria-describedby="date de cloture" (dateTimeInput)="updateCloseDate()"
            [readonly]="userMode !== 'exploitant' || isClosedDemand || receiveDateIsEmpty"
            [disabled]="userMode !== 'exploitant' || isClosedDemand || receiveDateIsEmpty">
          <owl-date-time #dt2 [firstDayOfWeek]="1" [disabled]="userMode !== 'exploitant' || isClosedDemand || receiveDateIsEmpty"></owl-date-time>
        </mat-form-field>
        <p *ngIf="showCloseDateErrMsg" color="#5A5A5A">{{closeDateErrMsg}}</p>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<div class="row default-padding">
  <div class="col-xs-12 col-md-12">
    <h3 class="title">Historique</h3>
    <div class="history">
      <div *ngFor="let point of history;">
        <div class="point">
          <div class="sub-title">
            <div class="bubble round" [ngClass]="[bubbleColor(point)]"></div>
            <div class="actions" *ngIf="point.messages?.length">
              <!--Regular messages-->
              <button mat-icon-button [attr.aria-label]="'Il y a ' + countComments(point.messages, point.groupMessages) + ' messages'" 
                (click)="toggleMsg(point)" *ngIf="countComments(point.messages, point.groupMessages)">
                <mat-icon [matBadge]="countComments(point.messages, point.groupMessages)" matBadgeSize="small" >sms</mat-icon>
              </button>
              <!--Attached files-->
              <button mat-icon-button [attr.aria-label]="'Il y a ' + (point.hadAttachments.value + genericCount(point.messages, 'attachment')) + ' Fichiers joints'"
                (click)="toggleMsg(point)" *ngIf="point.hadAttachments && point.hadAttachments.bool || genericCount(point.messages, 'attachment')">
                <mat-icon [matBadge]="point.hadAttachments.value + genericCount(point.messages, 'attachment')" matBadgeSize="small">attach_file</mat-icon>
              </button>
              <!--Assignments-->
              <button mat-icon-button aria-label="Utilisateur" (click)="toggleMsg(point)"
                *ngIf="countAssignments(point.messages)"
                [attr.aria-label]="'Il y a ' + countAssignments(point.messages) + ' utilisateurs assignés'">
                <mat-icon [matBadge]="countAssignments(point.messages)" matBadgeSize="small">person</mat-icon>
              </button>
              <!--Updates-->
              <button mat-icon-button
                [attr.aria-label]="'Il y a ' + genericCount(point.messages, 'updates') + ' modifications'"
                (click)="toggleMsg(point)" *ngIf="genericCount(point.messages, 'updates')">
                <mat-icon [matBadge]="genericCount(point.messages, 'updates')" matBadgeSize="small">edit_note</mat-icon>
              </button>
              <!--Réduire / développer-->
              <button mat-icon-button
                [attr.aria-label]="point.showMsg? 'Développer' + getDemandStatusLabel(point.current_state?.code) : 'Réduire' + getDemandStatusLabel(point.current_state?.code)"
                (click)="toggleMsg(point)" class="hide-mobile" [ngClass]="{ rotate: point.showMsg }">
                <mat-icon class="arrow">keyboard_arrow_down</mat-icon>
              </button>
            </div>
            {{ getDemandStatusLabel(point.current_state?.code) }}
          </div>
          <div class="created-at">
            Le {{ point.created_at | date: 'dd-MM-yyyy' }} à
            {{ point.created_at | date: 'HH:mm' }}
          </div>
          <div class="user-name" *ngIf="(client_name !== 'sabom' || userMode == 'exploitant') ">{{ fullName(point) }}
          </div>
          <!--popup Historique-->
          <div class="messages" *ngIf="messagesToShow(point.groupMessages)"
            [@openClose]="showPointMessages(point) ? 'open' : 'closed'">
            <div class="message" *ngFor="let group of point.groupMessages">
              <div class="body" [ngClass]="{ right: historyMessageRightSide(group) }">
                <div *ngIf="!group.isCommentMessage && (client_name !== 'sabom' || userMode == 'exploitant') "
                  class="user-email">
                  {{ group.user?.email.includes('lineo-app') || group.user?.email.includes('auto-app') ?
                  'Administrateur' : group.user?.email}}
                </div>
                <div *ngIf="group.isCommentMessage" class="user-email">{{ group.user }}</div>
                <div class="created-at">Le {{
                  group.date | date:
                  'dd-MM-yyyy' }} à {{ group.date | date:
                  'HH:mm' }}</div>
                <div class="msg-bubble break-words" [ngClass]="{
                    'msg-bubble--outlined': group.isOutlined
                  }">
                  <div *ngFor="let message of group.messages">
                    <span class="attachment-span" *ngIf="message.field === 'internal_comment_link'" style="margin-top: 33px; margin-bottom: -10px;">
                      <mat-icon class="attachment-file-icon">link</mat-icon>
                      <a class="attachment-size" [href]="encodeUri(getInternalCommentLinkInfo(message, 'url'))" target="_blank">
                        <span class="attachment-file-name">{{ getInternalCommentLinkInfo(message, 'filename') }}</span>
                      </a>
                    </span>
                    <span *ngIf="message.field  !== 'internal_comment_link'">{{ message.message || 'Message vide'}}</span>
                    <app-attachments *ngIf="message.type !== 'PA' && !message.isAssignmentMessage"
                      [demandId]="demand.id" [attachments]="
                    message.attachments?.length
                        ? message.attachments
                        : point.attachments
                    " [inBubble]="true"></app-attachments>
                    <app-attachments *ngIf="message.type === 'PA'" [demandId]="demand.id"
                      [attachments]="message.attachments" [inBubble]="true"></app-attachments>
                    <br>
                  </div>
                </div>
                <div class="msg-bubble break-words" *ngIf="group.type == 'assignments'" [ngClass]="{
                    'msg-bubble--outlined': group.isOutlined && group.comment != null,
                    'msg-bubble--comment': group.comment == null
                  }">
                  {{ group.comment }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>