<div id="error" xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">
  <div class="error">
    <div class="error-inner">
      <mat-card class="mixed-round-border ml-25">
        <mat-card-header>
          <h4 class="error-title" *ngIf="client === 'national'; else title">
            Vous n'avez pas accès au module {{ module_name }} <br> ou votre session a expiré
          </h4>
          <ng-template #title>
            <h4 class="error-title">
            Vous n'avez pas accès au module {{ module_name }}
          </h4>
          </ng-template>

        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 error-section">
              <span class="error-section-title">
                Utilisateur
              </span>
              <span class="error-section-content">
                {{ email }}
              </span>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 error-section">
              <span class="error-section-title">
                URL
              </span>
              <span class="error-section-content">
                {{ url }}
              </span>
            </div>
          </div>
          <div *ngIf="client === 'national'">
            <div class="row error-section">
              <span class="error-section-content">
                <hr>
                Veuillez contacter le chef de projet Hubgrade de votre région pour obtenir
                l'accès à ce module.
              </span>
            </div>
            <div>
              <hr>
              <div class="row error-section">
                <span class="error-section-content">
                  Si vous disposez de plusieurs comptes, essayez de vous connecter avec un
                  autre compte sinon veuillez réactiver tout simplement votre session.
                </span>
                <br>
              </div>
              <div *ngIf="authenticationTypeOk('google')" class="d-flex justify-content-center" style="margin:15px 0;">
                <button [disabled]="loading" mat-raised-button class="uppercased export-btn mat-primary"
                  (click)="googleSignIn()">Se reconnecter</button>
              </div>
            </div>
          </div>
          <div *ngIf="client === 'sabom'">
            <div class="row error-section">
              <span class="error-section-content">
                <hr>
                Veuillez contacter le chef de projet pour obtenir l'accès à ce module.
              </span>
            </div>
            <div>
              <hr>
              <div class="row error-section">
                <span class="error-section-content">
                  Si vous disposez de plusieurs comptes google, essayez de vous connecter avec un
                  autre compte.
                </span>
              </div>
              <div *ngIf="authenticationTypeOk('google')" class="d-flex justify-content-center" style="margin:15px 0;">
                <button [disabled]="loading" mat-raised-button class="uppercased export-btn mat-primary"
                  (click)="googleSignIn()">Se reconnecter</button>
              </div>
            </div>
          </div>
          <div *ngIf="showPublik">
            <hr>
            <div class="row error-section">
              <span class="error-section-content">
                Si vous disposez de plusieurs comptes publik, essayez de vous connecter avec un autre
                compte.
              </span>
            </div>
            <div *ngIf="authenticationTypeOk('publik')" class="d-flex justify-content-center" style="margin:15px 0;">
              <button [disabled]="loading" mat-raised-button class="uppercased export-btn mat-primary"
                (click)="publikSignIn()">Se reconnecter</button>
            </div>
            <div *ngIf="authenticationTypeOk('keycloak')" class="d-flex justify-content-center" style="margin:15px 0;">
              <button [disabled]="loading" mat-raised-button class="uppercased export-btn mat-primary"
                (click)="keycloakSignIn()">Se reconnecter</button>
            </div>
          </div>
          <a routerLink="/">
            <button class="h360-btn reconnect-btn uppercased mat-primary" mat-raised-button>
              se reconnecter
            </button>
          </a><br><br>
          <a href="{{url_logout}}?post_logout_redirect_uri={{origin}}&client_id={{client_id}}">
            <button class="h360-btn reconnect-btn uppercased mat-primary" mat-raised-button>
              changer de compte
            </button>
          </a>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
