import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { getDemandStateColor, getDemandStatusLabel, getStatusLabel } from '@app/shared/helpers/demand-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { Chart } from 'angular-highcharts';
import * as _ from 'lodash';
import * as moment from 'moment';
import { IndicatorDetailGraphComponent } from '../indicator-detail-graph/indicator-detail-graph.component';
import { IndicatorDetailComponent } from '../indicator-detail/indicator-detail.component';


interface IAverageReview {
  total:number;
  average:number;
}

@Component({
  selector: 'app-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss'],
})
export class IndicatorComponent implements OnInit {
  @Input() indicator;
  @Input() demands;
  @Input() isNotCard: boolean = false;
  @Input() contracts;

  isdialogDetailOpen: boolean = false;
  isdialogDetailFilterOpen: boolean = false;

  doughnutOptions = {
    responsive: true,
    maintainAspectRatio: true,
    cutoutPercentage: 75,
    tooltips: {
      enabled: false,
    },
    hover: {
      mode: null,
    },
  };

  doughtnutColors = [
    {
      backgroundColor: [
        '#FFFFFF',
        '#9E9E9E',
      ],
      borderWidth: 0,
    },
  ];

  isSmallScreen: boolean;
  panelClass: string = '';

  constructor(
    public dialog: MatDialog,
    private breakpoint: BreakpointObserver,
    private demandsService: DemandService
  ) { }

  listDemands = [];
  chart;
  graph;

  listRgaaStat = [];

  reviewIndicator: IAverageReview = null;
  getDemandStatusLabel = getDemandStatusLabel;

  ngOnInit() {
    const that = this

    if (this.indicator.id === 1) {
      this.listDemands = this.getDemandsByStatus(DemandStateCode.EN_ATTENTE_CLOTURE);
      this.indicator.value = this.listDemands.length;
    } else if (this.indicator.id === 2) {
      this.listDemands = this.getDemandsByStatus(DemandStateCode.ABANDONNEE);
      this.indicator.value = this.listDemands.length;
    } else if (this.indicator.id === 3) {
      this.listDemands = this.getDemandsByStatus(DemandStateCode.A_ENVOYER);
      this.indicator.value = this.listDemands.length;
    } else if (this.indicator.id === 4) {
      this.listDemands = this.getDemandsByStatus(DemandStateCode.SUSPENDUE);
      this.indicator.value = this.listDemands.length;
    } else if (this.indicator.id === 5) {
      const result = this.getDemandsByStatusIndicator(5);
      this.listRgaaStat = result;
      const resultData = this.getDemandsByStatusIndicatorFullData(5)
      this.listDemands = resultData.demands;
      setTimeout(() => {
        this.chart = new Chart({
          chart: {
            type: this.indicator.graphType,
            height: 180,
            events: {
              render: function () {
                that.removeDesc()
              }
            },
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            formatter(): string {
              return this.point.name + ': <b>' + this.point.y + '</b>';
            },
          },
          title: {
            text: _.sumBy(result, 'y') || 0,
            verticalAlign: 'middle',
            y: 5,
          },
          plotOptions: {
            pie: {
              shadow: false,
            },
          },
          series: [{
            data: result,
            size: '100%',
            borderWidth: 1,
            borderColor: '#000',
            innerSize: '85%',
            dataLabels: {
              enabled: false,
            },
            point: {
              events: {
                click: (event) => {
                  this.isdialogDetailFilterOpen = true
                  this.dialog.open(IndicatorDetailComponent, {
                    height: this.isSmallScreen ? '100vh' : 'auto',
                    maxHeight: this.isSmallScreen ? '100vh' : '90vh',
                    width: '95%',
                    data: { indicator: this.indicator, demands: this.getDemandsByStatusIndicatorData(5, event.point.name) },
                    autoFocus: false,
                    disableClose: false,
                    panelClass: this.panelClass
                  }).afterClosed().subscribe(result => {
                    this.isdialogDetailFilterOpen = false;
                  });
                },
              },
            },
          }],
        } as any);
      });
    } else if (this.indicator.id === 6) {
      this.listDemands = this.getResolutionTimeIndicator();
      this.listRgaaStat = this.listDemands;
      this.chart = new Chart({
        chart: {
          type: this.indicator.graphType,
          height: 180,
          events: {
            render: function () {
              that.removeDesc()
            }
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter(): string {
            return _.capitalize(this.point.name) + ': <b>' + (this.point.y === 0 ? '0' : this.point.y.toFixed(1)) + ' j</b>';
          },
        },
        title: {
          text: null,
        },
        yAxis: {
          title: null,
          labels: {
            formatter(): string {
              return this.value + 'j';
            },
          },
        },
        xAxis: {
          categories: this.listDemands.map((i) => _.capitalize(i['name'])),
        },
        plotOptions: {
          pie: {
            shadow: false,
          },
          line: {
            color: '#0062a9',
          },
          series: {
            line: {
              marker: {
                radius: 1,
              },
            },
          },
        },
        series: [{
          data: this.listDemands,
          showInLegend: false,
          size: '100%',
          dataLabels: {
            enabled: false,
          },
        }],
      } as any);
    } else if (this.indicator.id === 7) {
      this.listDemands = this.getDemandsByStatus(DemandStateCode.ENVOYEE);
      const result = this.getSentDemandsByMonthIndicator();
      this.listRgaaStat = result;

      this.chart = new Chart({
        chart: {
          type: this.indicator.graphType,
          height: 180,
          events: {
            render: function () {
              that.removeDesc()
            }
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter(): string {
            return this.point.name + ': <b>' + this.point.y + '</b>';
          },
        },
        xAxis: {
          categories: result.map((i) => _.capitalize(i['name'])),
        },
        yAxis: {
          title: null,
        },
        title: {
          text: null,
        },
        plotOptions: {
          pie: {
            shadow: false,
          },
          column: {
            color: '#0062a9',
          },
        },
        series: [{
          data: result,
          size: '100%',
          innerSize: '85%',
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        }],
      } as any);
    } else if (this.indicator.id === 8) {
      this.listDemands = this.getOutOfTimeDemandsIndicator();
      this.indicator.value = this.listDemands.length;
    } else if (this.indicator.id === 9) {
      const result = this.getOutOfTimeDemandsByMonthIndicator();
      this.listRgaaStat = result.graph;
      this.listDemands = result.demands;

      this.chart = new Chart({
        chart: {
          type: this.indicator.graphType,
          height: 180,
          events: {
            render: function () {
              that.removeDesc()
            }
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter(): string {
            return this.point.name + ': <b>' + Math.round(this.point.y * 100) / 100 + '%</b> - Nombre : <b>'
              + this.point.number + '/' + this.point.numberTotalDemand + '</b>';
          },
        },
        xAxis: {
          categories: result.graph.map((i) => _.capitalize(i['name'])),
        },
        yAxis: {
          title: null,
          max: 100,
          labels: {
            formatter(): string {
              return this.value + '%';
            },
          },
        },
        title: {
          text: null,
        },
        plotOptions: {
          pie: {
            shadow: false,
          },
          column: {
            color: '#0062a9',
          },
        },
        series: [{
          data: result.graph,
          size: '100%',
          innerSize: '85%',
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        }],
      } as any);
    } else if (this.indicator.id === 10) {
      // this.listDemands = this.getDemandsByStatus(DemandStateCode.A_ENVOYER);
      this.indicator.value = this.getSuspendedTimeDemandsIndicator();
    } else if (this.indicator.id === 11) {
      const result = this.getTimeDemandsByMonthIndicator();
      this.listRgaaStat = result.graph;
      this.listDemands = result.demands;

      this.chart = new Chart({
        chart: {
          type: this.indicator.graphType,
          height: 180,
          events: {
            render: function () {
              that.removeDesc()
            }
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter(): string {
            return this.point.name + ': <b>' + this.point.number + '</b>';
          },
        },
        xAxis: {
          categories: result.graph.map((i) => _.capitalize(i['name'])),
        },
        yAxis: {
          title: null,
        },
        title: {
          text: null,
        },
        plotOptions: {
          pie: {
            shadow: false,
          },
          column: {
            color: '#0062a9',
          },
        },
        series: [{
          data: result.graph,
          size: '100%',
          innerSize: '85%',
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        }],
      } as any);
    } else if (this.indicator.id === 12) {
      this.listDemands = this.getSoonOutOfTimeDemands();
      this.indicator.value = this.listDemands.length;
    } else if (this.indicator.id === 13) {
      const result = this.getDemandStateByDemandObjectIndicator();
      this.listRgaaStat = result.graph.concat(result.graph2);
      this.graph = {
        serie1: result.graph,
        serie2: result.graph2,
      };

      // Timeout needed because for large indicators, the display is messed up the first time
      setTimeout(() => {
        this.chart = new Chart({
          chart: {
            type: this.indicator.graphType,
            height: 180,
            events: {
              render: function () {
                that.removeDesc()
              }
            },
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            formatter(): string {
              return this.point.name + ': <b>' + this.point.y + '</b>';
            },
          },
          xAxis: {
            categories: result.graph.map((i) => i['name']),
          },
          yAxis: {
            title: null,
          },
          title: {
            text: null,
          },
          plotOptions: {
            column: {
              stacking: 'normal',
            },
          },
          series: [{
            data: result.graph,
            color: '#757575',
            size: '100%',
            innerSize: '85%',
            showInLegend: false,
            dataLabels: {
              enabled: false,
            },
            name: 'Clôturée',
          }, {
            data: result.graph2,
            color: 'orange',
            size: '100%',
            innerSize: '85%',
            showInLegend: false,
            dataLabels: {
              enabled: false,
            },
            name: 'En cours',
          }],
        } as any);
      });
    } else if (this.indicator.id === 14) {
      const result = this.getDemandStateByContractIndicator();
      this.listRgaaStat = result.graph.concat(result.graph2);

      this.graph = {
        serie1: result.graph,
        serie2: result.graph2,
      };

      // Timeout needed because for large indicators, the display is messed up the first time
      setTimeout(() => {
        this.chart = new Chart({
          chart: {
            type: this.indicator.graphType,
            height: 180,
            events: {
              render: function () {
                that.removeDesc()
              }
            },
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            formatter(): string {
              return this.point.name + ': <b>' + this.point.y + '</b>';
            },
          },
          xAxis: {
            categories: result.graph.map((i) => i['name']),
          },
          yAxis: {
            title: null,
          },
          title: {
            text: null,
          },
          plotOptions: {
            column: {
              stacking: 'normal',
            },
          },
          series: [{
            data: result.graph,
            color: '#757575',
            size: '100%',
            innerSize: '85%',
            showInLegend: false,
            dataLabels: {
              enabled: false,
            },
            name: 'Clôturée',
          }, {
            data: result.graph2,
            color: 'orange',
            size: '100%',
            innerSize: '85%',
            showInLegend: false,
            dataLabels: {
              enabled: false,
            },
            name: 'En cours',
          }],
        } as any);
      });
    } else if (this.indicator.id === 15) {
      const result = this.getDemandStateByDemander();
      this.listRgaaStat = result.graph.concat(result.graph2);

      this.graph = {
        serie1: result.graph,
        serie2: result.graph2,
      };

      setTimeout(() => {
        this.chart = new Chart({
          chart: {
            type: this.indicator.graphType,
            height: 180,
            events: {
              render: function () {
                that.removeDesc()
              }
            },
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            formatter(): string {
              return this.point.name + ': <b>' + this.point.y + '</b>';
            },
          },
          xAxis: {
            categories: result.graph.map((i) => i['name']),
          },
          yAxis: {
            title: null,
          },
          title: {
            text: null,
          },
          plotOptions: {
            column: {
              stacking: 'normal',
            },
          },
          series: [{
            data: result.graph,
            color: '#7f8c8d',
            size: '100%',
            innerSize: '85%',
            showInLegend: false,
            dataLabels: {
              enabled: false,
            },
            name: 'Clôturée',
          }, {
            data: result.graph2,
            color: 'orange',
            size: '100%',
            innerSize: '85%',
            showInLegend: false,
            dataLabels: {
              enabled: false,
            },
            name: 'En cours',
          }],
        } as any);
      });
    } else if (this.indicator.id === 16) {
      const result = this.getDemandsByStatusIndicator(16);
      this.listRgaaStat = result;
      const resultData = this.getDemandsByStatusIndicatorFullData(16);
      this.listDemands = resultData.demands;
      setTimeout(() => {
        this.chart = new Chart({
          chart: {
            type: this.indicator.graphType,
            height: 180,
            events: {
              render: function () {
                that.removeDesc()
              }
            },
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            formatter(): string {
              return this.point.name + ': <b>' + this.point.y + '</b>';
            },
          },
          title: {
            text: _.sumBy(result, 'y') || 0,
            verticalAlign: 'middle',
            y: 5,
          },
          plotOptions: {
            pie: {
              shadow: false,
            },
          },
          series: [{
            data: result,
            size: '100%',
            borderWidth: 1,
            borderColor: '#000',
            innerSize: '85%',
            dataLabels: {
              enabled: false,
            },
            point: {
              events: {
                click: (event) => {
                  this.isdialogDetailFilterOpen = true
                  this.dialog.open(IndicatorDetailComponent, {
                    height: this.isSmallScreen ? '100vh' : 'auto',
                    maxHeight: this.isSmallScreen ? '100vh' : '90vh',
                    width: '95%',
                    data: { indicator: this.indicator, demands: this.getDemandsByStatusIndicatorData(16, event.point.name) },
                    autoFocus: false,
                    disableClose: false,
                    panelClass: this.panelClass
                  }).afterClosed().subscribe(result => {
                    this.isdialogDetailFilterOpen = false;
                  });
                },
              },
            },
          }],
        } as any);
      });
    } else if (this.indicator.id === 17) {
      this.listDemands = this.getDemandsByStatus(DemandStateCode.CLOTUREE);
      this.indicator.value = this.listDemands.length;
    } else if (this.indicator.id === 18) {
      const result = this.getUnmodifiedInprogressDemand();
      this.listRgaaStat = result.graph;
      this.listDemands = result.demands;
      setTimeout(() => {
        this.chart = new Chart({
          chart: {
            type: this.indicator.graphType,
            height: 180,
            events: {
              render: function () {
                that.removeDesc()
              }
            },
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            formatter(): string {
              return this.point.name + ': <b>' + this.point.number + '</b>';
            },
          },
          xAxis: {
            categories: result.graph.map((i) => _.capitalize(i['name'])),
          },
          yAxis: {
            title: null,
          },
          title: {
            text: null,
          },
          plotOptions: {
            pie: {
              shadow: false,
            },
            column: {
              color: '#0062a9',
            },
            series: {
              point: {
                events: {
                  click: (event) => {
                    let month = "";

                    switch (event.point.category) {
                      case 'Jan': month = '01'; break;
                      case 'Fév': month = '02'; break;
                      case 'Mar': month = '03'; break;
                      case 'Avr': month = '04'; break;
                      case 'Mai': month = '05'; break;
                      case 'Juin': month = '06'; break;
                      case 'Juil': month = '07'; break;
                      case 'Août': month = '08'; break;
                      case 'Sept': month = '09'; break;
                      case 'Oct': month = '10'; break;
                      case 'Nov': month = '11'; break;
                      case 'Déc': month = '12'; break;
                    }
                    this.isdialogDetailFilterOpen = true
                    this.dialog.open(IndicatorDetailComponent, {
                      height: this.isSmallScreen ? '100vh' : 'auto',
                      maxHeight: this.isSmallScreen ? '100vh' : '90vh',
                      width: '95%',
                      data: { indicator: this.indicator, demands: result.columns[month] },
                      autoFocus: false,
                      disableClose: false,
                      panelClass: this.panelClass
                    }).afterClosed().subscribe(result => {
                      this.isdialogDetailFilterOpen = false;
                    });
                  },
                }
              }
            }
          },
          series: [{
            data: result.graph,
            size: '100%',
            innerSize: '85%',
            showInLegend: false,
            dataLabels: {
              enabled: false,
            },
          }],
        } as any);
      });
    } else if (this.indicator.id === 19) {
      this.listDemands = this.getDemandWithReview();
      this.reviewIndicator = this.getAverageReview();
    } else if (this.indicator.id === 20) {
      this.listDemands = this.getDemandsWithStatusInStatisticFromYear(DemandStateCode.ENVOYEE, moment().format('YYYY'));
      const result = this.countDemandByMonthFromStatistic(this.listDemands, DemandStateCode.ENVOYEE);
      this.listRgaaStat = result;

      this.chart = new Chart({
        chart: {
          type: this.indicator.graphType,
          height: 180,
          events: {
            render: function () {
              that.removeDesc()
            }
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter(): string {
            return this.point.name + ': <b>' + this.point.y + '</b>';
          },
        },
        xAxis: {
          categories: result.map((i) => _.capitalize(i['name'])),
        },
        yAxis: {
          title: null,
        },
        title: {
          text: null,
        },
        plotOptions: {
          pie: {
            shadow: false,
          },
          column: {
            color: '#0062a9',
          },
          series: {
            point: {
              events: {
                click: (event) => {
                  const limit = moment().startOf('month').add(-11, 'months');
                  let month = "";

                  switch (event.point.category) {
                    case 'Jan': month = '01'; break;
                    case 'Fév': month = '02'; break;
                    case 'Mar': month = '03'; break;
                    case 'Avr': month = '04'; break;
                    case 'Mai': month = '05'; break;
                    case 'Juin': month = '06'; break;
                    case 'Juil': month = '07'; break;
                    case 'Août': month = '08'; break;
                    case 'Sept': month = '09'; break;
                    case 'Oct': month = '10'; break;
                    case 'Nov': month = '11'; break;
                    case 'Déc': month = '12'; break;
                  }
                  let currentDemand = this.demands.filter((demand) => {
                    return demand.statistics.some((statistic) => statistic.source_state_code === DemandStateCode.ENVOYEE && moment(statistic.start_date).format('MM') == month && moment(statistic.start_date) > limit);
                  })
                  this.isdialogDetailFilterOpen = true
                  this.dialog.open(IndicatorDetailComponent, {
                    height: this.isSmallScreen ? '100vh' : 'auto',
                    maxHeight: this.isSmallScreen ? '100vh' : '90vh',
                    width: '95%',
                    data: { indicator: this.indicator, demands: currentDemand },
                    autoFocus: false,
                    disableClose: false,
                    panelClass: this.panelClass
                  }).afterClosed().subscribe(result => {
                    this.isdialogDetailFilterOpen = false;
                  });
                },
              }
            }
          }
        },
        series: [{
          data: result,
          size: '100%',
          innerSize: '85%',
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        }],
      } as any);
    }

    const maxWidth = '(max-width: 575px)';
    const minWidth = '(min-width: 576px)';
    this.isSmallScreen = this.breakpoint.isMatched(maxWidth);
    this.breakpoint.observe([maxWidth, minWidth])
      .subscribe((result) => {
        this.isSmallScreen = result.breakpoints[maxWidth];
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.demands) {
      this.ngOnInit();
    }
  }

  getDemandsByStatus(status) {
    return this.demands.filter((demand) => {
      return demand.workflow_current_state_code === status;
    });
  }

  // 5 & 16 graph
  getDemandsByStatusIndicator(id: number) {
    const demandsByStatus = [];
    const labelByCode = [];
    this.demands.forEach((demand) => {
      if (id === 5) {
        // for demandeur
        if (demand.workflow_current_state_code !== DemandStateCode.CLOTUREE
          && demand.workflow_current_state_code !== DemandStateCode.ENVOYEE
          && demand.workflow_current_state_code !== DemandStateCode.AFFECTEE
          && demand.workflow_current_state_code !== DemandStateCode.EN_COURS
          && demand.workflow_current_state_code !== DemandStateCode.REJETEE
          && demand.workflow_current_state_code !== DemandStateCode.SUSPENDUE
          && demand.workflow_current_state_code !== DemandStateCode.ABANDONNEE) {
          if (demandsByStatus[demand.workflow_current_state_code]) {
            demandsByStatus[demand.workflow_current_state_code]++;
          } else {
            demandsByStatus[demand.workflow_current_state_code] = 1;
          }

          labelByCode[demand.workflow_current_state_code] = getDemandStatusLabel(demand.workflow_current_state_code);
        }
      } else {
        // id = 16 for exploitant
        if (demand.workflow_current_state_code !== DemandStateCode.A_AFFECTER
          && demand.workflow_current_state_code !== DemandStateCode.A_COMPLETER
          && demand.workflow_current_state_code !== DemandStateCode.A_ENVOYER
          && demand.workflow_current_state_code !== DemandStateCode.EN_ATTENTE_ABANDON
          && demand.workflow_current_state_code !== DemandStateCode.EN_ATTENTE_CLOTURE
          && demand.workflow_current_state_code !== DemandStateCode.CLOTUREE
          && demand.workflow_current_state_code !== DemandStateCode.ABANDONNEE) {
          if (demandsByStatus[demand.workflow_current_state_code]) {
            demandsByStatus[demand.workflow_current_state_code]++;
          } else {
            demandsByStatus[demand.workflow_current_state_code] = 1;
          }

          labelByCode[demand.workflow_current_state_code] = getDemandStatusLabel(demand.workflow_current_state_code);
        }
      }
    });

    const result = [];
    for (const code in demandsByStatus) {
      // for demandeur
      if (id === 5) {
        // add pos field to sort result to follow workflow
        switch (code) {
          case 'DEMANDE_A_ENVOYER':
            result.push({
              name: _.capitalize(labelByCode[code]),
              y: demandsByStatus[code],
              color: getDemandStateColor(code, 'demandeur', true),
              pos: 1,
            });
            break;
          case 'DEMANDE_A_COMPLETER':
            result.push({
              name: _.capitalize(labelByCode[code]),
              y: demandsByStatus[code],
              color: getDemandStateColor(code, 'demandeur', true),
              pos: 2,
            });
            break;
          case 'DEMANDE_EN_ATTENTE_CLOTURE':
            result.push({
              name: _.capitalize(labelByCode[code]),
              y: demandsByStatus[code],
              color: getDemandStateColor(code, 'demandeur', true),
              pos: 3,
            });
            break;
          case 'DEMANDE_EN_ATTENTE_ABANDON':
            result.push({
              name: _.capitalize(labelByCode[code]),
              y: demandsByStatus[code],
              color: getDemandStateColor(code, 'demandeur', true),
              pos: 4,
            });
            break;
        }
      } else {
        // for exploitant
        // add pos field to sort result to follow workflow
        switch (code) {
          case 'DEMANDE_A_ENVOYER':
            result.push({
              name: _.capitalize(labelByCode[code]),
              y: demandsByStatus[code],
              color: getDemandStateColor(code, 'exploitant', true),
              pos: 1,
            });
            break;
          case 'DEMANDE_ENVOYEE':
            result.push({
              name: _.capitalize(labelByCode[code]),
              y: demandsByStatus[code],
              color: getDemandStateColor(code, 'exploitant', true),
              pos: 2,
            });
            break;
          case 'DEMANDE_AFFECTEE':
            result.push({
              name: _.capitalize(labelByCode[code]),
              y: demandsByStatus[code],
              color: getDemandStateColor(code, 'exploitant', true),
              pos: 3,
            });
            break;
          case 'DEMANDE_EN_COURS':
            result.push({
              name: _.capitalize(labelByCode[code]),
              y: demandsByStatus[code],
              color: getDemandStateColor(code, 'exploitant', true),
              pos: 4,
            });
            break;
          case 'DEMANDE_SUSPENDUE':
            result.push({
              name: _.capitalize(labelByCode[code]),
              y: demandsByStatus[code],
              color: getDemandStateColor(code, 'exploitant', true),
              pos: 5,
            });
            break;
          case 'DEMANDE_REJETEE':
            result.push({
              name: _.capitalize(labelByCode[code]),
              y: demandsByStatus[code],
              color: getDemandStateColor(code, 'exploitant', true),
              pos: 6,
            });
            break;
          case 'DEMANDE_ABANDONNEE':
            result.push({
              name: _.capitalize(labelByCode[code]),
              y: demandsByStatus[code],
              color: getDemandStateColor(code, 'exploitant', true),
              pos: 7,
            });
            break;
          case 'DEMANDE_CLOTUREE':
            result.push({
              name: _.capitalize(labelByCode[code]),
              y: demandsByStatus[code],
              color: getDemandStateColor(code, 'exploitant', true),
              pos: 8,
            });
            break;
        }
      }

    }
    result.sort((a, b) => (a.pos > b.pos) ? 1 : -1);
    return result;
  }
  // 5 & 16 data
  getDemandsByStatusIndicatorData(id: number, name: string) {
    const demandsByCase = [];
    this.demands.forEach((demand) => {
      switch (id) {
        case 5:
          switch (name) {
            case 'Demande ' + getStatusLabel(DemandStateCode.A_ENVOYER):
              if (demand.workflow_current_state_code === DemandStateCode.A_ENVOYER) {
                demandsByCase.push(demand);
              }
              break;
            case 'Demande ' + getStatusLabel(DemandStateCode.A_COMPLETER):
              if (demand.workflow_current_state_code === DemandStateCode.A_COMPLETER) {
                demandsByCase.push(demand);
              }
              break;
            case 'Demande ' + getStatusLabel(DemandStateCode.EN_ATTENTE_CLOTURE):
              if (demand.workflow_current_state_code === DemandStateCode.EN_ATTENTE_CLOTURE) {
                demandsByCase.push(demand);
              }
              break;
            case 'Demande ' + getStatusLabel(DemandStateCode.EN_ATTENTE_ABANDON):
              if (demand.workflow_current_state_code === DemandStateCode.EN_ATTENTE_ABANDON) {
                demandsByCase.push(demand);
              }
              break;
          }
          break;
        case 16:
          switch (name) {
            case 'Demande ' + getStatusLabel(DemandStateCode.ENVOYEE):
              if (demand.workflow_current_state_code === DemandStateCode.ENVOYEE) {
                demandsByCase.push(demand);
              }
              break;
            case 'Demande ' + getStatusLabel(DemandStateCode.AFFECTEE):
              if (demand.workflow_current_state_code === DemandStateCode.AFFECTEE) {
                demandsByCase.push(demand);
              }
              break;
            case 'Demande ' + getStatusLabel(DemandStateCode.EN_COURS):
              if (demand.workflow_current_state_code === DemandStateCode.EN_COURS) {
                demandsByCase.push(demand);
              }
              break;
            case 'Demande ' + getStatusLabel(DemandStateCode.SUSPENDUE):
              if (demand.workflow_current_state_code === DemandStateCode.SUSPENDUE) {
                demandsByCase.push(demand);
              }
              break;
            case 'Demande ' + getStatusLabel(DemandStateCode.REJETEE):
              if (demand.workflow_current_state_code === DemandStateCode.REJETEE) {
                demandsByCase.push(demand);
              }
              break;
          }
          break;
      }
    });
    return demandsByCase;
  }

  private getDemandsByStatusIndicatorFullData(id: number) {
    let demandsByCase = [];
    this.demands.forEach((demand) => {
      switch (id) {
        case 5:
          if (demand.workflow_current_state_code === DemandStateCode.A_ENVOYER || demand.workflow_current_state_code === DemandStateCode.A_COMPLETER || demand.workflow_current_state_code === DemandStateCode.EN_ATTENTE_CLOTURE || demand.workflow_current_state_code === DemandStateCode.EN_ATTENTE_ABANDON) {
            demandsByCase.push(demand);
          }
          break;
        case 16:
          if (demand.workflow_current_state_code === DemandStateCode.ENVOYEE || demand.workflow_current_state_code === DemandStateCode.AFFECTEE || demand.workflow_current_state_code === DemandStateCode.EN_COURS || demand.workflow_current_state_code === DemandStateCode.SUSPENDUE || demand.workflow_current_state_code === DemandStateCode.REJETEE) {
            demandsByCase.push(demand);
          }
          break;
      }
    });
    return { demands: demandsByCase };
  }

  // 6
  getResolutionTimeIndicator() {
    const resolutionTimeByMonth = [];
    const monthLabel = [];
    const demandsByMonth = [];

    // Init value for each last 12 months.
    let currentDate = moment();
    for (let i = 0; i < 12; i++) {
      let firstDay = moment(currentDate).subtract(i, 'months').date(1);
      let d = firstDay.endOf('month');
      const yearMonth = d.format('YYYYMM');
      resolutionTimeByMonth[yearMonth] = 0;
      demandsByMonth[yearMonth] = 0;

      switch (d.format('MM')) {
        case '01': monthLabel[yearMonth] = 'Jan'; break;
        case '02': monthLabel[yearMonth] = 'Fév'; break;
        case '03': monthLabel[yearMonth] = 'Mar'; break;
        case '04': monthLabel[yearMonth] = 'Avr'; break;
        case '05': monthLabel[yearMonth] = 'Mai'; break;
        case '06': monthLabel[yearMonth] = 'Juin'; break;
        case '07': monthLabel[yearMonth] = 'Juil'; break;
        case '08': monthLabel[yearMonth] = 'Août'; break;
        case '09': monthLabel[yearMonth] = 'Sept'; break;
        case '10': monthLabel[yearMonth] = 'Oct'; break;
        case '11': monthLabel[yearMonth] = 'Nov'; break;
        case '12': monthLabel[yearMonth] = 'Déc'; break;
      }
    }

    this.demands.forEach((demand) => {
      if (demand.workflow_current_state_code === DemandStateCode.CLOTUREE || demand.workflow_current_state_code === DemandStateCode.ABANDONNEE) {
        const yearMonth = moment(demand.last_transition_created_at).format('YYYYMM');

        let time = 0;
        if (demand.statistics) {
          demand.statistics.forEach((stat) => {
            if (stat.source_state_code !== DemandStateCode.SUSPENDUE
              && stat.source_state_code !== DemandStateCode.A_COMPLETER
              && stat.source_state_code !== DemandStateCode.A_ENVOYER
              && stat.source_state_code !== DemandStateCode.ABANDONNEE
              && stat.source_state_code !== DemandStateCode.EN_ATTENTE_ABANDON
              && stat.source_state_code !== DemandStateCode.EN_ATTENTE_CLOTURE
              && stat.source_state_code !== DemandStateCode.CLOTUREE) {
              time += stat.time_diff !== null ? stat.time_diff : 0;
            }
          });
        }

        if (resolutionTimeByMonth[yearMonth] !== undefined) {
          if (demand.delais_type === "OUV") {
            resolutionTimeByMonth[yearMonth] += (time / (60 * 10));
          } else {
            resolutionTimeByMonth[yearMonth] += (time / (60 * 24));
          }
        }
        if (demandsByMonth[yearMonth] !== undefined) { demandsByMonth[yearMonth]++; }
      }
    });

    const result = [];
    for (const yearMonth in resolutionTimeByMonth) {
      result.push({
        name: monthLabel[yearMonth],
        y: demandsByMonth[yearMonth] > 0 ? (resolutionTimeByMonth[yearMonth] / demandsByMonth[yearMonth]) : 0,
        key: yearMonth,
      });
    }

    return result;
  }

  // 7
  getSentDemandsByMonthIndicator() {
    const { sentDemandsByMonth, monthLabel } = this.getDateWithDelta();

    this.indicator.value1 = 0;
    this.indicator.value2 = 0;
    const currentYearWeak = moment().format('YYYYWW');
    const currentYearMonthDay = moment().format('YYYYMMDD');
    this.listDemands.forEach((demand) => {
      if (demand.send_date_str) {
        const yearMonth = moment(demand.send_date_str).format('YYYYMM');
        const yearWeak = moment(demand.send_date_str).format('YYYYWW');
        const yearMonthDay = moment(demand.send_date_str).format('YYYYMMDD');

        if (sentDemandsByMonth[yearMonth] != undefined) { sentDemandsByMonth[yearMonth]++; }
        if (yearMonthDay === currentYearMonthDay) { this.indicator.value1++; }
        if (yearWeak === currentYearWeak) { this.indicator.value2++; }
      }
    });

    const result = [];
    for (const yearMonth in sentDemandsByMonth) {
      result.push({
        name: monthLabel[yearMonth],
        y: sentDemandsByMonth[yearMonth],
        key: yearMonth,
      });
    }

    return result;
  }

  // 8
  getOutOfTimeDemandsIndicator() {
    return this.demands.filter((demand) => {
      return demand.delais > 0
        && demand.workflow_current_state_code !== DemandStateCode.ABANDONNEE
        && demand.workflow_current_state_code !== DemandStateCode.CLOTUREE
        && demand.workflow_current_state_code !== DemandStateCode.A_ENVOYER
        && demand.timeLeft <= 0;
    });
  }

  // 9
  getOutOfTimeDemandsByMonthIndicator() {
    const monthLabel = [];
    const outOfTimeDemandsByMonth = [];
    const demandsByMonth = [];

    // Init value for each last 12 months.
    let d = moment().startOf('month');
    const limit = moment().startOf('month').add(-12, 'months');
    while (d > limit) {
      const yearMonth = d.format('YYYYMM');
      outOfTimeDemandsByMonth[yearMonth] = 0;
      demandsByMonth[yearMonth] = 0;

      switch (d.format('MM')) {
        case '01': monthLabel[yearMonth] = 'Jan'; break;
        case '02': monthLabel[yearMonth] = 'Fév'; break;
        case '03': monthLabel[yearMonth] = 'Mar'; break;
        case '04': monthLabel[yearMonth] = 'Avr'; break;
        case '05': monthLabel[yearMonth] = 'Mai'; break;
        case '06': monthLabel[yearMonth] = 'Juin'; break;
        case '07': monthLabel[yearMonth] = 'Juil'; break;
        case '08': monthLabel[yearMonth] = 'Août'; break;
        case '09': monthLabel[yearMonth] = 'Sept'; break;
        case '10': monthLabel[yearMonth] = 'Oct'; break;
        case '11': monthLabel[yearMonth] = 'Nov'; break;
        case '12': monthLabel[yearMonth] = 'Déc'; break;
      }

      d = d.add(-1, 'months');
    }

    const outOfTimeDemands = [];
    this.demands.forEach((demand) => {
      if (demand.workflow_current_state_code !== DemandStateCode.A_ENVOYER
        && demand.workflow_current_state_code !== DemandStateCode.ABANDONNEE
        && demand.send_date_str !== null
        && demand.send_date_str !== undefined) {
        const yearMonth = moment(demand.send_date_str).format('YYYYMM');
        demandsByMonth[yearMonth]++;

        if (demand.delais > 0 && demand.timeLeft <= 0) {
          if (outOfTimeDemandsByMonth[yearMonth] !== undefined) {
            outOfTimeDemands.push(demand);
            outOfTimeDemandsByMonth[yearMonth]++;
          }
        }
      }
    });

    const result = [];
    for (const yearMonth in outOfTimeDemandsByMonth) {
      result.push({
        name: monthLabel[yearMonth],
        y: demandsByMonth[yearMonth] > 0 ? (outOfTimeDemandsByMonth[yearMonth] / demandsByMonth[yearMonth]) * 100 : 0,
        number: outOfTimeDemandsByMonth[yearMonth],
        numberTotalDemand: demandsByMonth[yearMonth],
        key: yearMonth,
      });
    }
    return { graph: result, demands: outOfTimeDemands };
  }

  // 10
  getSuspendedTimeDemandsIndicator() {
    let totalTimeSuspended = 0;
    this.demands.forEach((demand) => {
      if (demand.time_DEMANDE_SUSPENDUE !== undefined) {
        totalTimeSuspended += demand.time_DEMANDE_SUSPENDUE;
      }
    });

    if (this.demands.length > 0) {
      return Number(totalTimeSuspended / 60 / this.demands.length).toFixed(0);
    } else {
      return 0;
    }
  }

  // 11
  getTimeDemandsByMonthIndicator() {
    const monthLabel = [];
    const outOfTimeDemandsByMonth = [];
    const demandsByMonth = [];

    // Init value for each last 12 months.
    let d = moment().startOf('month');
    const limit = moment().startOf('month').add(-12, 'months');
    while (d > limit) {
      const yearMonth = d.format('YYYYMM');
      outOfTimeDemandsByMonth[yearMonth] = 0;
      demandsByMonth[yearMonth] = 0;

      switch (d.format('MM')) {
        case '01': monthLabel[yearMonth] = 'Jan'; break;
        case '02': monthLabel[yearMonth] = 'Fév'; break;
        case '03': monthLabel[yearMonth] = 'Mar'; break;
        case '04': monthLabel[yearMonth] = 'Avr'; break;
        case '05': monthLabel[yearMonth] = 'Mai'; break;
        case '06': monthLabel[yearMonth] = 'Juin'; break;
        case '07': monthLabel[yearMonth] = 'Juil'; break;
        case '08': monthLabel[yearMonth] = 'Août'; break;
        case '09': monthLabel[yearMonth] = 'Sept'; break;
        case '10': monthLabel[yearMonth] = 'Oct'; break;
        case '11': monthLabel[yearMonth] = 'Nov'; break;
        case '12': monthLabel[yearMonth] = 'Déc'; break;
      }

      d = d.add(-1, 'months');
    }

    const outOfTimeDemands = [];
    this.demands.forEach((demand) => {
      if (demand.workflow_current_state_code !== DemandStateCode.A_ENVOYER
        && demand.workflow_current_state_code !== DemandStateCode.ABANDONNEE
        && demand.send_date_str !== null
        && demand.send_date_str !== undefined) {
        const yearMonth = moment(demand.send_date_str).format('YYYYMM');
        demandsByMonth[yearMonth]++;

        if (demand.timeLeft <= 0) {
          if (outOfTimeDemandsByMonth[yearMonth] !== undefined) {
            outOfTimeDemands.push(demand);
            outOfTimeDemandsByMonth[yearMonth]++;
          }
        }
      }
    });

    const result = [];
    for (const yearMonth in outOfTimeDemandsByMonth) {
      result.push({
        name: monthLabel[yearMonth],
        y: demandsByMonth[yearMonth] - outOfTimeDemandsByMonth[yearMonth],
        number: demandsByMonth[yearMonth] - outOfTimeDemandsByMonth[yearMonth],
        key: yearMonth,
      });
    }
    return {
      graph: result, demands: this.demands.filter((demand) => {
        return demand.timeLeft > 0
          && demand.workflow_current_state_code !== DemandStateCode.A_ENVOYER
          && demand.workflow_current_state_code !== DemandStateCode.ABANDONNEE
          && demand.send_date_str !== null
          && demand.send_date_str !== undefined;
      }),
    };
  }

  // 12
  getSoonOutOfTimeDemands() {
    return this.demands.filter((demand) => {
      return demand.delais > 0
        && demand.contractual_realisation_datetime
        && Math.floor((demand.contractual_realisation_datetime.toDate().getTime() - new Date().getTime()) / 1000 / 60) <= (5 * 24 * 60) // less than 5 days left
        && demand.workflow_current_state_code !== DemandStateCode.ABANDONNEE
        && demand.workflow_current_state_code !== DemandStateCode.CLOTUREE
        && demand.workflow_current_state_code !== DemandStateCode.A_ENVOYER;
    });
  }

  // 13
  getDemandStateByDemandObjectIndicator() {
    const demandObjects = [];
    const openStateByDemandObject = [];
    const closeStateByDemandObject = [];

    this.demands.forEach((demand) => {
      if (demand.workflow_current_state_code !== DemandStateCode.A_ENVOYER) {
        if (!demandObjects.includes(demand.demand_object)) {
          demandObjects.push(demand.demand_object);
          openStateByDemandObject[demand.demand_object] = 0;
          closeStateByDemandObject[demand.demand_object] = 0;
        }

        if (demand.workflow_current_state_code === DemandStateCode.ABANDONNEE
          || demand.workflow_current_state_code === DemandStateCode.CLOTUREE) {
          closeStateByDemandObject[demand.demand_object]++;
        } else {
          openStateByDemandObject[demand.demand_object]++;
        }
      }
    });
    let openResult = [];
    let closeResult = [];
    for (const demandObject of demandObjects) {
      openResult.push({
        name: demandObject,
        y: openStateByDemandObject[demandObject],
        number: openStateByDemandObject[demandObject],
        total_number: openStateByDemandObject[demandObject] + closeStateByDemandObject[demandObject],
        key: demandObject,
      });

      closeResult.push({
        name: demandObject,
        y: closeStateByDemandObject[demandObject],
        number: closeStateByDemandObject[demandObject],
        total_number: openStateByDemandObject[demandObject] + closeStateByDemandObject[demandObject],
        key: demandObject,
      });
    }
    closeResult = closeResult.sort((a, b) => b['total_number'] > a['total_number'] ? 1 : b['total_number'] === a['total_number'] ? 0 : -1);
    openResult = openResult.sort((a, b) => b['total_number'] > a['total_number'] ? 1 : b['total_number'] === a['total_number'] ? 0 : -1);
    return { graph: closeResult, graph2: openResult };
  }

  // 14
  getDemandStateByContractIndicator() {
    const contracts = [];
    const openStateByContract = [];
    const closeStateByContract = [];

    this.demands.forEach((demand) => {
      if (demand.workflow_current_state_code !== DemandStateCode.A_ENVOYER) {
        if (!contracts.includes(demand.contract)) {
          contracts.push(demand.contract);
          openStateByContract[demand.contract] = 0;
          closeStateByContract[demand.contract] = 0;
        }

        if (demand.workflow_current_state_code === DemandStateCode.ABANDONNEE
          || demand.workflow_current_state_code === DemandStateCode.CLOTUREE) {
          closeStateByContract[demand.contract]++;
        } else {
          openStateByContract[demand.contract]++;
        }
      }
    });

    let openResult = [];
    let closeResult = [];
    for (const contract of contracts) {
      const foundContract = this.contracts.find((c) => c.code === contract);
      let label = contract;
      if (foundContract) {
        label = foundContract.code + ' - ' + foundContract.label;
      }

      openResult.push({
        name: label,
        y: openStateByContract[contract],
        number: openStateByContract[contract],
        total_number: openStateByContract[contract] + closeStateByContract[contract],
        key: contract,
      });

      closeResult.push({
        name: label,
        y: closeStateByContract[contract],
        number: closeStateByContract[contract],
        total_number: openStateByContract[contract] + closeStateByContract[contract],
        key: contract,
      });
    }
    closeResult = closeResult.sort((a, b) => b['total_number'] > a['total_number'] ? 1 : b['total_number'] === a['total_number'] ? 0 : -1);
    openResult = openResult.sort((a, b) => b['total_number'] > a['total_number'] ? 1 : b['total_number'] === a['total_number'] ? 0 : -1);
    return { graph: closeResult, graph2: openResult };
  }

  // 15
  getDemandStateByDemander() {
    const demanders = [];
    const openStateByDemanders = [];
    const closeStateByDemanders = [];

    this.demands.forEach((demand) => {
      if (!demanders.includes(demand.created_by)) {
        demanders.push(demand.created_by);
        openStateByDemanders[demand.created_by] = 0;
        closeStateByDemanders[demand.created_by] = 0;
      }

      if (demand.workflow_current_state_code === DemandStateCode.ABANDONNEE
        || demand.workflow_current_state_code === DemandStateCode.CLOTUREE) {
        closeStateByDemanders[demand.created_by]++;
      } else {
        openStateByDemanders[demand.created_by]++;
      }
    });

    let openResult = [];
    let closeResult = [];
    for (const demander of demanders) {
      openResult.push({
        name: demander,
        y: openStateByDemanders[demander],
        number: openStateByDemanders[demander],
        total_number: openStateByDemanders[demander] + closeStateByDemanders[demander],
        key: demander,
      });

      closeResult.push({
        name: demander,
        y: closeStateByDemanders[demander],
        number: closeStateByDemanders[demander],
        total_number: openStateByDemanders[demander] + closeStateByDemanders[demander],
        key: demander,
      });
    }
    closeResult = closeResult.sort((a, b) => b['total_number'] > a['total_number'] ? 1 : b['total_number'] === a['total_number'] ? 0 : -1);
    openResult = openResult.sort((a, b) => b['total_number'] > a['total_number'] ? 1 : b['total_number'] === a['total_number'] ? 0 : -1);
    return { graph: closeResult, graph2: openResult };
  }

  // 18
  getUnmodifiedInprogressDemand() {
    let monthLabel = [];
    let demandByMonth = [];
    let columnByMonth = [];

    let d = moment().startOf('month').add(-4, 'months');
    const limit = moment().startOf('month').add(-16, 'months');
    while (d > limit) {
      const yearMonth = d.format('YYYYMM');
      const Month = d.format('MM');
      demandByMonth[yearMonth] = 0;
      columnByMonth[Month] = [];

      switch (d.format('MM')) {
        case '01': monthLabel[yearMonth] = 'Jan'; break;
        case '02': monthLabel[yearMonth] = 'Fév'; break;
        case '03': monthLabel[yearMonth] = 'Mar'; break;
        case '04': monthLabel[yearMonth] = 'Avr'; break;
        case '05': monthLabel[yearMonth] = 'Mai'; break;
        case '06': monthLabel[yearMonth] = 'Juin'; break;
        case '07': monthLabel[yearMonth] = 'Juil'; break;
        case '08': monthLabel[yearMonth] = 'Août'; break;
        case '09': monthLabel[yearMonth] = 'Sept'; break;
        case '10': monthLabel[yearMonth] = 'Oct'; break;
        case '11': monthLabel[yearMonth] = 'Nov'; break;
        case '12': monthLabel[yearMonth] = 'Déc'; break;
      }

      d = d.add(-1, 'months');
    }

    let unmodifiedDemand = [];
    this.demands.forEach(demand => {
      if ((demand.workflow_current_state_code === DemandStateCode.EN_COURS || demand.workflow_current_state_code === DemandStateCode.ENVOYEE || demand.workflow_current_state_code === DemandStateCode.AFFECTEE) && demand.send_date_str !== null
        && demand.send_date_str !== undefined) {

        const toDay = new Date();
        let lastModifiedDate = new Date(demand.updated_at);

        const limitDate = new Date(demand.updated_at);

        limitDate.setMonth((limitDate.getMonth()) + 4);


        if (limitDate < toDay) {
          unmodifiedDemand.push(demand);
          const yearMonth = moment(lastModifiedDate).format('YYYYMM');
          const Month = moment(lastModifiedDate).format('MM');
          if (yearMonth in monthLabel) {
            demandByMonth[yearMonth]++;
            columnByMonth[Month].push(demand);
          }
        }
      }
    });

    const result = [];
    for (const yearMonth in demandByMonth) {
      result.push({
        name: monthLabel[yearMonth],
        y: demandByMonth[yearMonth],
        number: demandByMonth[yearMonth],
        key: yearMonth,
      });
    }

    return { graph: result, demands: unmodifiedDemand, columns: columnByMonth };
  }

  // 19
  getAverageReview(): IAverageReview {
    let total: number = this.listDemands.length;
    let average: number = 0;

    this.listDemands.forEach((demand) => { // Récupère la note de chaque demande
      if(demand.reviews) average += demand.reviews;
    })

    if(total === 0) return {total:0, average:0};
    average = average / total; // Calcul moyenne
    return {total:total, average:average};
  }

  // 19
  getDemandWithReview() {
      let demands = this.getDemandsByStatus(DemandStateCode.CLOTUREE);
      return demands.filter((demand) => {
        return demand.reviews != null;
      });
  }

  // 20
  getDemandsWithStatusInStatisticFromYear(status, year=null) {
    const limit = moment().startOf('month').add(-11, 'months');

    return this.demands.filter((demand) => {
      return demand.statistics.some((statistic) => statistic.source_state_code === status && (year == null || moment(statistic.start_date) >= limit));
    });
  }

  // 20
  countDemandByMonthFromStatistic(demands, status) {
    const { sentDemandsByMonth, monthLabel } = this.getDateWithDelta();

    this.indicator.value1 = 0;
    this.indicator.value2 = 0;
    const currentYearWeak = moment().format('YYYYWW');
    const currentYearMonthDay = moment().format('YYYYMMDD');
    demands.forEach((demand) => {
      const statistics = demand.statistics.filter((statistic) => statistic.source_state_code === status);
      if (statistics[0].start_date) {
        const yearMonth = moment(statistics[0].start_date).format('YYYYMM');
        const yearWeak = moment(statistics[0].start_date).format('YYYYWW');
        const yearMonthDay = moment(statistics[0].start_date).format('YYYYMMDD');

        if (sentDemandsByMonth[yearMonth] != undefined) { sentDemandsByMonth[yearMonth]++; }
        if (yearMonthDay === currentYearMonthDay) { this.indicator.value1++; }
        if (yearWeak === currentYearWeak) { this.indicator.value2++; }
      }
    });

    const result = [];
    for (const yearMonth in sentDemandsByMonth) {
      result.push({
        name: monthLabel[yearMonth],
        y: sentDemandsByMonth[yearMonth],
        key: yearMonth,
      });
    }

    return result;
  }

  openDetail(force = false) {
    this.panelClass = this.isSmallScreen ? 'panelClassMobile' : 'panelClass';

    if (this.isdialogDetailFilterOpen === false && (this.indicator.noDetail !== true || force)) {
      if (this.indicator.detail === 'graph') {
        this.isdialogDetailOpen = true;
        this.dialog.open(IndicatorDetailGraphComponent, {
          maxHeight: '90vh',
          width: '90vw',
          data: { indicator: this.indicator, graph: this.graph },
          autoFocus: false,
          disableClose: false,
        }).afterClosed().subscribe(result => {
          this.isdialogDetailOpen = false;
        });
      } else {
        this.isdialogDetailOpen = true;
        this.dialog.open(IndicatorDetailComponent, {
          height: this.isSmallScreen ? '100vh' : 'auto',
          maxHeight: this.isSmallScreen ? '100vh' : '90vh',
          width: '95%',
          data: { indicator: this.indicator, demands: this.listDemands },
          autoFocus: false,
          disableClose: false,
          panelClass: this.panelClass
        }).afterClosed().subscribe(result => {
          this.isdialogDetailOpen = false;
        });
      }
    }
  }

  removeDesc() {
    const highchartsDescs = document.querySelectorAll('desc')
    highchartsDescs.forEach(desc => {
      desc.remove()
    })
  }

  private getDateWithDelta() {
    const sentDemandsByMonth = [];
    const monthLabel = [];

    // Init value for each last 12 months.
    let d = moment().startOf('month');
    const limit = moment().startOf('month').add(-12, 'months');
    while (d > limit) {
      const yearMonth = d.format('YYYYMM');
      sentDemandsByMonth[yearMonth] = 0;

      switch (d.format('MM')) {
        case '01': monthLabel[yearMonth] = 'Jan'; break;
        case '02': monthLabel[yearMonth] = 'Fév'; break;
        case '03': monthLabel[yearMonth] = 'Mar'; break;
        case '04': monthLabel[yearMonth] = 'Avr'; break;
        case '05': monthLabel[yearMonth] = 'Mai'; break;
        case '06': monthLabel[yearMonth] = 'Juin'; break;
        case '07': monthLabel[yearMonth] = 'Juil'; break;
        case '08': monthLabel[yearMonth] = 'Août'; break;
        case '09': monthLabel[yearMonth] = 'Sept'; break;
        case '10': monthLabel[yearMonth] = 'Oct'; break;
        case '11': monthLabel[yearMonth] = 'Nov'; break;
        case '12': monthLabel[yearMonth] = 'Déc'; break;
      }

      d = d.add(-1, 'months');
    }
    return {sentDemandsByMonth, monthLabel}
  }
}
