import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '@env/environment';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { DetailMapModalComponent } from '@app/components/modals/detail-map-modal/detail-map-modal.component';
import { getDemandStatusLabel, getStatusLabel } from '@app/shared/helpers/demand-helper';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit {
  DemandStateCode = DemandStateCode;
  getDemandStatusLabel = getDemandStatusLabel;
  getStatusLabel = getStatusLabel;
  statusMarkerColors = environment.status_colors;

  clusterIcon = "/assets/map_cluster";

  public latitude: number = environment.map_latitude;
  public longitude: number = environment.map_longitude;
  public zoom : number = environment.map_zoom;
  public POI: Boolean = environment.map_POI;
  public agmMapStyle: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<GoogleMapsComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog,
  ) {
    this.switchPOI();
  }

  getSVGIcon(marker: any): string {
    let color = "#FF0000";
    if (environment.toggle_feature_map_by_status) {
      color = this.statusMarkerColors[marker.workflow_current_state_code];
    }
    let svgContent = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="${color}" >
        <g style="stroke: black; stroke-width: 1px;">
          <path d="M12 2C7.03 2 3 6.03 3 11c0 6 9 13 9 13s9-7 9-13c0-4.97-4.03-9-9-9zm0 12c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"/>
        </g>
    </svg>`;
    let encodedSVG = encodeURIComponent(svgContent);
    return `data:image/svg+xml;charset=utf-8,${encodedSVG}`;
}

  ngOnInit() {
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onClickStatus(marker) {
    this.dialog.open(DetailMapModalComponent, {
      data: {demand: marker},
      autoFocus: false,
    });
  }

  capitalize(text: string): string {
    return _.capitalize(text);
  }

  switchPOI() {
    if(this.POI){
      this.agmMapStyle = [];
    } else {
      this.agmMapStyle = [{featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }]},{featureType: "transit", elementType: "labels", stylers: [{ visibility: "off" }]}];
    }
  }
}
