import { Component, Input, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { Demand } from '@app/shared/models/demand';
import { IMessage } from 'src/app/shared/models/message';
import { AgmMap, MapsAPILoader } from '@agm/core';
import * as _ from 'lodash';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import { demandMatchState, getDemandStatusLabel, getLastPointAvancement, getLastTransitionAttachments, getLastTransitionMessage } from '@app/shared/helpers/demand-helper';
import { BreakpointObserver } from '@angular/cdk/layout';
import { IAttachment } from '@app/shared/models/attachment';
import { environment } from '@env/environment';
import { DemandService } from '@app/shared/services/demand/demand.service';
import Toast from '@app/shared/helpers/toast';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { isAssigned } from '@app/shared/helpers/user-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { User, UserType } from '@app/shared/models/user';

declare var google: any;

interface ILocation {
  lat: string;
  lng: string;
  zoom: number;
  geometry: any;
}

@Component({
  selector: 'app-demand-tab',
  templateUrl: './demand-tab.component.html',
  styleUrls: ['./demand-tab.component.scss'],
})
export class DemandTabComponent implements OnInit {
  @Input() demand: Demand;
  @ViewChild(AgmMap) map: AgmMap;
  @ViewChild('int_comment') internal_comment;
  @ViewChild('int_ref') internal_reference;
  private geocoder: any;
  public location: {
    lat: number;
    lng: number;
    zoom: number;
  } = {
      lat: null,
      lng: null,
      zoom: 17,
    };
  public mode: any = null;
  pointAvancementMessage;
  mapsUrl;
  displayMessage: IMessage;
  displayAttachments: IAttachment[];
  isSmallScreen: boolean;
  toggle_feature_internal_comment = environment.toggle_feature_internal_comment;
  toggle_feature_send_ilotier = environment.toggle_feature_send_ilotier;
  toggle_feature_internal_reference = environment.toggle_feature_internal_reference;
  activity_label = environment.activity_label;
  client_name = environment.client_name;
  getDemandStatusLabel = getDemandStatusLabel;
  urba_motif_value: string;

  sub: Subscription;

  user: User;

  // Document d'urbanisme
  public showBlockUrba: boolean = false;

  constructor(
    public mapsApiLoader: MapsAPILoader,
    private breakPoint: BreakpointObserver,
    private demandService: DemandService,
    private router: Router,
    private authService: AuthenticationService
  ) {
    // TODO:: Add this in a helper

    const maxWidth = '(max-width: 991px)';
    const minWidth = '(min-width: 992px)';
    this.isSmallScreen = this.breakPoint.isMatched(maxWidth);
    this.breakPoint.observe([maxWidth, minWidth])
      .subscribe((result) => {
        this.isSmallScreen = result.breakpoints[maxWidth];
      });

    this.authService.getUser().then((_user) => {
      this.user = _user as User;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.demand) {
      this.ngOnInit();
    }
  }

  ngOnInit() {
    this.mode = getUserMode();
    this.showBlockUrba = this.demand.demand_object.code === 'DOC_URBA';
    this.urba_motif_value = this.demand.urba_motif === 'Autre' ? 'Autre : ' + this.demand.urba_motif_bis : this.demand.urba_motif;
    if (this.demand.location_latitude && this.demand.location_longitude) {
      this.location.lng = parseFloat(this.demand.location_longitude);
      this.location.lat = parseFloat(this.demand.location_latitude);
      this.mapsUrl = `http://maps.googleapis.com/maps/api/staticmap?center=${this.location.lat},${this.location.lng}
      &zoom=17&scale=2&size=1280x160&maptype=roadmap&format=png
      &visual_refresh=true&markers=size:mid|color:red|label:|${this.location.lat},${this.location.lng}
      &key=${environment.google_api_key}`;
    } else {
      this.mapsApiLoader.load().then(() => {
        this.geocoder = new google.maps.Geocoder();
        this.geocoder.geocode({
          address: this.demand.location_full_address,
        }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const location = _.first<ILocation>(results);

            this.location.lng = location.geometry.location.lng();
            this.location.lat = location.geometry.location.lat();
            this.location.zoom = 17;
            this.mapsUrl = `http://maps.googleapis.com/maps/api/staticmap?center=${this.location.lat},${this.location.lng}
            &zoom=${this.location.zoom}&scale=2&size=1280x160&maptype=roadmap&format=png
            &visual_refresh=true&markers=size:mid|color:red|label:|${this.location.lat},${this.location.lng}
            &key=${environment.google_api_key}`;
          }
        });
      });
    }
    this.getDisplayedMessage();
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  getDisplayedMessage() {
    this.displayMessage = undefined;

    // We get the last PA's message from last transition
    const lastPointAvancement = getLastPointAvancement(this.demand);
    // We get the last transition's message from EXPLOITANT (here is DEMANDEUR view so we display messages from EXPLOITANT)
    const lastTransition = getLastTransitionMessage(this.demand, UserType.EXPLOITANT);
    if (lastTransition && lastPointAvancement) {
      if (Date.parse(lastTransition.created_at) > Date.parse(lastPointAvancement.created_at)) {
        this.displayMessage = this.formatMessage(lastTransition, true)
        this.displayAttachments = getLastTransitionAttachments(this.demand)
      } else if (lastPointAvancement.mode === UserType.EXPLOITANT.toUpperCase()) {
        // here is DEMANDEUR view so we display messages from EXPLOITANT
        this.displayMessage = this.formatMessage(lastPointAvancement)
        this.displayAttachments = lastPointAvancement.attachments
      }
    } else if (lastTransition) {
      this.displayMessage = this.formatMessage(lastTransition, true)
      this.displayAttachments = getLastTransitionAttachments(this.demand)
    } else if (lastPointAvancement && lastPointAvancement.mode === UserType.EXPLOITANT.toUpperCase()) {
      // here is DEMANDEUR view so we display messages from EXPLOITANT
      this.displayMessage = this.formatMessage(lastPointAvancement)
      this.displayAttachments = lastPointAvancement.attachments
    }
  }

  formatMessage(message: any, transition: boolean = false) {
    return {
      user: transition ? message.user : message.created_by,
      message: message.message,
      created_at: message.created_at,
    }
  }

  getColCssClass() {
    return this.displayAttachments !== undefined && this.displayAttachments.length !== 0 ? 'col-xs-12 col-md-6' : 'col-xs-12 col-md-12';
  }

  allDownload() {
    this.demand.attachments.forEach((attachment) => {
      if (attachment.kind === 'FILE') {
        this.demandService.getDownloadUrl(attachment.storage_full_uri).subscribe((res: any) => {
          window.open(res.url, '_blank');
        });
      }
    })
  }
}
