<div class="title">{{ title }}</div>
<mat-dialog-content>
  <mat-form-field class="full-width">
    <input matInput #input placeholder="Libellé du lien" [attr.maxlength]="200">
    <mat-hint align="end">
      <span class="visually-hidden">Compteur de caractères</span>
      {{input?.value.length || 0}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 200
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="full-width">
    <input matInput #inputUrl placeholder="Lien" [attr.maxlength]="2000" aria-describedby="link_error">
    <mat-hint align="end">
      <span class="visually-hidden">Compteur de caractères</span>
      {{inputUrl?.value.length || 0}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 2000
    </mat-hint>
  </mat-form-field>
  <mat-error *ngIf="!isUrlOK" id="link_error">
    Le lien n'est pas valide, celui-ci doit commencer par http ou https
  </mat-error>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="cancel-btn" class="uppercased mat-primary"
          mat-button mat-dialog-close
          (click)="refuse()">
    Annuler
  </button>
  <button mat-button class="uppercased mat-primary"
          (click)="confirm()" [disabled]="!input?.value.length || !inputUrl?.value.length">
    Ajouter
  </button>
</mat-dialog-actions>
