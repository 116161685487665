<div class="btn-wrapper">
  <button (click)="fileInput.click()" mat-stroked-button class="mat-primary uppercased demand-action-btn-upload">
    <mat-icon class="mat-18 attach-icon">attach_file</mat-icon>Ajouter un fichier
  </button>
  <button mat-icon-button
  matTooltip="Les fichiers autorisés sont de type texte, tableur, pdf, image, vidéo, audio et archive, à l’exception des scripts et programmes. La taille maximale par fichier est de {{max_file_size / 1000 / 1000}} Mo."
  class="clickable btn-info_add_file"
  >
    <mat-icon>info</mat-icon>
  </button>

  <input #fileInput id="upload-file" class="v-btn" type="file" (change)="onFileInput($event.srcElement.files);$event.target.value = null;"
    placeholder="Upload a file..."
    accept=".odt,.ods,.odp,.doc,.docx,.dat,.csv,.rtf,.txt,.xml,.html,.ppt,.pptx,.xls,.xlsx,.sld,.sldx,.zip,.rar,.gz,.7z,.ace,.tar,.bz2,.pdf,.sxw,.wps,audio/*,video/*,image/*"
    multiple />
</div>
