import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastHelper {
  private duration: number = 5000; // milliseconds
  private horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  private verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private _snackBar: MatSnackBar) { }

  success(message: string, action: string = null): Observable<void> {
    const actionSubject = new Subject<void>();

    const snackBarRef = this._snackBar.open(message, action, {
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['snackbar', 'success-snackbar'], // Toast style in toast.scss
    });

    snackBarRef.onAction().subscribe(() => {
      actionSubject.next();
      actionSubject.complete();
    });

    return actionSubject.asObservable();
  }

  info(message: string, action: string = null): Observable<void> {
    const actionSubject = new Subject<void>();

    const snackBarRef = this._snackBar.open(message, action, {
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['snackbar', 'info-snackbar'], // Toast style in toast.scss
    });

    snackBarRef.onAction().subscribe(() => {
      actionSubject.next();
      actionSubject.complete();
    });

    return actionSubject.asObservable();
  }

  warning(message: string, action: string = null): Observable<void> {
    const actionSubject = new Subject<void>();

    const snackBarRef = this._snackBar.open(message, action, {
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['snackbar', 'warning-snackbar'], // Toast style in toast.scss
    });

    snackBarRef.onAction().subscribe(() => {
      actionSubject.next();
      actionSubject.complete();
    });

    return actionSubject.asObservable();
  }

  error(message: string, action: string = null): Observable<void> {
    const actionSubject = new Subject<void>();

    const snackBarRef = this._snackBar.open(message, action, {
      duration: this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['snackbar', 'error-snackbar'], // Toast style in toast.scss
    });

    snackBarRef.onAction().subscribe(() => {
      actionSubject.next();
      actionSubject.complete();
    });

    return actionSubject.asObservable();
  }
}