import { Component, Input, OnInit } from '@angular/core';
import { Demand } from '@app/shared/models/demand';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { userIsExploitant } from '@app/shared/helpers/user-modes-helper';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { isAssigned } from '@app/shared/helpers/user-helper';
import { User } from '@app/shared/models/user';

@Component({
  selector: 'app-btn-transition',
  templateUrl: './btn-transition.component.html',
  styleUrls: ['./btn-transition.component.scss'],
})
export class BtnTransitionBaseComponent implements OnInit {
  @Input() demand: Demand;

  public buttonText = 'Base button';
  public raisedBtn = false;
  public stateCode: DemandStateCode;
  public loading: boolean = false;
  public user: User;

  constructor(
    private authService: AuthenticationService,
  ) {
    this.authService.getUser().then((_user) => {
      this.user = _user as User;
    });
  }

  ngOnInit() {}

  checkVisibility(): boolean {
    return this.demand.next_states.map((state) => state.code)
        .includes(this.stateCode) && this.checkVisibilityTypology();
  }

  checkVisibilityTypology() {
    // Override me in the subclasses to handle cases where typology matters
    return true;
  }

  isDisabled(): boolean {
    return userIsExploitant() && demandMatchState(this.demand, [
      DemandStateCode.AFFECTEE,
      DemandStateCode.EN_COURS,
      DemandStateCode.SUSPENDUE,
      DemandStateCode.EN_ATTENTE_CLOTURE,
      DemandStateCode.EN_ATTENTE_ABANDON,
      DemandStateCode.REJETEE,
      DemandStateCode.CLOTUREE,
      DemandStateCode.ABANDONNEE,
      DemandStateCode.A_COMPLETER,
    ]) && !isAssigned(this.user, this.demand);
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }

  onClick() {
    console.log(`${this.buttonText} clicked`);
  }
}
