import { Component, Output, EventEmitter } from '@angular/core';
import Toast from '../../../shared/helpers/toast';
import { BtnTransitionBaseComponent } from '../btn-transition.component';
import { ConfirmationInputModalComponent } from '../../modals/confirmation-input-modal/confirmation-input-modal.component';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { Demand } from '@app/shared/models/demand';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-btn-ajouter-commentaire-interne',
  templateUrl: '../btn-transition.component.html',
  styleUrls: ['../btn-transition.component.scss'],
})
export class BtnAjouterCommentaireInterneComponent extends BtnTransitionBaseComponent {
  @Output() reloadHistory: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private demandService: DemandService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
  ) {
    super(authenticationService);
    this.buttonText = 'Envoyer un commentaire interne';
  }

  checkVisibility(): boolean {
    return demandMatchState(this.demand, [
      DemandStateCode.REJETEE,
      DemandStateCode.EN_COURS,
      DemandStateCode.SUSPENDUE,
      DemandStateCode.AFFECTEE,
    ]);
  }

  onFinish() {
    Toast.info('Commentaire interne envoyé.');
    this.stopLoading();
    this.reloadHistory.emit(true);
  }

  onClick() {
    const dialogRef = this.dialog.open(ConfirmationInputModalComponent, {
      data: {
        title: 'Commentaire interne',
        labelConfirm: 'j\'envoie',
        upload:true,
        hideFileUpload: true,
        messageMaxLength: 3000,
        isForInternalComment: true,
        demand: this.demand
      },
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === true) {
        this.startLoading();
        
        this.demandService.sendInternalComment(
          this.demand,
          result.message,
          this.user,
          result.attachments
        )
        .subscribe(res => {this.onFinish();});
      }
    });
  }
}
