import { Component, Input, OnInit } from '@angular/core';
import { Demand } from '@app/shared/models/demand';
import { demandMatchState } from '@app/shared/helpers/demand-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { userIsExploitant } from '@app/shared/helpers/user-modes-helper';
import * as moment from 'moment';
import { Duration } from 'moment';
import { environment } from '@env/environment';
import { DelaiService } from '@app/shared/services/delai.service';
import { HoraireService } from '@app/shared/services/horaire.service';
import { Horaire } from '@app/shared/models/horaire';

@Component({
  selector: 'app-demand-page-header',
  templateUrl: './demand-page-header.component.html',
  styleUrls: ['./demand-page-header.component.scss'],
})
export class DemandPageHeaderComponent implements OnInit {

  @Input() demand: Demand;

  public impactProgressBarValue = {
    SANS_OBJET: 0,
    FAIBLE: 15,
    MODERE: 50,
    ELEVE: 90,
  };
  timeLeft: Duration;
  MAX_TIME_LEFT: number = 5;
  contractual_realisation_datetime: Date;

  contract_label = environment.contract_label;
  client_name = environment.client_name;

  constructor(
    private delaiService: DelaiService,
    private horaireService: HoraireService,
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    const dateNow = moment(new Date());
    const demandRealisationDateTime =  moment(this.demand.realisation_datetime);
    this.timeLeft =  moment.duration(demandRealisationDateTime.diff(dateNow));

    this.horaireService.getHoraires().then((horaires) => {
      this.contractual_realisation_datetime = this.calculateContractualDate(horaires);
    });
  }

  isCloseToEcheanceDate() {
    return (userIsExploitant() && this.timeLeft.asDays() <= this.MAX_TIME_LEFT
      && !demandMatchState(
      this.demand, [
        DemandStateCode.EN_ATTENTE_CLOTURE, DemandStateCode.EN_ATTENTE_ABANDON,
        DemandStateCode.CLOTUREE, DemandStateCode.ABANDONNEE])
    );
  }

  calculateContractualDate(horaires: Horaire[]) {
    if (this.demand.send_date_str === undefined || this.demand.send_date_str === null) {
      return undefined;
    };

    let delais = this.demand.delais;
    let delais_type = this.demand.delais_type;
    let statistics = this.demand.statistics;

    // Init time for each status
    for (let status of Object.keys(DemandStateCode)) {
      this.demand['time_' + DemandStateCode[status]] = 0;
    }

    let activeTime = 0;
    let holdTime = 0;

    if (!statistics) statistics = [];

    // Add the current status time
    if (this.demand.last_transition_created_at !== null) {
      statistics.push({
        demand_id: this.demand.id,
        source_state_code: this.demand.workflow_current_state.code,
        start_date: moment(this.demand.last_transition_created_at).format('YYYY-MM-DD[T]HH:mm:ss'),
        end_date: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        delais_type: delais_type
      });
    }

    statistics.forEach((stat) => {
      stat.time_diff = this.delaiService.calculateTimeSpent(
        new Date(stat.start_date), 
        new Date(stat.end_date), 
        delais_type !== 'OUV', 
        horaires
      );

      let time = stat.time_diff !== null ? stat.time_diff : 0;
      if (stat.source_state_code !== DemandStateCode.SUSPENDUE
        && stat.source_state_code !== DemandStateCode.A_COMPLETER
        && stat.source_state_code !== DemandStateCode.A_ENVOYER
        && stat.source_state_code !== DemandStateCode.EN_ATTENTE_ABANDON
        && stat.source_state_code !== DemandStateCode.EN_ATTENTE_CLOTURE
        && stat.source_state_code !== DemandStateCode.ABANDONNEE
        && stat.source_state_code !== DemandStateCode.CLOTUREE) {
        activeTime += time;
      } else if (stat.source_state_code !== DemandStateCode.A_ENVOYER
        && stat.source_state_code !== DemandStateCode.ABANDONNEE
        && stat.source_state_code !== DemandStateCode.CLOTUREE){
        holdTime += time;
      } else {
        // A Envoyer is not active nor hold
      }

      this.demand['time_' + stat.source_state_code] += time;
    });

    //this.demand.active_time = activeTime;
    //this.demand.hold_time = holdTime;

    // Calculate the time left
    let timeLeft = delais - activeTime;
    //this.demand.timeLeft = timeLeft;

    return this.delaiService.calculateEndDate(
      new Date(this.demand.send_date_str), 
      delais + holdTime, 
      delais_type !== 'OUV', 
      horaires
    );
  }

  isCloseToContractualEcheanceDate() {
    if (userIsExploitant() && !demandMatchState(this.demand, [DemandStateCode.EN_ATTENTE_CLOTURE, 
                                                              DemandStateCode.EN_ATTENTE_ABANDON,
                                                              DemandStateCode.CLOTUREE, 
                                                              DemandStateCode.ABANDONNEE])
        && this.contractual_realisation_datetime
    ) {
      // less than 5 days left
      return Math.floor((this.contractual_realisation_datetime.getTime() - new Date().getTime()) / 1000 / 60) <= (5 * 24 * 60);
    } else {
      return false;
    }
  }
}
